/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { useCallback, useRef } from 'react';

export default function useDebounce() {
  const timer = useRef();

  const debounce = useCallback((fn, delay) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => fn(), delay);
  }, []);

  return debounce;
}
