/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState } from 'react';

import classNames from 'classnames';

import './Overlay.scss';

const Overlay = ({
  onClick,
  children,
  animated,
  animateChildren = true,
  extradark,
  delayOnClick = true,
  fadeOut = false,
}) => {
  const [showFadeOut, setShowFadeOut] = useState(false);

  return (
    // @TODO provide a aria compatible way to close the overlay
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classNames('overlay', {
        'overlay--animated': animated,
        'overlay--animate-children': animated && animateChildren,
        'overlay--extradark': extradark,
        'overlay--fade-out': showFadeOut || fadeOut,
      })}
      data-testid="overlay"
      onMouseDown={() => {
        if (onClick) {
          if (animated) {
            setShowFadeOut(true);
            if (delayOnClick) {
              setTimeout(onClick, 500);
            } else {
              onClick();
            }
          } else {
            onClick();
          }
        }
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="overlay__content"
        onMouseDown={(ev) => {
          // eventTrap
          ev.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Overlay;
