/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { Client } from '@fieldbyfield/client';

export const useClient = (token) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const createClient = useCallback(async () => {
    return new Client({
      base: `${process.env.CALLS_EXTERNAL_URL}/v0`,
      token: token || (isAuthenticated && (await getAccessTokenSilently())) || null,
    });
  }, [isAuthenticated, getAccessTokenSilently, token]);

  return useCallback(() => ({ createClient }), [createClient])();
};
