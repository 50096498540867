/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export default function convexHull(points) {
  /* convex hull code from d3 ************************************************** */
  function crossProduct(a, b, c) {
    return (b[0] - a[0]) * (c[1] - a[1]) - (b[1] - a[1]) * (c[0] - a[0]);
  }

  function lexicographicOrder(a, b) {
    return a[0] - b[0] || a[1] - b[1];
  }

  function computeUpperHullIndexes(pts) {
    const n = pts.length;
    const indexes = [0, 1];
    let size = 2;

    for (let i = 2; i < n; i++) {
      while (size > 1 && crossProduct(pts[indexes[size - 2]], pts[indexes[size - 1]], pts[i]) <= 0) --size;
      indexes[size++] = i;
    }

    return indexes.slice(0, size); // remove popped points
  }

  const n = points.length;
  if (n < 3) return null;

  const sortedPoints = new Array(n);
  const flippedPoints = new Array(n);

  for (let i = 0; i < n; i++) sortedPoints[i] = [+points[i][0], +points[i][1], i];
  sortedPoints.sort(lexicographicOrder);
  for (let i = 0; i < n; i++) flippedPoints[i] = [sortedPoints[i][0], -sortedPoints[i][1]];

  const upperIndexes = computeUpperHullIndexes(sortedPoints);
  const lowerIndexes = computeUpperHullIndexes(flippedPoints);

  // Construct the hull polygon, removing possible duplicate endpoints.
  const skipLeft = lowerIndexes[0] === upperIndexes[0];
  const skipRight = lowerIndexes[lowerIndexes.length - 1] === upperIndexes[upperIndexes.length - 1];
  const hull = [];

  // Add upper hull in right-to-l order.
  // Then add lower hull in left-to-right order.
  for (let i = upperIndexes.length - 1; i >= 0; --i) hull.push(points[sortedPoints[upperIndexes[i]][2]]);
  for (let i = +skipLeft; i < lowerIndexes.length - skipRight; i++) hull.push(points[sortedPoints[lowerIndexes[i]][2]]);

  return hull;
}
