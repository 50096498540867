/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

const Venn = () => {
  return (
    <g className="canvas__venn">
      <circle cx="-32" cy="32" r="64" className="canvas__venn-circle canvas__venn-circle--feasibility" />
      <circle cx="-32" cy="-32" r="64" className="canvas__venn-circle canvas__venn-circle--viability" />
      <circle cx="32" cy="32" r="64" className="canvas__venn-circle canvas__venn-circle--marketability" />
      <circle cx="32" cy="-32" r="64" className="canvas__venn-circle canvas__venn-circle--desirability" />

      <defs>
        <path d="M-96,32a64,64 0 1,0 128,0a64,64 0 1,0 -128,0" id="textPathFeasibility" />
        <path d="M-96,-32A64,64 0 1 132,-32A64,64 0 1 1-96,-32" id="textPathViability" />
        <path d="M-32,32a64,64 0 1,0 128,0a64,64 0 1,0 -128,0" id="textPathMarketability" />
        <path d="M-32,-32A64,64 0 1 196,-32A64,64 0 1 1-32,-32" id="textPathDesirability" />
      </defs>

      <text dx={2 * Math.PI * 64 * 0.125} dy="6" className="canvas__venn-text">
        <textPath xlinkHref="#textPathViability">Viability</textPath>
      </text>

      <text dx={2 * Math.PI * 64 * 0.375} dy="6" className="canvas__venn-text">
        <textPath xlinkHref="#textPathDesirability">Desirability</textPath>
      </text>

      <text dx={2 * Math.PI * 64 * 0.125} dy="-3" className="canvas__venn-text">
        <textPath xlinkHref="#textPathFeasibility">Feasibility</textPath>
      </text>

      <text dx={2 * Math.PI * 64 * 0.375} dy="-3" className="canvas__venn-text">
        <textPath side="left" xlinkHref="#textPathMarketability">
          Marketability
        </textPath>
      </text>
    </g>
  );
};

export default Venn;
