/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import {
  ASPECT_PRODUCT,
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
  ASPECT_PROBLEM,
  ASPECT_SOLUTION,
  ASPECT_UNIQUENESS,
  ASPECT_ALTERNATIVES,
} from './aspect.js';

export const CONTEXT_CENTER_RELATIONS = [
  [ASPECT_MOTIVATIONS, 'need', ASPECT_PRODUCT],
  [ASPECT_USERS, 'use', ASPECT_PRODUCT],
  [ASPECT_CUSTOMERS, 'buy', ASPECT_PRODUCT],
  [ASPECT_DISTRIBUTION, 'distribute', ASPECT_PRODUCT],
  [ASPECT_PRODUCTION, 'produce', ASPECT_PRODUCT],
  [ASPECT_ENABLERS, 'enable', ASPECT_PRODUCT],
  [ASPECT_DRIVERS, 'lead', ASPECT_PRODUCT],
  [ASPECT_GOALS, 'position', ASPECT_PRODUCT],
];

export const CONTEXT_CONTEXT_RELATIONS = [
  [ASPECT_GOALS, 'lead', ASPECT_DRIVERS],
  [ASPECT_DRIVERS, 'pursue', ASPECT_GOALS],
  [ASPECT_DRIVERS, 'control', ASPECT_ENABLERS],
  [ASPECT_ENABLERS, 'support', ASPECT_DRIVERS],
  [ASPECT_ENABLERS, 'empower', ASPECT_PRODUCTION],
  [ASPECT_PRODUCTION, 'leverage', ASPECT_ENABLERS],
  [ASPECT_PRODUCTION, 'supply', ASPECT_DISTRIBUTION],
  [ASPECT_DISTRIBUTION, 'pass', ASPECT_PRODUCTION],
  [ASPECT_DISTRIBUTION, 'reache', ASPECT_CUSTOMERS],
  [ASPECT_CUSTOMERS, 'receive', ASPECT_DISTRIBUTION],
  [ASPECT_CUSTOMERS, 'authorize', ASPECT_USERS],
  [ASPECT_USERS, 'convince', ASPECT_CUSTOMERS],
  [ASPECT_USERS, 'pursue', ASPECT_MOTIVATIONS],
  [ASPECT_MOTIVATIONS, 'move', ASPECT_USERS],
  [ASPECT_MOTIVATIONS, 'match', ASPECT_GOALS],
  [ASPECT_GOALS, 'match', ASPECT_MOTIVATIONS],
];

/** [Aspect, towards center, away from center]
export const INNER_CONTEXT_RELATIONS = [
  [ASPECT_GOALS, 'guides', 'supports'],
  [ASPECT_DRIVERS, 'leads', 'reports to'],
  [ASPECT_ENABLERS, 'supports', 'is based on'],
  [ASPECT_PRODUCTION, 'leads to', '?'],
  [ASPECT_DISTRIBUTION, 'distributes', '?'],
  [ASPECT_CUSTOMERS, '?', '?'],
  [ASPECT_USERS, '?', '?'],
  [ASPECT_MOTIVATIONS, 'drives', 'fullfills'],
];
*/

export const CONTEXT_CORE_RELATIONS = [
  [ASPECT_GOALS, 'inform', ASPECT_UNIQUENESS],
  [ASPECT_DRIVERS, 'shape', ASPECT_UNIQUENESS],
  [ASPECT_ENABLERS, 'make possible', ASPECT_SOLUTION],
  [ASPECT_PRODUCTION, 'builds', ASPECT_SOLUTION],
  [ASPECT_DISTRIBUTION, 'tackles', ASPECT_ALTERNATIVES],
  [ASPECT_CUSTOMERS, 'replace', ASPECT_ALTERNATIVES],
  [ASPECT_USERS, 'overcome', ASPECT_PROBLEM],
  [ASPECT_MOTIVATIONS, 'confront', ASPECT_PROBLEM],
];

export const CORE_CONTEXT_RELATIONS = [
  [ASPECT_UNIQUENESS, 'suits', ASPECT_GOALS],
  [ASPECT_UNIQUENESS, 'guides', ASPECT_DRIVERS],
  [ASPECT_SOLUTION, 'demands', ASPECT_ENABLERS],
  [ASPECT_SOLUTION, 'requires', ASPECT_PRODUCTION],
  [ASPECT_ALTERNATIVES, 'challenge', ASPECT_DISTRIBUTION],
  [ASPECT_ALTERNATIVES, 'influence', ASPECT_CUSTOMERS],
  [ASPECT_PROBLEM, 'hinders', ASPECT_USERS],
  [ASPECT_PROBLEM, 'impedes', ASPECT_MOTIVATIONS],
];

export const CORE_CENTER_RELATIONS = [
  [ASPECT_PROBLEM, 'demands', ASPECT_PRODUCT],
  [ASPECT_PRODUCT, 'solves', ASPECT_PROBLEM],
  [ASPECT_SOLUTION, 'is part of', ASPECT_PRODUCT],
  [ASPECT_PRODUCT, 'realizes', ASPECT_SOLUTION],
  [ASPECT_UNIQUENESS, 'distincts', ASPECT_PRODUCT],
  [ASPECT_PRODUCT, 'exhibits', ASPECT_UNIQUENESS],
  [ASPECT_ALTERNATIVES, 'replaces', ASPECT_PRODUCT],
  [ASPECT_PRODUCT, 'surpasses', ASPECT_ALTERNATIVES],
];

export const TO_CENTER_RELATIONS = CONTEXT_CENTER_RELATIONS.concat(CORE_CENTER_RELATIONS);
export const RELATIONS = [].concat(
  CORE_CENTER_RELATIONS,
  CONTEXT_CENTER_RELATIONS,
  CONTEXT_CONTEXT_RELATIONS,
  CONTEXT_CORE_RELATIONS,
  CORE_CONTEXT_RELATIONS
);

export function getVerb(c1, c2, fallback = '') {
  if (!c1 || !c2) {
    return fallback;
  }
  const relation = RELATIONS.find((r) => {
    return r[0] === c1.aspect && r[2] === c2.aspect;
  });
  return relation ? relation[1] : fallback;
}
