/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

const MenuItemSelect = ({ title, icon, iconColor, onChange, options }) => {
  return (
    <div className="context-menu-select">
      <div className="context-menu-select__icon">
        {icon && <Icon icon={icon} size="alpha" color={iconColor || 'highlight-light'} />}
      </div>
      <div className="context-menu-select__title">{title}</div>
      <div className="context-menu-select__arrow-right">
        <Icon icon="arrow-right" size="tiny" color="gray-600" />
      </div>

      <div className="context-menu-select__options context-menu">
        {options
          .filter(({ hidden = false }) => !hidden)
          .map((option) => {
            return (
              <div
                className={classNames('context-menu__item', { 'context-menu__item--disabled': option.disabled })}
                key={option.value}
              >
                <button
                  type="button"
                  className="context-menu-select__option"
                  onClick={(ev) => {
                    onChange(option.value);
                    ev.stopPropagation();
                  }}
                >
                  <div className="context-menu-select__option-content">
                    <div className="context-menu-select__option-check">
                      {option.selected && (
                        <Icon
                          icon="check-thick"
                          size="tiny"
                          color={option.checkmarkColor ? option.checkmarkColor : 'highlight-light'}
                        />
                      )}
                      {!option.selected && option.icon && <Icon icon={option.icon} size="tiny" color="gray-700" />}
                    </div>
                    {option.title}
                  </div>
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MenuItemSelect;
