/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export const CUE_TYPE_NONE = '~none';
export const CUE_TYPE_PRODUCT = 'Product';
export const CUE_TYPE_PERSON = 'Person';
export const CUE_TYPE_QUESTION = 'Question';
export const CUE_TYPE_ACTION_ITEM = 'ActionItem';

export const ALL_CUE_TYPES = [
  CUE_TYPE_NONE,
  CUE_TYPE_PRODUCT,
  CUE_TYPE_PERSON,
  CUE_TYPE_QUESTION,
  CUE_TYPE_ACTION_ITEM,
];
