/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useCallback, useEffect, useState } from 'react';

import useDebounce from '../utils/useDebounce';

import TextInput from './TextInput.jsx';

const DEFAULT_DEBOUNCE_DELAY = 300;

const DebouncedTextInput = (
  { value: externalValue, onChange, onImmediateChange, delay = DEFAULT_DEBOUNCE_DELAY, ...props },
  ref
) => {
  const debounce = useDebounce();
  const [value, setValue] = useState(externalValue);

  useEffect(() => {
    setValue(externalValue);
    // Only update the internal value to the external one, when onChange changed,
    // signaling a change in the handled. This means, that the correct use of useCallback
    // is required, when reusing a DebouncedTextInput component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  const debouncedOnChange = useCallback(
    (v, isValueValid) => {
      if (onImmediateChange) {
        onImmediateChange(v, isValueValid);
      }
      debounce(() => onChange(v, isValueValid), delay);
    },
    [debounce, onChange, delay, onImmediateChange]
  );

  return <TextInput {...props} onChange={debouncedOnChange} value={value} ref={ref} />;
};

export default React.forwardRef(DebouncedTextInput);
