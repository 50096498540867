/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { tracking } from '@fieldbyfield/business';
import { Paragraph, Button } from '@fieldbyfield/components';

import './CookieBanner.scss';

const CookieBanner = () => {
  const userEmail = useSelector((state) => state.user.email);
  const [cookiesAccepted, setCookiesAccepted] = useState(document.cookie.indexOf('cookie-banner-confirmed=1') !== -1);

  useEffect(() => {
    if (tracking.haveConsent(null, navigator.doNotTrack, cookiesAccepted)) {
      tracking.enable(null);
    } else {
      tracking.disable();
    }
    tracking.amplitude().logEvent('collect: open app');
  }, [cookiesAccepted]);

  useEffect(() => {
    if (userEmail) {
      tracking.amplitude().setUserProperties({ email: userEmail });
    }
  }, [userEmail]);

  if (cookiesAccepted || navigator.doNotTrack === '1') {
    return null;
  }

  return (
    <div className="cookie-banner">
      <Paragraph>This website uses cookies to ensure you get the best experience on our website.</Paragraph>
      <div className="cookie-banner__actions">
        <Button
          type="primary"
          theme="light"
          onClick={() => {
            document.cookie = 'cookie-banner-confirmed=1;path=/;max-age=864000';
            setCookiesAccepted(true);
          }}
        >
          Got it
        </Button>
        <a href="https://field.so/privacy" target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
      </div>
    </div>
  );
};

export default CookieBanner;
