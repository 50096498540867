/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './SectionSeparator.scss';

const SectionSeparator = ({ children, spacing = 'default', id, meta = null, rightAccessory }) => {
  return (
    <div className={classNames('section-separator', `section-separator--spacing-${spacing}`)}>
      {children && (
        <div className="section-separator__name" id={id}>
          {children}
          {meta !== null && <span className="section-separator__meta"> ({meta})</span>}
        </div>
      )}
      <div className="section-separator__line" />
      {rightAccessory && <div className="section-separator__right-accessory">{rightAccessory}</div>}
    </div>
  );
};

export default SectionSeparator;
