/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useCallback } from 'react';

import classNames from 'classnames';

import Button from '../Button';

import './MetaInfoButton.scss';

const MetaInfoButton = ({
  title,
  value,
  action,
  onClick,
  to,
  highlight,
  theme = 'default',
  noSpacer,
  ButtonLink,
  ...props
}) => {
  const ButtonComponent = useCallback((p) => <Button {...p} onClick={onClick} />, [onClick]);
  const LinkComponent = useCallback((p) => <ButtonLink {...p} to={to} />, [to]);

  let Component = ButtonComponent;
  if (to) {
    Component = LinkComponent;
  }

  return (
    <div
      className={classNames(
        'meta-info-button',
        `meta-info-button--theme-${theme}`,
        highlight && 'meta-info-button--highlight',
        noSpacer && 'meta-info-button--no-spacer'
      )}
    >
      <Component type="ghost-slim" {...props}>
        <div className="meta-info-button__content">
          {value !== undefined && <div className="meta-info-button__value">{value}</div>}
          <div className="meta-info-button__label">
            <div className="meta-info-button__title">{title}</div>
            <div className="meta-info-button__action">{action}</div>
          </div>
        </div>
      </Component>
    </div>
  );
};

export default MetaInfoButton;
