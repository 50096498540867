/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect } from 'react';

import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { crash, withErrorTracking, ErrorBoundary } from '@fieldbyfield/business';
import { AppFrame } from '@fieldbyfield/components';
import { BrowserRouter, Switch, Route, useLocation, Redirect, useHistory } from '@fieldbyfield/router';

import CookieBanner from './CookieBanner';
import DefaultPage from './DefaultPage';
import Home from './Home';
import InfoBlock from './InfoBlock';
import { reducer, setAuthToken, fetchUser } from './redux';
import preloadedState from './redux/preloadedState';
import Survey from './Survey';

import '@fieldbyfield/components/build/style.css';

import './App.scss';

// We need to pass the history object into the Router as well as the crash
// reporter.
const globalHistory = createBrowserHistory();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Routing = ({ saveStateToLocaleStorage }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const query = useQuery();

  const tokenFromURL = query.get('access_token');
  if (tokenFromURL) {
    dispatch(setAuthToken(tokenFromURL));
    history.push(`${pathname}/introduction`);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const authError = useSelector((state) => state.ui.authError);

  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUser(null));
  }, [dispatch, token]);

  useEffect(() => {
    crash.setUser(user);
  }, [user]);

  return (
    <ErrorBoundary>
      <div className="app">
        <CookieBanner />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/workspaces/:workspaceId/surveys/:surveyId">
            {(!token || authError) && (
              <DefaultPage
                title="You do not seem authorized to view this survey."
                description={`If you received an email be sure to use the link you were
                sent.`}
                infoBlocks={
                  <>
                    <InfoBlock title="Where Can I Answer the Survey?" icon="pf-collect" iconColor="blueberry-pancakes">
                      If you are looking for a specific survey, make sure to click on the link you received in an email.
                      You can only answer the survey by following this link.
                    </InfoBlock>
                    <InfoBlock title="Learn More" icon="docs" iconColor="raspberry-sorbet">
                      Collect helps you to gather knowledge and enable participation in your organization. To learn more
                      about Collect make sure to check out our website,{' '}
                      <a href="https://field.so" target="_blank" rel="noopener noreferrer">
                        www.field.so
                      </a>
                      .
                    </InfoBlock>
                  </>
                }
              />
            )}
            {token && !authError && <Survey saveStateToLocaleStorage={saveStateToLocaleStorage} />}
          </Route>
          <Route path="/*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </ErrorBoundary>
  );
};

const store = configureStore({
  reducer,
  devTools: process.env.REACT_APP_DEBUG === 'y',
  preloadedState: loadState(),
  enhancers: [crash.reduxEnhancer()],
});

function saveCurrentStateToLocaleStorage() {
  const state = store.getState();
  const { user, auth, survey, questions } = state;
  const stateToSave = {
    user: {
      timeboxTotal: user.timeboxTotal,
      timeboxRemaining: user.timeboxRemaining,
      timeboxIsRunning: user.timeboxIsRunning,
      timeboxIsActive: user.timeboxIsActive,
    },
    auth,
    data: {
      workspace: survey.workspace,
      survey: survey.id,
      questions: questions.map((q) => ({
        id: q.id,
        cues: q.cues,
        newCue: q.newCue,
        inspirations: q.inspirations.filter((i) => i.custom),
      })),
    },
  };
  localStorage.setItem('state', JSON.stringify(stateToSave));
}

const App = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', saveCurrentStateToLocaleStorage);
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppFrame>
          <Routing saveStateToLocaleStorage={saveCurrentStateToLocaleStorage} />
        </AppFrame>
      </BrowserRouter>
    </Provider>
  );
};

function loadState() {
  try {
    const serializedState = localStorage.getItem('state');

    if (serializedState === null) {
      return preloadedState;
    }
    const localState = JSON.parse(serializedState);

    // localState.data may contain cues and inspiration entered into a survey
    // but not submitted yet. We don’t want to load them here, we do that after
    // loading the survey and checking if it is actually the same one.
    delete localState.data;

    return {
      ...preloadedState,
      ...localState,
    };
  } catch (err) {
    console.log(err);
    return preloadedState;
  }
}

export default withErrorTracking(App, {
  enabled: process.env.REACT_APP_REPORT_CRASHES === 'y',
  trace: process.env.REACT_APP_TRACE === 'y',
  context: process.env.REACT_APP_CONTEXT,
  history: globalHistory,
  store,
});
