/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

// Manage requests that are currently in-flight. Identifies requests by the "ID"
// of the request. Ususally this is an hash over props of the request object.
// However how this ID is calculated is left to the user of the request manager.
export class RunningRequestsManager {
  constructor() {
    // Mapping a hash of the request to an instance of AbortController for
    // request cancellation.
    this.requests = {};
  }

  // Checks if the request identified by its ID is currently in-flight.
  has(id) {
    if (this.requests[id]) {
      return true;
    }
    return false;
  }

  add(id) {
    this.requests[id] = new AbortController();
    return this.requests[id].signal;
  }

  // Cancels an in-flight request given its hash.
  cancel(id) {
    this.requests[id].abort();
    delete this.requests[id];
  }

  clean(id) {
    delete this.requests[id];
  }
}
