/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export const CANVAS_STYLE_OPTIONS = {
  subtle: 'subtle',
  hidden: 'hidden',
  prominent: 'prominent',
};

export const CANVAS_DOTGRID_OPTIONS = {
  fine: 'fine',
  default: 'default',
  coarse: 'coarse',
  hidden: 'hidden',
};
