/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './AutogrowingInput.scss';

/**
 * The AutogrowingInput is a non-styled utility component,
 * that contains an input element which grows horizontally as content
 * is typed in.
 *
 * All further props are passed on to the input element.
 *
 * @param {Object} props
 * @param {string} props.value
 * @param {string} [props.placeholder]
 * @param {boolean} [props.readOnly]
 * @param {string} [props.className]
 * @param {ref} ref
 */
const AutogrowingInput = React.forwardRef(({ value, placeholder, className, readOnly, ...props }, ref) => {
  return (
    <div className={classNames('autogrowing-input', className)}>
      <input
        className="autogrowing-input__input"
        readOnly={readOnly}
        ref={ref}
        value={value}
        placeholder={placeholder}
        tabIndex={readOnly ? -1 : 0}
        {...props}
      />
      <div className="autogrowing-input__spacer" aria-hidden>
        {value || placeholder}
      </div>
    </div>
  );
});

export default AutogrowingInput;
