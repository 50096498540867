/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './Icon.scss';

const baseUrl = process.env.ICONS_EXTERNAL_URL;

const Icon = ({ icon, size = 'beta', color = 'currentColor' }) => {
  if (color !== 'currentColor' && !color.startsWith('#') && !color.startsWith('rgb')) {
    color = `var(--${color})`;
  }

  return (
    <span
      className={classNames('icon', `icon--${size}`)}
      style={{
        '--icon-color': color,
        '--icon-image': `url(${baseUrl}/${icon}.svg)`,
      }}
    />
  );
};

export default React.memo(Icon);
