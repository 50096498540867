/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState } from 'react';

import classNames from 'classnames';

import ContextMenu, { CONTEXT_MENU_ITEM_TYPES } from '../ContextMenu';
import Floating from '../Floating';
import Icon from '../Icon';

import './Toolbar.scss';

const Toolbar = ({ actions = [], additionalActions = [], viewOptions = [], theme = 'light' }) => {
  const [contextMenuPosition, setContextMenuPosition] = useState([null, null]);

  const contextMenuHandler = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (ev.clientX && ev.clientY) {
      setContextMenuPosition([ev.clientX, ev.clientY]);
    } else {
      // This event seems to have been triggered by keyboard interaction with a button
      const rect = ev.target.getBoundingClientRect();
      setContextMenuPosition([rect.x, rect.y]);
    }
  };

  return (
    <div className={classNames('toolbar', `toolbar--${theme}`)}>
      {contextMenuPosition && contextMenuPosition[0] !== null && (
        <Floating x={contextMenuPosition[0]} y={contextMenuPosition[1]}>
          <ContextMenu
            menuItems={additionalActions.map((a) => ({
              ...a,
              type: CONTEXT_MENU_ITEM_TYPES.button,
            }))}
            onDismiss={() => {
              setContextMenuPosition([null, null]);
            }}
          />
        </Floating>
      )}

      <div className="toolbar__actions">
        {actions
          .filter(({ hidden }) => !hidden)
          .map((a) => (
            <button
              className={classNames('toolbar__button', {
                'toolbar__button--disabled': a.disabled,
              })}
              disabled={a.disabled}
              onClick={a.onClick}
              type="button"
              key={a.title}
            >
              <span className="toolbar__button-content">
                <Icon icon={a.icon} size="alpha" color={theme === 'dark' ? 'gray-600' : 'gray-500'} /> {a.title}
              </span>
            </button>
          ))}
        {additionalActions.length > 0 && (
          <button
            className={classNames('toolbar__button', 'toolbar__button--more', 'toolbar__button--icon-only', {
              'toolbar__button--disabled': additionalActions.every((a) => a.disabled),
              'toolbar__button--active': contextMenuPosition && contextMenuPosition[0] !== null,
            })}
            disabled={additionalActions.every((a) => a.disabled)}
            onClick={contextMenuHandler}
            type="button"
            key=""
          >
            <span className="toolbar__button-content">
              <Icon icon="options" size="alpha" color={theme === 'dark' ? 'gray-500' : 'gray-600'} />
            </span>
          </button>
        )}
      </div>

      {viewOptions.length > 0 && (
        <div className="toolbar__view-modes">
          {viewOptions.map((a, i) => {
            if (a.type === 'SEPARATOR') {
              // We have to use the index here as key
              // eslint-disable-next-line react/no-array-index-key
              return <div className="toolbar__view-mode-separator" key={`separator-${i}`} />;
            }
            return (
              <button
                className={classNames('toolbar__view-mode-button', {
                  'toolbar__view-mode-button--active': a.active,
                })}
                onClick={a.onClick}
                type="button"
                label={a.label}
                key={a.label}
              >
                <span className="toolbar__view-mode-button-content">
                  <Icon icon={a.icon} size="alpha" color={theme === 'dark' ? 'gray-500' : 'gray-600'} />
                </span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Toolbar;
