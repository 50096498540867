/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

/* eslint-disable import/prefer-default-export */

export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_USER = 'user';
export const USER_ROLE_GUEST = 'guest';

export const ALL_USER_ROLES = [USER_ROLE_ADMIN, USER_ROLE_USER, USER_ROLE_GUEST];

export const WORKSPACE_PLAN_WAITLIST = 'waitlist';
export const WORKSPACE_PLAN_BASIC = 'basic';
export const WORKSPACE_PLAN_PRO = 'pro';
export const WORKSPACE_PLAN_PRO_TRIAL = 'pro-trial';
export const WORKSPACE_PLAN_TEAM = 'team';
export const WORKSPACE_PLAN_TEAM_TRIAL = 'team-trial';
export const WORKSPACE_PLAN_ENTERPRISE = 'enterprise';
export const ALL_WORKSPACE_PLANS = [
  WORKSPACE_PLAN_WAITLIST,
  WORKSPACE_PLAN_BASIC,
  WORKSPACE_PLAN_PRO,
  WORKSPACE_PLAN_PRO_TRIAL,
  WORKSPACE_PLAN_TEAM,
  WORKSPACE_PLAN_TEAM_TRIAL,
  WORKSPACE_PLAN_ENTERPRISE,
];
export const PRO_FEATURES_WORKSPACE_PLANS = [
  WORKSPACE_PLAN_PRO,
  WORKSPACE_PLAN_PRO_TRIAL,
  WORKSPACE_PLAN_TEAM,
  WORKSPACE_PLAN_TEAM_TRIAL,
  WORKSPACE_PLAN_ENTERPRISE,
];

export class BasicAPI {
  constructor({ request }) {
    this.request = request;
  }

  getVersion() {
    return this.request('GET', 'hello').then((data) => {
      return data.version;
    });
  }

  // Humans/Users...

  // Returns information about the currently authenticated user.
  me({ withAdminOverride } = {}) {
    return this.request('GET', 'me', { with_admin_override: withAdminOverride });
  }

  // Invites a new human as a user to the product.
  //
  // Usage:
  //   Client.basic.invite({
  //     human: {
  //       name: 'Kate Miller', // optional
  //       email: 'kate@example.com'
  //     }
  //   });
  invite({ human }) {
    return this.request('PUT', `humans`, {}, human);
  }

  // Retrieves information about humans.
  //
  // Usage:
  //   Client.basic.getHumans({
  //     humans: [
  //        { id: "f1d2d2f924e..." },
  //        // ...
  //     ]
  //   });
  getHumans({ humans }) {
    return this.request(
      'GET',
      `humans`,
      {
        id: humans.map((v) => v.id).sort(),
      },
      {}
    );
  }

  getHuman({ human }) {
    return this.request('GET', `humans/${human.id}`);
  }

  setHuman({ human }) {
    return this.request('POST', `humans/${human.id}`, {}, human);
  }

  getUsers({ users }) {
    return this.request(
      'GET',
      `users`,
      {
        id: users.map((v) => v.id).sort((a, b) => a - b),
      },
      {}
    );
  }

  getUser({ user }) {
    return this.request('GET', `users/${user.id}`);
  }

  setUser({ user }) {
    return this.request('POST', `users/${user.id}`, {}, user);
  }

  // Workspaces...

  // Retrieves given workspaces.
  getWorkspaces({ workspaces }) {
    return this.request(
      'GET',
      `workspaces`,
      {
        id: workspaces.map((v) => v.id).sort((a, b) => a - b),
      },
      {}
    );
  }

  addWorkspace({ workspace }) {
    return this.request('PUT', `workspaces`, {}, workspace);
  }

  setWorkspace({ workspace }) {
    return this.request('POST', `workspaces/${workspace.id}`, {}, workspace);
  }

  getWorkspace({ workspace }) {
    return this.request('GET', `workspaces/${workspace.id}`);
  }

  deleteWorkspace({ workspace }) {
    return this.request('DELETE', `workspaces/${workspace.id}`);
  }

  inviteMember({ workspace, user }) {
    return this.request('PUT', `workspaces/${workspace.id}/members`, {}, user);
  }

  removeMember({ workspace, subject }) {
    return this.request('DELETE', `workspaces/${workspace.id}/members/${subject.id}`);
  }

  setWorkspaceTemplates({ workspace, templates }) {
    return this.request('POST', `workspaces/${workspace.id}/templates`, {}, templates);
  }

  getWorkspaceTemplates({ workspace }) {
    return this.request('GET', `workspaces/${workspace.id}/templates`);
  }

  getWorkspaceKb({ workspace }) {
    return this.request('GET', `workspaces/${workspace.id}/kb`);
  }

  // Deprecated / BC

  inviteUser({ user }) {
    console.warn('Client.basic.inviteUser({ user }) is depreacted in favor of Client.basic.invite({ human }).');
    return this.invite({ human: user });
  }

  getMe() {
    console.warn('Client.basic.getMe() is depreacted in favor of Client.basic.me().');
    return this.me();
  }

  setMe({ user }) {
    console.warn('Client.basic.setMe() is deprecated, please use Client.basic.setUser() instead.');

    if (user.id) {
      return this.setUser({ user });
    }
    return this.me().then((me) => {
      return this.setUser({ ...user, id: me.id });
    });
  }
}
