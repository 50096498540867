/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classnames from 'classnames';

import FallbackImage from './FallbackImage.jsx';

import './UserAvatar.scss';

const guessNameFromEmail = (email) => {
  if (!email) {
    return '';
  }

  return email
    .split('@')[0]
    .split('.')
    .map((p) => p[0].toUpperCase() + p.slice(1).toLowerCase())
    .join(' ');
};

// UserAvatar renders either as the user's avatar image, which may be available
// under user.avatar, or using a fallback method renders the user's initials.
const UserAvatar = ({ user, size = 'beta' }) => {
  if (user.avatar) {
    return (
      <span className={classnames('user-avatar', `user-avatar--${size}`)}>
        <img className="user-avatar__image" src={user.avatar} alt={user.name || user.email} />
      </span>
    );
  }
  return (
    <span className={classnames('user-avatar', `user-avatar--${size}`, 'user-avatar--with-fallback')}>
      <FallbackImage id={user.id} name={user.name || guessNameFromEmail(user.email)} />
    </span>
  );
};

export default UserAvatar;
