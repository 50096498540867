/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';
import { paramCase } from 'param-case';

import { CANVAS_DOTGRID_OPTIONS, CANVAS_STYLE_OPTIONS } from './constants.js';
import DotGrid from './DotGrid.jsx';
import Venn from './Venn.jsx';

import './ProductFieldCanvas.scss';

/*
This component treats the product field canvas as
a coordinate system, with the product at its center (0,0).

|--------------------| width                   = 2 * 100 = 20
|mmcccxxxxooxxxxcccmm| m margin      2 * mm    = 2 *  20 =  40
                       c context     2 * ccc   = 2 *  30 =  60
                       x core        2 * xxxxx = 2 *  35 =  70
                       o center      2 * o     = 2 *  15 =  30

The `highlight` param takes different values to highlight certain
elements in the canvas

- `marker` - highlights the marker element used when explaining
    the canvas
- `introduction` - animates introduction axis from top to bottom
- `realization` - animates  realization axis from left to right
- `activities` - animates both introduction and realization axis
    introduction und realizatio are main *activities* for any
    innovation
- `product` - the diamond in the center
- `core` - all areas in the core
- `context` - all areas in the context
- `elements` - text in the four corners
- `idea` | `resources` | `market` | `value`
  highlights the quadrants with all areas
- `goals` |  `drivers` | `uniqueness` | `motivations` | `users` | `problem` | `resources`  
  | `enablers` | `production` | `solution` | `market` | `distribution` | `customers`
  | `alternatives`  
  highlights the corresponding area
        
*/

const ProductFieldCanvas = ({
  dotgrid = CANVAS_DOTGRID_OPTIONS.default,
  highlight,
  frame = CANVAS_STYLE_OPTIONS.subtle,
  labels = CANVAS_STYLE_OPTIONS.subtle,
  venn = CANVAS_STYLE_OPTIONS.hidden,
  jargon = {},
  theme,
  labelsToShow,
  showProduct = true,
  showCore = true,
  showContext = true,
  showElements = true,
}) => {
  const style = {};
  if (theme) {
    if (theme.labels) {
      style[`--label-color`] = theme.labels;
    }
    if (theme.frame) {
      style[`--frame-line-color`] = theme.frame;
    }
    if (theme.frame) {
      style[`--frame-circle-color`] = theme.frame;
    }
    if (theme.dotgrid) {
      style[`--dotgrid-color`] = theme.dotgrid;
    }
    if (theme.vennLines) {
      style[`--venn-line-color`] = theme.vennLines;
    }
    if (theme.vennFill) {
      style[`--venn-fill-color`] = theme.vennFill;
    }
    if (theme.vennLabels) {
      style[`--venn-label-color`] = theme.vennLabels;
    }
    if (theme.fill) {
      style[`--fill-color`] = theme.fill;
    }
    if (theme.hatch) {
      style[`--hatch-color`] = theme.hatch;
    }
    if (theme.highlight) {
      style[`--highlight-color`] = theme.highlight;
    }
    if (theme.highlightLabels) {
      style[`--highlight-label-color`] = theme.highlightLabels;
    }
    if (theme.highlightFrame) {
      style[`--highlight-frame-color`] = theme.highlightFrame;
    }
    if (theme.highlightVenn) {
      style[`--highlight-venn-color`] = theme.highlightVenn;
    }
    if (theme.highlightDotgrid) {
      style[`--highlight-dotgrid-color`] = theme.highlightDotgrid;
    }
    if (theme.highlightFill) {
      style[`--highlight-fill-color`] = theme.highlightFill;
    }
  }

  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      className={classNames(
        'canvas',
        highlight && highlight.split(' ').map((h) => `canvas--highlight-${paramCase(h)}`),
        `canvas--labels-${labels}`,
        `canvas--frame-${frame}`,
        `canvas--venn-${venn}`,
        !showContext && 'canvas--hide-context',
        !showCore && 'canvas--hide-core',
        !showProduct && 'canvas--hide-product',
        !showElements && 'canvas--hide-elements',
        labelsToShow && `canvas--labels-show-explicit`,
        labelsToShow && labelsToShow.map((l) => `canvas--labels-show-${paramCase(l)}`)
      )}
      style={style}
    >
      <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4" patternTransform="scale(.75)">
        <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" className="canvas__hatch-pattern" />
      </pattern>
      <g transform="translate(100, 100)">
        {/* Fills */}
        {/* fills for IDEA, top left quadrant */}
        <path
          // d="m-80 -80 l80 0 l0 30 l-50 0 M -35 -63 h -16 v 5.5 h 16 z" // Cut out hole for text
          d="m-80 -80 l80 0 l0 30 l-50 0 z"
          className="canvas__idea canvas__goals canvas__context canvas__fill"
        />
        <path
          d="m-80 -80 l30 30 l0 50 l-30 0 z"
          className="canvas__idea canvas__drivers canvas__context canvas__fill"
        />
        <path
          d="m-50 -50 l50 0 l0 35 l-15 15 l-35 0 z"
          className="canvas__idea canvas__uniqueness canvas__core canvas__fill"
        />
        {/* fills for RESOURCES, bottom left quadrant */}
        <path
          d="m-80 80 l80 0 l0 -30 l-50 0 z"
          className="canvas__resources canvas__production canvas__context canvas__fill"
        />
        <path
          d="m-80 80 l30 -30 l0 -50 l-30 0 z"
          className="canvas__resources canvas__enablers canvas__context canvas__fill"
        />
        <path
          d="m-50 50 l50 0 l0 -35 l-15 -15 l-35 0 z"
          className="canvas__resources canvas__solution canvas__core canvas__fill"
        />
        {/* fills for MARKET, bottom right quadrant */}
        <path
          d="m80 80 l-80 0 l0 -30 l50 0 z"
          className="canvas__market canvas__distribution canvas__context canvas__fill"
        />
        <path
          d="m80 80 l-30 -30 l0 -50 l30 0 z"
          className="canvas__market canvas__customers canvas__context canvas__fill"
        />
        <path
          d="m50 50 l-50 0 l0 -35 l15 -15 l35 0 z"
          className="canvas__market canvas__alternatives canvas__core canvas__fill"
        />
        {/* fills for VALUE, top right quadrant */}
        <path
          d="m80 -80 l-80 0 l0 30 l50 0 z"
          className="canvas__value canvas__motivations canvas__context canvas__fill"
        />
        <path d="m80 -80 l-30 30 l0 50 l30 0 z" className="canvas__value canvas__users canvas__context canvas__fill" />
        <path
          d="m50 -50 l-50 0 l0 35 l15 15 l35 0 z"
          className="canvas__value canvas__problem canvas__core canvas__fill"
        />
        <path d="m-15 0 l15 15 l15 -15 l-15 -15 z" className="canvas__product canvas__fill" />
        {dotgrid !== CANVAS_DOTGRID_OPTIONS.hidden && <DotGrid style={dotgrid} />}
        {/* lines for IDEA, top left quadrant */}
        <line
          x1="-80"
          y1="-80"
          x2="-78"
          y2="-78"
          className="canvas__idea canvas__drivers canvas__goals canvas__context canvas__line "
        />
        <line
          x1="-78"
          y1="-78"
          x2="-71"
          y2="-71"
          className="canvas__idea canvas__drivers canvas__goals canvas__context canvas__line canvas__line--under-venn"
        />
        <line
          x1="-71"
          y1="-71"
          x2="-50"
          y2="-50"
          className="canvas__idea canvas__drivers canvas__goals canvas__context canvas__line "
        />
        <line
          x1="-82.5"
          y1="-80"
          x2="-80"
          y2="-80"
          className="canvas__idea canvas__drivers canvas__context canvas__line canvas__marker canvas__elements canvas__line--corner"
        />
        <line
          x1="-80"
          y1="-82.5"
          x2="-80"
          y2="-80"
          className="canvas__idea canvas__goals canvas__context canvas__line canvas__marker canvas__elements canvas__line--corner"
        />
        <line
          x1="-50"
          y1="-50"
          x2="-50"
          y2="0"
          className="canvas__idea canvas__drivers canvas__uniqueness canvas__context canvas__core canvas__line "
        />
        <line
          x1="-50"
          y1="-50"
          x2="0"
          y2="-50"
          className="canvas__idea canvas__goals canvas__uniqueness canvas__context canvas__core canvas__line "
        />
        <line
          x1="-50"
          y1="0"
          x2="-15"
          y2="0"
          className="canvas__idea canvas__uniqueness canvas__resources canvas__solution canvas__core canvas__line "
        />
        <line
          x1="-50"
          y1="0"
          x2="-82.5"
          y2="0"
          className="canvas__idea canvas__drivers canvas__resources canvas__enablers canvas__context canvas__line canvas__line--dashed "
        />
        <line
          x1="-50"
          y1="0"
          x2="-53"
          y2="0"
          className="canvas__idea canvas__drivers canvas__resources canvas__enablers  canvas__context canvas__line canvas__line--highlight-only"
        />
        {/* lines for RESOURCES, bottom left quadrant */}
        <line
          x1="-80"
          y1="80"
          x2="-78"
          y2="78"
          className="canvas__resources canvas__enablers canvas__production canvas__context canvas__line "
        />
        <line
          x1="-78"
          y1="78"
          x2="-71"
          y2="71"
          className="canvas__resources canvas__enablers canvas__production canvas__context canvas__line canvas__line--under-venn"
        />
        <line
          x1="-71"
          y1="71"
          x2="-50"
          y2="50"
          className="canvas__resources canvas__enablers canvas__production canvas__context canvas__line "
        />
        <line
          x1="-82.5"
          y1="80"
          x2="-80"
          y2="80"
          className="canvas__resources canvas__enablers canvas__context  canvas__line canvas__marker canvas__elements canvas__line--corner"
        />
        <line
          x1="-80"
          y1="82.5"
          x2="-80"
          y2="80"
          className="canvas__resources canvas__production canvas__context canvas__line canvas__marker canvas__elements canvas__line--corner"
        />
        <line
          x1="-50"
          y1="50"
          x2="-50"
          y2="0"
          className="canvas__resources canvas__enablers canvas__solution canvas__context canvas__core canvas__line "
        />
        <line
          x1="-50"
          y1="50"
          x2="0"
          y2="50"
          className="canvas__resources canvas__production canvas__solution canvas__context canvas__core canvas__line "
        />
        <line
          x1="0"
          y1="50"
          x2="0"
          y2="15"
          className="canvas__resources canvas__solution canvas__market canvas__alternatives canvas__core canvas__line "
        />
        <line
          x1="0"
          y1="50"
          x2="0"
          y2="82.5"
          className="canvas__resources canvas__production canvas__market canvas__distribution canvas__context canvas__line canvas__line--dashed "
        />
        <line
          x1="0"
          y1="50"
          x2="0"
          y2="53"
          className="canvas__resources canvas__production canvas__market canvas__distribution canvas__context canvas__line canvas__line--highlight-only"
        />
        {/* lines for MARKET, bottom right quadrant */}
        <line
          x1="80"
          y1="80"
          x2="78"
          y2="78"
          className="canvas__market canvas__distribution canvas__customers canvas__context canvas__line "
        />
        <line
          x1="78"
          y1="78"
          x2="71"
          y2="71"
          className="canvas__market canvas__distribution canvas__customers canvas__context canvas__line canvas__line--under-venn"
        />
        <line
          x1="71"
          y1="71"
          x2="50"
          y2="50"
          className="canvas__market canvas__distribution canvas__customers canvas__context canvas__line "
        />
        <line
          x1="82.5"
          y1="80"
          x2="80"
          y2="80"
          className="canvas__market canvas__customers canvas__context canvas__line canvas__marker canvas__elements canvas__line--corner"
        />
        <line
          x1="80"
          y1="82.5"
          x2="80"
          y2="80"
          className="canvas__market canvas__distribution canvas__context canvas__line canvas__marker canvas__elements canvas__line--corner"
        />
        <line
          x1="0"
          y1="50"
          x2="50"
          y2="50"
          className="canvas__market canvas__distribution canvas__alternatives canvas__context canvas__core canvas__line "
        />
        <line
          x1="50"
          y1="0"
          x2="50"
          y2="50"
          className="canvas__market canvas__customers canvas__alternatives canvas__context canvas__core canvas__line "
        />
        <line
          x1="15"
          y1="0"
          x2="50"
          y2="0"
          className="canvas__market canvas__alternatives canvas__value canvas__problem canvas__core canvas__line"
        />
        <line
          x1="50"
          y1="0"
          x2="82.5"
          y2="0"
          className="canvas__market canvas__customers canvas__value canvas__users canvas__context canvas__line canvas__line--dashed "
        />
        <line
          x1="50"
          y1="0"
          x2="53"
          y2="0"
          className="canvas__market canvas__customers canvas__value canvas__users canvas__context canvas__line canvas__line--highlight-only"
        />
        {/* lines for VALUE, top right quadrant */}
        <line
          x1="80"
          y1="-80"
          x2="78"
          y2="-78"
          className="canvas__value canvas__motivations canvas__users canvas__context canvas__line "
        />
        <line
          x1="78"
          y1="-78"
          x2="71"
          y2="-71"
          className="canvas__value canvas__motivations canvas__users canvas__context canvas__line canvas__line--under-venn"
        />
        <line
          x1="71"
          y1="-71"
          x2="50"
          y2="-50"
          className="canvas__value canvas__motivations canvas__users canvas__context canvas__line "
        />
        <line
          x1="82.5"
          y1="-80"
          x2="80"
          y2="-80"
          className="canvas__value canvas__users canvas__context canvas__line canvas__marker canvas__elements canvas__line--corner"
        />
        <line
          x1="80"
          y1="-82.5"
          x2="80"
          y2="-80"
          className="canvas__value canvas__motivations canvas__context canvas__line canvas__marker canvas__elements canvas__line--corner"
        />
        <line
          x1="50"
          y1="-50"
          x2="50"
          y2="0"
          className="canvas__value canvas__users canvas__problem canvas__context canvas__core canvas__line "
        />
        <line
          x1="0"
          y1="-50"
          x2="50"
          y2="-50"
          className="canvas__value canvas__motivations canvas__problem canvas__context canvas__core  canvas__line "
        />
        <line
          x1="0"
          y1="-50"
          x2="0"
          y2="-15"
          className="canvas__value canvas__problem canvas__idea canvas__uniqueness canvas__core canvas__line "
        />
        <line
          x1="0"
          y1="-50"
          x2="0"
          y2="-82.5"
          className="canvas__value canvas__motivations canvas__idea canvas__goals canvas__context canvas__line canvas__line--dashed "
        />
        <line
          x1="0"
          y1="-50"
          x2="0"
          y2="-53"
          className="canvas__value canvas__motivations canvas__idea canvas__goals canvas__context canvas__line canvas__line--highlight-only"
        />
        <circle
          cx="-50"
          cy="-50"
          r=".75"
          className="canvas__idea canvas__drivers canvas__goals canvas__uniqueness canvas__context canvas__core canvas__marker canvas__circle"
        />
        <circle
          cx="-50"
          cy="50"
          r=".75"
          className="canvas__resources canvas__production canvas__enablers canvas__solution canvas__context canvas__core canvas__marker canvas__circle"
        />
        <circle
          cx="50"
          cy="50"
          r=".75"
          className="canvas__market canvas__alternatives canvas__distribution canvas__customers canvas__context canvas__core canvas__marker canvas__circle"
        />
        <circle
          cx="50"
          cy="-50"
          r=".75"
          className="canvas__value canvas__users canvas__problem canvas__motivations canvas__context canvas__core canvas__marker canvas__circle"
        />
        <g className="canvas__product">
          <line
            x1="0"
            y1="-15"
            x2="-15"
            y2="0"
            className="canvas__product canvas__idea canvas__uniqueness canvas__core canvas__marker canvas__line "
          />
          <line
            x1="0"
            y1="-15"
            x2="15"
            y2="0"
            className="canvas__product canvas__value canvas__problem canvas__core canvas__marker canvas__line "
          />
          <line
            x1="15"
            y1="0"
            x2="0"
            y2="15"
            className="canvas__product canvas__market canvas__alternatives canvas__core canvas__marker canvas__line "
          />
          <line
            x1="-15"
            y1="0"
            x2="0"
            y2="15"
            className="canvas__product canvas__resources canvas__solution canvas__core canvas__marker canvas__line "
          />
        </g>
        <g className="canavs__activities">
          <line
            x1="-82.5"
            y1="0"
            x2="82.5"
            y2="0"
            className="canvas__introduction canvas__activities canvas__line canvas__line--highlight-only"
          />
          <line
            x1="0"
            y1="-82.5"
            x2="0"
            y2="82.5"
            className="canvas__realization canvas__activities canvas__line canvas__line--highlight-only"
          />
        </g>
        <g className="canvas__labels">
          <text
            x="-81.5"
            y="-81.5"
            className="canvas__idea canvas__idea-element canvas__label--element canvas__label canvas__label--top canvas__label--right canvas__elements"
          >
            <tspan dy="-4">ID</tspan>
            <tspan x="-81.5" dy="4">
              EA
            </tspan>
          </text>
          <text x="-40" y="-25" className="canvas__idea canvas__uniqueness canvas__core canvas__label">
            {jargon.uniqueness || 'uniqueness'}
          </text>
          <text
            x="-60"
            y="-50"
            className="canvas__idea canvas__drivers canvas__context canvas__label canvas__label--right canvas__label--all-caps"
          >
            {jargon.DRIVERS || 'DRIVERS'}
          </text>
          <text
            x="-50"
            y="-60"
            className="canvas__idea canvas__goals canvas__context canvas__label canvas__label--all-caps"
          >
            {jargon.GOALS || 'GOALS'}
          </text>

          <text
            x="81.5"
            y="-81.5"
            className="canvas__value canvas__value-element canvas__label--element canvas__label canvas__label--top canvas__elements"
          >
            <tspan dy="-4">VA</tspan>
            <tspan x="81.5" dy="4">
              LUE
            </tspan>
          </text>
          <text
            x="40"
            y="-25"
            className="canvas__value canvas__problem canvas__core canvas__label canvas__label--right"
          >
            {jargon.problem || 'problem'}
          </text>
          <text
            x="60"
            y="-50"
            className="canvas__value canvas__users canvas__context canvas__label canvas__label--all-caps"
          >
            {jargon.USERS || 'USERS'}
          </text>
          <text
            x="50"
            y="-60"
            className="canvas__value canvas__motivations canvas__context canvas__label canvas__label--right canvas__label--all-caps"
          >
            {jargon.MOTIVATIONS || 'MOTIVATIONS'}
          </text>

          <text
            x="-81.5"
            y="81.5"
            className="canvas__resources canvas__resources-element canvas__label--element canvas__label canvas__label--bottom canvas__label--right canvas__elements"
          >
            <tspan x="-81.5" dy="0">
              RES
            </tspan>
            <tspan x="-81.5" dy="4">
              OUR
            </tspan>
            <tspan x="-81.5" dy="4">
              CES
            </tspan>
          </text>
          <text x="-40" y="25" className="canvas__resources canvas__solution canvas__core canvas__label">
            {jargon.solution || 'solution'}
          </text>
          <text
            x="-60"
            y="50"
            className="canvas__resources canvas__enablers canvas__context canvas__label canvas__label--right canvas__label--all-caps"
          >
            {jargon.ENABLERS || 'ENABLERS'}
          </text>
          <text
            x="-50"
            y="60"
            className="canvas__resources canvas__production canvas__context canvas__label canvas__label--all-caps"
          >
            {jargon.PRODUCTION || 'PRODUCTION'}
          </text>

          <text
            x="81.5"
            y="81.5"
            className="canvas__market canvas__market-element canvas__label--element canvas__label canvas__label--bottom canvas__label--left canvas__elements"
          >
            <tspan dy="0">MAR</tspan>
            <tspan x="81.5" dy="4">
              KET
            </tspan>
          </text>
          <text
            x="40"
            y="25"
            className="canvas__market canvas__alternatives canvas__core canvas__label canvas__label--right"
          >
            {jargon.alternatives || 'alternatives'}
          </text>
          <text
            x="60"
            y="50"
            className="canvas__market canvas__customers canvas__context canvas__label canvas__label--all-caps"
          >
            {jargon.CUSTOMERS || 'CUSTOMERS'}
          </text>
          <text
            x="50"
            y="60"
            className="canvas__market canvas__distribution canvas__context canvas__label canvas__label--right canvas__label--all-caps"
          >
            {jargon.DISTRIBUTION || 'DISTRIBUTION'}
          </text>
        </g>
        {venn !== CANVAS_STYLE_OPTIONS.hidden && <Venn style={dotgrid} />}
      </g>
    </svg>
  );
};

export default React.memo(ProductFieldCanvas);
