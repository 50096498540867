/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export default class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  distance(point) {
    const distanceX = Math.abs(this.x - point.x);
    const distanceY = Math.abs(this.y - point.y);
    const distanceSquare = distanceX ** 2 + distanceY ** 2;
    return Math.sqrt(distanceSquare);
  }
}
