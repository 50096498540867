/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import Icon from '../Icon';

import './AdminActionsSection.scss';

/**
 * A highlighted section for displaying admin tools.
 * This component DOES NOT manage access, it is purely visual.
 *
 * @param {Object} props
 * @param {JSX.Element} props.children
 */
const AdminActionsSection = ({ children }) => {
  return (
    <div className="admin-actions-section">
      <div className="admin-actions-section__title">
        <Icon icon="powertools" size="alpha" /> Admin Tools
      </div>
      {children}
    </div>
  );
};

export default AdminActionsSection;
