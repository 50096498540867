/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState } from 'react';

import Cue from '../Cue';

import './CueList.scss';

const CueList = ({ cues, onAddCue, onUpdateCue, onDeleteCue, onAnonymizeCue, onDeanonymizeCue, newCueText }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const styleSheet = document.styleSheets[0];

  const keyframes = [
    `@keyframes k-0 {
      0% {
        transform: scale(0.60);
      }
      10% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(1);
      }
    }`,
  ];

  keyframes.push(`@keyframes k-1 {
    0% {
        transform: translateY(calc(-100% - var(--cue-spacer) - var(--create-cue-extra-height))) scale(1);
      }
      40% {
        transform: translateY(calc(-100% - var(--cue-spacer) - var(--create-cue-extra-height) - 8%)) scale(1.05);
      }
      100% {
        transform: translateY(0) scale(1);
        --cue-padding-bottom: 0;
      }
    }`);

  keyframes.push(`@keyframes k-2 {
      0% {
        transform: translateY(calc(-100% - var(--cue-spacer)));
      }
      100% {
        transform: translateY(0);
      }
    }`);

  cues.forEach((_c, i) => {
    if (i > 1) {
      keyframes.push(`@keyframes k-${i + 1} {
          0% {
            transform: translateY(calc(-100% - var(--cue-spacer)));
          }
          100% {
            transform: translateY(0);
          }
        }`);
    }
  });

  keyframes.forEach((k) => {
    styleSheet.insertRule(k, styleSheet.cssRules.length);
  });

  return (
    <div className="cue-list__container">
      <div className="cue-list">
        <div
          className="cue-list__cue"
          key={cues.length}
          style={
            shouldAnimate
              ? {
                  animationName: 'k-0',
                  animationTimingFunction: 'ease-out',
                  animationDuration: '0.5s',
                  animationDelay: '.7s',
                  animationIterationCount: 1,
                  animationDirection: 'normal',
                  animationFillMode: 'both',
                }
              : {}
          }
        >
          <Cue
            text={newCueText}
            create
            onAddCue={(v) => {
              setShouldAnimate(true);
              onAddCue(v);
            }}
            onUpdateCue={(c) => {
              onUpdateCue({ ...c, id: 'new' });
            }}
            key={0}
          />
        </div>

        {cues.map((c, i) => {
          const style = shouldAnimate
            ? {
                animationName: `k-${i + 1}`,
                animationTimingFunction: 'ease-in-out',
                animationDuration: '.8s',
                animationDelay: `${0.3 - i * 0.1}s`,
                animationIterationCount: 1,
                animationDirection: 'normal',
                animationFillMode: 'both',
              }
            : {};

          return (
            <div className="cue-list__cue" key={c.id} style={style}>
              <Cue
                {...c}
                onUpdateCue={onUpdateCue}
                onDeleteCue={(id) => {
                  setShouldAnimate(false);
                  onDeleteCue(id);
                }}
                onAnonymizeCue={onAnonymizeCue}
                onDeanonymizeCue={onDeanonymizeCue}
                key={c.id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CueList;
