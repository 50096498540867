/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import './InfoBlock.scss';
import { Icon } from '@fieldbyfield/components';

const InfoBlock = ({ children, title, icon, iconColor, action }) => {
  return (
    <div className="info-block">
      <div className="info-block__icon">
        <Icon icon={icon} color={iconColor} size="gamma" />
      </div>
      <div className="info-block__title">{title}</div>
      <div className="info-block__message">
        {children}
        {action && <div className="info-block__action">{action}</div>}
      </div>
    </div>
  );
};

export default InfoBlock;
