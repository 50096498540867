/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import amplitude from 'amplitude-js';

// Arrays of functions, an enabler functions receives a user object for
// initialization. By default the trackers are in noop mode, until enabled.
const enablers = [];
const disablers = [];

//
// Amplitude
//
// Provide a noop instance of the tracker, so we don't have to check if we're
// allowed to track in component code.
const noopAmplitudeInstance = {
  logEvent: () => {},
  setUserProperties: () => {},
};

// Default to noop until enabled.
let amplitudeInstance = noopAmplitudeInstance;

enablers.push((user) => {
  const tracker = amplitude.getInstance();

  tracker.init(process.env.AMPLITUDE_KEY, user ? user.id : null);
  tracker.setOptOut(false);

  amplitudeInstance = tracker;
});

disablers.push(() => {
  // Does not use tracker opt out feature, as the instance doesn't forward any
  // calls.
  amplitudeInstance = noopAmplitudeInstance;
});

//
// Export our tracking API object.
//
export default {
  // A central place for our consent policy.
  haveConsent: (user, dnt, bannerAccepted) => {
    if (dnt === '1') {
      return false;
    }
    if (bannerAccepted === true) {
      return true;
    }
    if (user && user.is_tracking_consent_given === true) {
      return true;
    }
    return false;
  },

  enable: (user) => {
    enablers.forEach((fn) => fn(user));
  },
  disable: () => {
    disablers.forEach((fn) => fn());
  },

  amplitude: () => {
    return amplitudeInstance;
  },
};
