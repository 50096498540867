/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { getElementAtPoint, ELEMENT_IDEA, ELEMENT_VALUE, ELEMENT_MARKET, ELEMENT_RESOURCES } from './element';

function normalize(values) {
  const ratio = Math.max(...values);
  return values.map((v) => v / ratio);
}

/**
 * an emitter has the fields {weight, x, y}
 */

export function computeCharacter(emitters) {
  const weights = emitters.reduce(
    (acc, { x, y, weight }) => {
      acc[getElementAtPoint([x, y])] += weight;
      return acc;
    },
    {
      [ELEMENT_IDEA]: 0,
      [ELEMENT_RESOURCES]: 0,
      [ELEMENT_MARKET]: 0,
      [ELEMENT_VALUE]: 0,
    }
  );
  const normalizedWeights = normalize(Object.values(weights));
  const pairs = [];
  Object.keys(weights).forEach((k, i) => {
    const normalizedWeight = isNaN(normalizedWeights[i]) ? 0 : normalizedWeights[i];
    pairs.push({ element: k, weight: normalizedWeight });
    weights[k] = normalizedWeight;
  });

  return { indication: weights, elements: pairs.sort((a, b) => b.weight - a.weight) };
}
