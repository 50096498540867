/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './SortableCheckboxList.scss';

const Checkmark = () => {
  return (
    <svg
      className="sortable-checkbox-list-button__checkmark"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 8.82794L5.33368 13L14 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

/**
 * A Sortable Checkbox List lets the user select multiple options from a list while also being able to control the order of the list.
 *
 * @param {Object} props
 * @param {[{ title: string, value: string, selected: boolean }]} props.options
 * @param {([{ title: string, value: string, selected: boolean }]) => void} props.onChange
 * @param {string} props.name
 * @param {string} props.labelId The entire CheckboxList should be labeld by a FormElement. Its id needs to be passed here.
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.invalid]

 */
const SortableCheckboxList = ({ options, disabled, invalid, onChange, labelId, name }) => {
  function changeEvent(ev) {
    onChange(
      options.map((o) => {
        if (String(o.value) === ev.target.value || (!o.value && o.title === ev.target.value)) {
          return { ...o, selected: !o.selected };
        }
        return { ...o };
      })
    );
  }

  return (
    <div
      className={classNames('sortable-checkbox-list', {
        'sortable-checkbox-list--disabled': disabled,
        'sortable-checkbox-list--invalid': invalid,
      })}
      role="group"
      aria-labelledby={labelId}
    >
      <DragDropContext
        onDragEnd={({ source: { index: prevIndex }, destination }) => {
          if (!destination) {
            return;
          }

          const nextOptions = [...options];

          // We take the item that changed its position out of the array
          // and insert it back in at its new position.
          const item = nextOptions.splice(prevIndex, 1);
          nextOptions.splice(destination.index, 0, item[0]);

          onChange(nextOptions);
        }}
      >
        <Droppable droppableId={`droppable-${name}`}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {options.map((o, i) => {
                const id = `${labelId}-option-${i}`;
                return (
                  <Draggable key={o.value || o.title} draggableId={`${o.value || o.title}-${name}`} index={i}>
                    {(providedForOptions) => (
                      <label
                        className={classNames('sortable-checkbox-list-button', {
                          'sortable-checkbox-list-button--disabled': !o.selected,
                        })}
                        key={o.value || o.title}
                        htmlFor={id}
                        ref={providedForOptions.innerRef}
                        {...providedForOptions.draggableProps}
                        {...providedForOptions.dragHandleProps}
                      >
                        <div className="sortable-checkbox-list-button__drag-handle" />
                        <input
                          id={id}
                          className="sortable-checkbox-list-button__input"
                          type="checkbox"
                          name={name}
                          value={o.value || o.title}
                          checked={o.selected || false}
                          onChange={changeEvent}
                          disabled={disabled}
                        />
                        <div className="sortable-checkbox-list-button__indicator">
                          <Checkmark />
                        </div>
                        {o.title}
                      </label>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SortableCheckboxList;
