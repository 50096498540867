/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useEffect, useCallback } from 'react';

import * as RadixSlider from '@radix-ui/react-slider';
import classNames from 'classnames';

import useDebounce from '../utils/useDebounce';

import './Slider.scss';

/**
 * A Slider lets the user pick a number from a predefined range.
 *
 * @param {Object} props
 * @param {number} props.value
 * @param {(number) => {}} props.onChange
 * @param {string} props.name
 * @param {number} [props.step = 1]
 * @param {number} [props.min = 0]
 * @param {number} [props.max = 10]
 * @param {{ left: ?string, right: ?string }} [props.labels]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.invalid]
 * @param {boolean} [props.light]
 */
const Slider = ({
  value: actualValue = 0,
  disabled,
  invalid,
  onChange,
  onImmediateChange,
  name,
  light,
  step = 1,
  min: actualMin = 0,
  max: actualMax = 10,
  labels = { left: null, right: null },
  debounce: delay = 0,
}) => {
  // Because the RadixSlider does not support negative mins we
  // have to do some tricks
  const min = 0;
  const max = actualMax - actualMin;
  const outsideValue = actualValue - actualMin;

  const debounce = useDebounce();
  const [value, setValue] = useState(outsideValue);

  useEffect(() => {
    setValue(outsideValue);
    // Only update the internal value to the external one, when onChange changed,
    // signaling a change in the handled. This means, that the correct use of useCallback
    // is required, when reusing a DebouncedTextInput component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  const debouncedOnChange = useCallback(
    (v) => {
      setValue(v);
      if (onImmediateChange) {
        onImmediateChange(v[0] + actualMin);
      }
      debounce(() => onChange(v[0] + actualMin), delay);
    },
    [debounce, onChange, onImmediateChange, delay, actualMin]
  );

  return (
    <div
      className={classNames('slider', {
        'slider--disabled': disabled,
        'slider--invalid': invalid,
        'slider--light': light,
        'slider--with-labels': labels.left || labels.right,
      })}
    >
      {labels.left && <div className="slider__label slider__label--left">{labels.left}</div>}
      <RadixSlider.Root
        name={name}
        value={[value]}
        step={step}
        disabled={disabled}
        min={min}
        max={max}
        onValueChange={(v) => {
          debouncedOnChange(v);
        }}
        className="slider__slider"
      >
        <RadixSlider.Track className="slider__track">
          <RadixSlider.Range className="slider__range" />
        </RadixSlider.Track>
        <RadixSlider.Thumb className="slider__thumb" />
      </RadixSlider.Root>
      {labels.right && <div className="slider__label">{labels.right}</div>}
    </div>
  );
};

export default Slider;
