/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

const MenuItemButton = ({ icon, iconColor, disabled, title, subtitle, onClick }) => {
  return (
    <button
      className={classNames('context-menu-button', { 'context-menu-button--disabled': disabled })}
      onClick={(ev) => {
        ev.stopPropagation();
        onClick();
      }}
      type="button"
      disabled={disabled}
    >
      <div className="context-menu-button__content">
        <div className="context-menu-button__icon">
          {icon && <Icon icon={icon} size="alpha" color={iconColor || 'highlight-light'} />}
        </div>
        <div className="context-menu-button__title">{title}</div>
        {subtitle && <div className="context-menu-button__subtitle">{subtitle}</div>}
      </div>
    </button>
  );
};

export default MenuItemButton;
