/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import { Paragraph } from '@fieldbyfield/components';

import './DefaultPage.scss';
import DefaultLayout from '../DefaultLayout';

const DefaultPage = ({ title, description, infoBlocks, headerAccessory, theme }) => {
  return (
    <DefaultLayout headerAccessory={headerAccessory} theme={theme}>
      <div className="default-page__intro">
        <h1 className="default-page__intro-title">{title}</h1>
        <div className="default-page__intro-text">
          <Paragraph size="gamma">{description}</Paragraph>
        </div>
      </div>

      <div className="default-page__info-background" />

      {infoBlocks && <div className="default-page__info">{infoBlocks}</div>}
    </DefaultLayout>
  );
};

export default DefaultPage;
