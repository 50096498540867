/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';
import './SelectionBar.scss';

const SelectionBar = ({ options, disabled, invalid, onChange, labelId, name, light, dark, small }) => {
  function changeEvent(ev) {
    onChange(
      options.map((o) => {
        if (o.value === ev.target.value || (!o.value && o.title === ev.target.value)) {
          return { ...o, selected: true };
        }
        return { ...o, selected: false };
      })
    );
  }

  return (
    <div
      className={classNames('selection-bar', {
        'selection-bar--disabled': disabled,
        'selection-bar--invalid': invalid,
        'selection-bar--light': light,
        'selection-bar--small': small,
        'selection-bar--dark': dark,
      })}
      role="radiogroup"
      aria-labelledby={labelId}
    >
      {options.map((o, i) => {
        const id = `${labelId}-option-${i}`;
        return (
          <label className="selection-bar-button" key={o.title} htmlFor={id}>
            <input
              id={id}
              className="selection-bar-button__input"
              type="radio"
              name={name}
              value={o.value || o.title}
              checked={o.selected || false}
              onChange={changeEvent}
              disabled={disabled}
            />
            <div className="selection-bar-button__label">{o.title}</div>
          </label>
        );
      })}
    </div>
  );
};

export default SelectionBar;
