/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import Button, { ButtonGroup } from '../Button';
import TextInput from '../TextInput';

import './ProtectedTextInput.scss';

const ProtectedTextInput = ({ value, onChange, title, placeholder, disableWhenEmpty, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [innerValue, setInnerValue] = useState(value);

  const textInputRef = useRef();

  useEffect(() => {
    if (isEditing && textInputRef.current) {
      const end = textInputRef.current.value.length;
      textInputRef.current.setSelectionRange(end, end);
      textInputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <div className="protected-text-input">
      {(isEditing && (
        <TextInput
          {...props}
          ref={textInputRef}
          placeholder={placeholder}
          debouce={0}
          value={innerValue}
          onChange={setInnerValue}
          lines="auto"
        />
      )) || (
        <div
          className={classNames('protected-text-input__value', !value && 'protected-text-input__value--placeholder')}
          onDoubleClick={() => {
            setIsEditing(true);
          }}
        >
          {value || placeholder}
        </div>
      )}
      <div className="protected-text-input__buttons">
        {!isEditing && (
          <Button
            type="ghost-slim"
            size="alpha"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit{title && ` ${title}`}
          </Button>
        )}
        {isEditing && (
          <ButtonGroup size="alpha">
            <Button
              type="primary"
              size="alpha"
              onClick={() => {
                setIsEditing(false);
                onChange(innerValue);
              }}
              disabled={disableWhenEmpty && !innerValue}
            >
              Save Changes
            </Button>
            <Button
              type="ghost"
              size="alpha"
              onClick={() => {
                setIsEditing(false);
                setInnerValue(value);
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        )}
      </div>
    </div>
  );
};

export default ProtectedTextInput;
