/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

import './Checklist.scss';

/**
 * A Checklist is a visual component to display a list of statements with a checkmark in front.
 *
 * @param {Object} props
 * @param {JSX.Element} props.statments
 * @param {"default" | "plum-compote" | "marketing" | {backgroundColor: string, borderColor: string, checkColor: string, shadow: boolean}} [props.theme = "default"]
 */
const Checklist = ({ statements, theme = 'default' }) => {
  const style = {};

  if (theme && typeof theme === 'object') {
    if (theme.backgroundColor) {
      style[`--background-color`] = theme.backgroundColor;
      style[`--border-color`] = theme.backgroundColor;
    }
    if (theme.borderColor) {
      style[`--border-color`] = theme.borderColor;
    }
    if (theme.checkColor) {
      style[`--check-color`] = theme.checkColor;
    }
  }

  return (
    <div
      className={classNames('checklist', {
        [`checklist--${theme}`]: theme,
        'checklist--no-shadow': theme && theme.shadow === false,
      })}
      style={style}
    >
      {statements.map((b, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="checklist__statement">
          <div className="checklist__statement-check">
            <div className="checklist__statement-check-icon">
              <Icon icon="check-thick" size="alpha" />
            </div>
          </div>
          <div>{b}</div>
        </div>
      ))}
    </div>
  );
};

export default Checklist;
