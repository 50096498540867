/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useLayoutEffect, useState } from 'react';

import * as RadixPopover from '@radix-ui/react-popover';
import classNames from 'classnames';

const Popover = ({ popoverContent, children, side, sideOffset, align, asChild, theme = 'default', noPadding }) => {
  const [floatingLayer, setFloatingLayer] = useState(document.querySelector('#floating'));

  useLayoutEffect(() => {
    setFloatingLayer(document.querySelector('#floating'));
  }, []);

  return (
    <RadixPopover.Root>
      <RadixPopover.Trigger className="popover__trigger" asChild={asChild}>
        {children}
      </RadixPopover.Trigger>
      <RadixPopover.Portal container={floatingLayer}>
        <RadixPopover.Content
          className={classNames(
            'visual-tooltip',
            `visual-tooltip--theme-${theme}`,
            noPadding && 'visual-tooltip--no-padding'
          )}
          side={side}
          align={align}
          sideOffset={sideOffset || 0}
          collisionPadding={12}
        >
          <RadixPopover.Arrow className="visual-tooltip__arrow" width={12} height={6} />
          {popoverContent}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

export default Popover;
