/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect } from 'react';

import classNames from 'classnames';

import { Icon, LegacyTooltip } from '@fieldbyfield/components';

import './TimeboxIndicator.scss';

function formatTime(time) {
  const minutes = Math.floor(time / 60);
  let seconds = time % 60;
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

const TimeboxIndicator = ({ time, setTimeRemaining, resetTime, isRunning, setIsRunning }) => {
  useEffect(() => {
    let timer = null;

    if (isRunning) {
      timer = setInterval(() => {
        setTimeRemaining((t) => {
          if (t > 0) {
            return t - 1;
          }
          clearInterval(timer);
          setIsRunning(false);
          return 0;
        });
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [time, isRunning, setIsRunning, setTimeRemaining]);

  return (
    <button
      className={classNames('timebox-indicator', {
        'timebox-indicator--is-paused': !isRunning,
        'timebox-indicator--running-out': time <= 10 && time > 0,
        'timebox-indicator--ran-out': time === 0,
      })}
      onClick={() => {
        if (time > 0) {
          setIsRunning(!isRunning);
        } else {
          resetTime();
          setIsRunning(true);
        }
      }}
      type="button"
    >
      <div className="timebox-indicator__content">
        <div className="timebox-indicator__icon-default">
          <Icon icon="stopwatch" size="alpha" color="gray-700" />
        </div>
        <div className="timebox-indicator__icon-pause">
          <Icon icon="pause" size="alpha" color="gray-700" />
        </div>
        <div className="timebox-indicator__icon-play">
          <Icon icon="play" size="alpha" color="gray-700" />
        </div>
        <div className="timebox-indicator__icon-reset">
          <Icon icon="reset-time" size="alpha" color="caution-dark" />
        </div>
        <div className="timebox-indicator__time">{formatTime(time)}</div>
      </div>

      <div className="timebox-indicator__tooltip">
        <LegacyTooltip captureFocus={false} tipPosition="top-right">
          <div className="timebox-indicator__tooltip-content">
            <strong>This timer is only for you!</strong> Nothing happens when it runs out – it’s just for you to give
            yourself a certain amount of time to focus on these questions. You can select the amount of time on the
            “Introduction” page.
          </div>
        </LegacyTooltip>
      </div>
    </button>
  );
};

export default TimeboxIndicator;
