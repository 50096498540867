/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon/Icon.jsx';

import MenuItemButton from './MenuItemButton.jsx';
import MenuItemSelect from './MenuItemSelect.jsx';
import { CONTEXT_MENU_ITEM_TYPES } from './types.js';

const Menu = ({ menu }) => {
  return (
    <div className="context-menu">
      {menu
        .filter(({ hidden = false }) => !hidden)
        .map((item, k) => {
          if (item.type === CONTEXT_MENU_ITEM_TYPES.button) {
            return (
              <div
                className={classNames('context-menu__item', { 'context-menu__item--disabled': item.disabled })}
                key={`button-${item.title}`}
              >
                <MenuItemButton {...item} />
              </div>
            );
          }
          if (item.type === CONTEXT_MENU_ITEM_TYPES.menu) {
            return (
              <div
                className={classNames('context-menu__item', { 'context-menu__item--disabled': item.disabled })}
                key={`menu-${item.title}`}
              >
                <div className="context-menu-submenu">
                  <div className="context-menu-submenu__icon">
                    {item.icon && <Icon icon={item.icon} size="alpha" color={item.iconColor || 'highlight-light'} />}
                  </div>
                  <div className="context-menu-submenu__title">{item.title}</div>
                  <div className="context-menu-submenu__arrow-right">
                    <Icon icon="arrow-right" size="tiny" color="gray-600" />
                  </div>

                  <div
                    className={classNames(
                      'context-menu-submenu__submenu',
                      item.wide && 'context-menu-submenu__submenu--wide'
                    )}
                  >
                    <Menu menu={item.children} />
                  </div>
                </div>
              </div>
            );
          }
          if (item.type === CONTEXT_MENU_ITEM_TYPES.select) {
            return (
              <div
                className={classNames('context-menu__item', { 'context-menu__item--disabled': item.disabled })}
                key={`select-${item.title}`}
              >
                <MenuItemSelect {...item} />
              </div>
            );
          }

          if (item.type === CONTEXT_MENU_ITEM_TYPES.separator) {
            // eslint-disable-next-line react/no-array-index-key
            return <div className="context-menu__item context-menu__item--separator" key={k} />;
          }

          return null;
        })}
    </div>
  );
};

export default Menu;
