/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useLayoutEffect, useState, useEffect } from 'react';

import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import './TextInput.scss';

const TextInput = (
  {
    disabled,
    placeholder,
    name,
    light,
    lines,
    minLines = 2,
    labelledBy,
    autoFocus,
    containerClassName,
    className,
    readOnly,
    onFocus,
    onBlur,

    // Value / Changes:
    value: externalValue,
    // A function that we call when the value changes
    // with signature onChange(value, isValid)
    // isValid is either null or the result of validate function
    onChange = Function.prototype,

    // Input Validation:

    // Null, false, or function that receives a value and must return a boolean,
    // true if the value passed the validation. The validation function must
    // handle empty (undefined, null, "") values itself, if it wants to skip
    // validation on them
    validate,

    // A function that is called whenever a validation happened, it is invoked
    // with the result of the validation.
    onValidate = Function.prototype,
    // Can be used to set the validation state outside of the component directly
    // without a function, you should probably be using props.validate instead.
    invalid,
  },
  ref
) => {
  const [value, setValue] = useState(externalValue);

  // 3-state, null indicates not validated. This way we don't do the initial
  // renders with the value being marked as invalid.
  const [isValueValid, setIsValueValid] = useState(null);

  useEffect(() => {
    if (validate) {
      setIsValueValid(validate(value));
    } else if (invalid !== undefined) {
      setIsValueValid(!invalid);
    }
  }, [value, setIsValueValid, invalid, validate]);

  useEffect(() => {
    onValidate(isValueValid);
  }, [isValueValid, onValidate]);

  useLayoutEffect(() => {
    setValue(externalValue);
    // Also reset value if onChange changes. Otherwise the TextInput might not reset
    // if the previous initial externalValue was the same, as the new one, even though
    // onChange is different now. This happened in combination with DebouncedTextInput
    // components, that were reused despite not using React.memo anywhere.
  }, [externalValue, onChange]);

  const handleChange = ({ target: { value: newValue } }) => {
    setValue(newValue);
    if (validate) {
      onChange(newValue, validate(newValue));
    } else {
      // return newValue and immediately validate
      onChange(newValue, null);
    }
  };

  let input;
  if (!lines || lines === 1) {
    input = (
      <input
        className={className || 'text-input__input'}
        type="text"
        value={value}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        name={name}
        id={name}
        aria-labelledby={labelledBy}
        readOnly={readOnly}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      />
    );
  } else {
    let TextAreaComponent = 'textarea';
    let props;
    if (lines === 'auto') {
      TextAreaComponent = TextareaAutosize;
      props = {
        minRows: minLines,
      };
    } else {
      props = { rows: lines };
    }
    input = (
      <TextAreaComponent
        className={className || 'text-input__input text-input__textarea'}
        type="text"
        value={value}
        onChange={handleChange}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        name={name}
        id={name}
        aria-labelledby={labelledBy}
        readOnly={readOnly}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        {...props}
      />
    );
  }

  return (
    <div
      className={
        containerClassName ||
        classNames('text-input', {
          'text-input--disabled': disabled,
          'text-input--invalid': isValueValid === false,
          'text-input--light': light,
        })
      }
    >
      {input}
    </div>
  );
};

export default React.forwardRef(TextInput);
