/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import Delaunay from 'faster-delaunay';
import inside from 'point-in-polygon';

import convexHull from './utils/convexHull';
import Point from './utils/point';

function makeCircle(a, b, c) {
  const center = getCircumcenter(a, b, c);
  const radius = [a, b, c].reduce((rmax, pt) => {
    const dx = pt[0] - center[0];
    const dy = pt[1] - center[1];
    const r = Math.sqrt(dx * dx + dy * dy);
    return r > rmax ? r : rmax;
  }, 0);
  return { x: center[0], y: center[1], radius };
}

function circumcircleRadiusSquared(a, b, c) {
  const [cx, cy] = getCircumcenter(a, b, c);
  const [vx, vy] = a;
  const dx = cx - vx;
  const dy = cy - vy;
  return dx * dx + dy * dy;
}

function getCircumcenter(a, b, c) {
  // determine midpoints (average of x & y coordinates)
  const midAB = [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
  const midBC = [(b[0] + c[0]) / 2, (b[1] + c[1]) / 2];

  // determine slope
  // we need the negative reciprocal of the slope to get
  // the slope of the perpendicular bisector
  const slopeAB = -1 / ((b[1] - a[1]) / (b[0] - a[0]));
  const slopeBC = -1 / ((c[1] - b[1]) / (c[0] - b[0]));

  // y = mx + b
  // solve for b
  const bAB = midAB[1] - slopeAB * midAB[0];
  const bBC = midBC[1] - slopeBC * midBC[0];

  // solve for x & y
  // x = (b1 - b2) / (m2 - m1)
  const x = (bAB - bBC) / (slopeBC - slopeAB);
  return [x, slopeAB * x + bAB];
}

export const getLargestEmptyCircles = (coords, n, minRadius) => {
  const convexhull = convexHull(coords);

  return new Delaunay(coords)
    .triangulate()
    .reduce((acc, v, i) => {
      // We want each triangle in an array,
      // not one long array with all vectors.
      if (i % 3 === 0) {
        acc.push([v]);
      } else {
        acc[acc.length - 1].push(v);
      }
      return acc;
    }, [])
    .filter((t) => {
      const c = getCircumcenter(t[0], t[1], t[2]);
      return inside(c, convexhull);
    })
    .map((t) => ({
      triangle: t,
      radiusSquared: circumcircleRadiusSquared(t[0], t[1], t[2]),
    }))
    .filter((c) => c.radiusSquared > minRadius * minRadius)
    .sort((a, b) => b.radiusSquared - a.radiusSquared)
    .slice(0, n)
    .map((c) => makeCircle(c.triangle[0], c.triangle[1], c.triangle[2]));
};

// This sorts out circles that overlap too much, but its
// less perfromant because we calculate the radius of all
// circles and compare the distance between loads of them
export const getLargestEmptyCirclesCleaned = (coords, n, minRadius, maxRadius) => {
  const convexhull = convexHull(coords);

  return new Delaunay(coords)
    .triangulate()
    .reduce((acc, v, i) => {
      // We want each triangle in an array,
      // not one long array with all vectors.
      if (i % 3 === 0) {
        acc.push([v]);
      } else {
        acc[acc.length - 1].push(v);
      }
      return acc;
    }, [])
    .filter((t) => {
      const c = getCircumcenter(t[0], t[1], t[2]);
      return inside(c, convexhull);
    })
    .map((t) => ({
      triangle: t,
      radiusSquared: circumcircleRadiusSquared(t[0], t[1], t[2]),
      ...makeCircle(t[0], t[1], t[2]),
    }))
    .filter((c) => c.radiusSquared > minRadius * minRadius && c.radiusSquared < maxRadius * maxRadius)
    .sort((a, b) => b.radiusSquared - a.radiusSquared)
    .reduce((acc, c) => {
      if (acc.some((cf) => new Point(c.x, c.y).distance(cf) < cf.radius)) {
        return acc;
      }

      acc.push(c);
      return acc;
    }, [])
    .slice(0, n);
};
