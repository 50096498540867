/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { ALL_USER_ROLES } from '@fieldbyfield/client';
import { ALL_ASPECTS, ALL_CUE_TYPES } from '@fieldbyfield/product-field';

// This completely whitelists the string value of a nested key with that name. Don't use lightely!
const KEY_EXCEPTIONS = ['type', 'meta.requestId', 'meta.requestStatus', 'payload.prefix'];

// All the listed values here are whitelabeled, as we know they don't contain PII
const VALUE_EXCEPTIONS = [...ALL_ASPECTS, ...ALL_CUE_TYPES, ...ALL_USER_ROLES];

const constructPath = (path, next) => (path === '' ? next : [path, next].join('.'));

// Deeply replaces all strings with '[FILTERED]' unless the key was excepted.
const scrub = (value, { path = '' } = {}) => {
  if (Array.isArray(value)) {
    return value.map((v) => (v ? scrub(v, { path: constructPath(path, '[]') }) : v));
  }
  if (typeof value === 'object' && value) {
    return Object.keys(value).reduce((memo, k) => {
      memo[k] = scrub(value[k], { path: constructPath(path, k) });
      return memo;
    }, {});
  }
  if (typeof value !== 'string') {
    return value;
  }
  if (KEY_EXCEPTIONS.includes(path) || (value !== '' && !isNaN(value)) || VALUE_EXCEPTIONS.includes(value)) {
    return value;
  }

  return `[scrubbed ${value.length} character/s]`;
};

export default scrub;
