/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { maybeDeduplicateRequest } from './dedupe';
import { ClientError } from './error';
import { RunningRequestsManager } from './requests';

// Manage in-flight requests, i.e. for de-duplication.
const running = new RunningRequestsManager();

// request builds and sends a request to a HTTP JSON REST API and parses the
// response. Does only send JSON, but can handle responses with JSON and other
// content types.
export async function request(base, token, secret, method, path, params = {}, data = {}, options = {}) {
  let requestHash;

  if (options.useRequestDeduplication) {
    const [h, result] = await maybeDeduplicateRequest(running, base, token, method, path, params, data);

    if (result !== null) {
      return result;
    }
    requestHash = h;
  }

  const fetchOptions = {
    method,
    headers: {
      Accept: 'application/json',
    },
  };

  // When a token is set, use it although we might not need it.
  const authoritzationParts = [];
  if (token !== null) {
    authoritzationParts.push(`Bearer ${token}`);
  }
  // When a token is set, use it although we might not need it.
  if (secret !== null) {
    authoritzationParts.push(secret);
  }

  if (authoritzationParts.length > 0) {
    fetchOptions.headers.Authorization = authoritzationParts.join(' ');
  }

  // We submit JSON in the body whenever possible as it's safe to assume that
  // the endpoints will be able to consume it.
  if (method !== 'HEAD' && method !== 'GET') {
    fetchOptions.headers['Content-Type'] = 'application/json';
    fetchOptions.body = JSON.stringify(data);
  }

  const query = buildQueryParams(params);

  if (options.useRequestDeduplication) {
    // Allow requests coming after this one to cancel this request.
    fetchOptions.signal = running.add(requestHash);
  }

  return fetch(`${base}/${path}${query.toString() !== '' ? `?${query}` : ''}`, fetchOptions)
    .then(async (res) => {
      if (options.useRequestDeduplication) {
        running.clean(requestHash);
      }

      let message = `Client API request failed :-S\n -> ${method} ${res.url}\n <- ${res.status} ${res.statusText}`;

      if (!res.ok) {
        const text = await res.text();
        const info = { http_status: res.status };

        // Don't even try to parse this.
        if (text === '' || text[0] !== '{') {
          message += `\n .. Error Detail  | n/a`;
          message += `\n .. Error ID      | n/a`;
        } else {
          try {
            data = JSON.parse(text);

            // Assume standard API error response.
            message += `\n .. Error Detail  | ${data.detail}`;
            message += `\n .. Error ID      | ${data.id}`;
            info.id = data.id;
          } catch (err) {
            message += `\n !! Failed to parse error response`;
            message += `\n .. While parsing | '${text}'`;
            message += `\n .. Parser Error  | ${err}`;
          }
        }

        // Even though we got a valid HTTP response (there was no network error),
        // we want to fail this promise, when the response status code indicates
        // user or server error. This makes it easier to handle failed requests.
        throw new ClientError(message, info);
      }
      const isJSONResponse = res.headers.get('Content-Type') === 'application/json';

      // Responses where we can assume an empty body.
      if (res.status === 204) {
        return isJSONResponse ? null : '';
      }

      // Automatically convert response to JSON, if indicated, leave others unparsed.
      if (!isJSONResponse) {
        return res.text();
      }
      try {
        return await res.json();
      } catch (err) {
        const text = await res.text();

        message += `\n !! Failed to parse response body`;
        message += `\n .. While parsing | '${text}'`;
        message += `\n .. Parser Error  | ${err}`;
        throw Error(message);
      }
    })
    .finally(() => {
      if (options.useRequestDeduplication) {
        running.clean(requestHash);
      }
    });
}

export function withBase(fn, base) {
  return (...args) => {
    return fn(base, ...args);
  };
}

export function withToken(fn, token) {
  return (...args) => {
    return fn(token, ...args);
  };
}

export function withSecret(fn, secret) {
  return (...args) => {
    return fn(secret, ...args);
  };
}

export function withAdditionalOptions(fn, options) {
  return (a, b, c, d, e, f) => {
    f = f || {};
    f = { ...f, ...options };
    return fn(a, b, c, d, e, f);
  };
}

// Builds an URLSearchParams object from given params.
//
// - Will filter out keys which value equals `undefined` or `null`,
//   as these are used to indicate that the APIs default should be
//   used instead, and thus don't need to be provided.
// - Will encode boolean values as `'true'` and `'false'`.
// - Given a params key foo with value `[1, 2, 3]`, will be formatted as
//   `?foo=1&foo=2&foo=3`.
function buildQueryParams(params) {
  const query = new URLSearchParams();

  Object.entries(params).forEach(([k, v]) => {
    if (v === undefined) {
      return;
    }
    if (Array.isArray(v)) {
      v.forEach((vv) => query.append(k, vv));
    } else {
      query.set(k, v);
    }
  });

  return query;
}
