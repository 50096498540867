/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

import './Banner.scss';

/**
 * A Banner informs the user about something that needs their immediate attention.
 *
 * @param {Object} props
 * @param {string} props.message
 * @param {string} [props.headline]
 * @param {string} [props.icon = "warning"]
 * @param {('default' | 'caution' | 'stop')} [props.type = "default"]
 */
const Banner = ({ headline, message, type = 'default', icon = 'warning' }) => {
  return (
    <div className={classNames('banner', `banner--${type}`, { 'banner--no-headline': !headline })}>
      <div className="banner__icon">
        <Icon size="gamma" icon={icon} />
      </div>
      <div className="banner__text">
        {headline && <div className="banner__headline">{headline}</div>}
        <div className="banner__message">{message}</div>
      </div>
    </div>
  );
};

export default Banner;
