/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

function radian2degrees(rad) {
  const degrees = 180 / Math.PI;
  return rad * degrees;
}

export default class Vector {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  add(vector) {
    const x = this.x + vector.x;
    const y = this.y + vector.y;
    return new Vector(x, y);
  }

  multiply(vector) {
    const x = this.x * vector.x;
    const y = this.y * vector.y;
    return new Vector(x, y);
  }

  length() {
    return Math.sqrt(this.x ** 2 + this.y ** 2);
  }

  normalize() {
    const length = this.length();
    if (length === 0) {
      return new Vector(1, 0);
    }
    return new Vector(this.x / length, this.y / length);
  }

  angle() {
    return Math.atan2(this.y, this.x);
  }

  angleDeg() {
    return radian2degrees(this.angle());
  }
}
