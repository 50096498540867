/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect } from 'react';

import { useAuth0, Auth0Provider } from '@auth0/auth0-react';

import { useAsyncError } from './crash';

export const AuthProvider = ({ domain, audience, clientId, children, onRedirectCallback }) => (
  <Auth0Provider
    domain={domain}
    audience={audience}
    clientId={clientId}
    redirectUri={window.location.origin}
    cacheLocation="localstorage"
    useRefreshTokens
    onRedirectCallback={onRedirectCallback}
  >
    {children}
  </Auth0Provider>
);

let tokenExpiryTimeout;

// useAuthentication wrap the Auth0's useAuth0 hook implenentation.
//
// It additionally automatically tries to login the user, on hook call. This
// however might not work and callers must check isLoading and isAuthenticated
// to determine if they must call loginWithRedirect().
//
// The hook will also try to install auto refreshing of the token.
export const useAuthentication = ({ onAuthToken = () => {} } = {}) => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading,
    loginWithPopup,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0();

  const throwError = useAsyncError();

  useEffect(() => {
    (async () => {
      // We want this code branch to run only once, so we can safely call `useAuthentication`
      // in other places without re-requesting/setting the token and re-scheduling the timeout.
      if (!isAuthenticated || tokenExpiryTimeout) {
        return;
      }

      // Automatically try to login the user, if that fails the caller must call loginWithRedirect().
      const getAuthToken = async () => {
        // As long as the token is not expired we retrieve it from local
        // storage. Otherwise it's refresh it with auth0 silently.
        // For the error handling, see: https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4
        let token;
        try {
          token = await getAccessTokenSilently();
        } catch (e) {
          // For error "codes", see https://openid.net/specs/openid-connect-core-1_0.html#AuthError
          if (e.error === 'login_required' || e.error === 'consent_required') {
            console.debug(
              'Did not automatically login the user (this is not an error, caller must call loginWithRedirect()): ',
              e
            );
            return;
          }
          throwError(e);
        }
        onAuthToken(token);

        // Try to install an optional routine to auto refresh the token.
        let exp;
        try {
          exp = JSON.parse(atob(token.split('.')[1])).exp;
        } catch (e) {
          console.log(
            'Cannot parse expiration time from token, auto refresh disabled. This must not be an error as the "exp" claim is optional.',
            e
          );
          return Promise.resolve();
        }
        if (exp) {
          // In case this is rerun, before the old token expired.
          if (tokenExpiryTimeout) {
            clearTimeout(tokenExpiryTimeout);
          }

          // Refresh access 1 second, after it expires. The token will only
          // be refreshed with auth0 if it has expired.
          tokenExpiryTimeout = setTimeout(getAuthToken, Math.max((exp + 1) * 1000 - Date.now(), 0));
        }
        return Promise.resolve();
      };

      await getAuthToken();
    })();
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims, onAuthToken, throwError]);

  return {
    logout: () => {
      clearInterval(tokenExpiryTimeout);
      // We need to provide a value for the returnTo option as
      // otherwise the behavior seems undefined. When the current URL
      // is https://explore.fieldbyfield.xyz/account, returnTo will be
      // https://explore.fieldbyfield.xyz. The URL provided here must be
      // whitelisted in the Auth0 configuration.
      logout({ returnTo: window.location.origin });
    },
    isAuthenticated,
    isLoading,
    loginWithPopup,
    loginWithRedirect,
    user,
  };
};
