/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import Button from '../Button';
import Checkbox from '../Checkbox';

import './ContractForm.scss';

/**
 * A Contract Form presents the user with a contract that they have to agree to before being able to continue.
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {JSX.Element} props.content
 * @param {() => {}} props.onConfirm
 */
const ContractForm = ({ onConfirm, content, title }) => {
  const contentContainer = useRef();
  const [isChecked, setIsChecked] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    setIsChecked(false);
    setHasScrolled(false);
    contentContainer.current.scroll(0, 0);
  }, [onConfirm, content, title]);

  return (
    <div className="contract-form-wrapper">
      <div className={classNames('contract-form', { 'contact-form--scrolled': hasScrolled })}>
        <div className="contract-form__intro">
          You need to accept our <strong>{title}</strong> before continuing.
        </div>
        <div
          ref={contentContainer}
          className="contract-form__text"
          onScroll={() => {
            setHasScrolled(true);
          }}
        >
          {content}
        </div>
        <div className="contract-form__footer">
          <Checkbox selected={isChecked} onChange={setIsChecked} name="checkbox" light>
            I have read and agree to the {title}.
          </Checkbox>
          <Button type="primary" onClick={onConfirm} disabled={!isChecked} theme="light">
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContractForm;
