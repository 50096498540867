/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

const SVGFrame = ({ className, width, height, children }) => (
  <svg className={className} height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
    {children}
  </svg>
);

export default SVGFrame;
