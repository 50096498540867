/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './Link.scss';

const Link = ({ href, onClick, children, className, disabled, ...props }) => {
  if (href) {
    return (
      <a href={href} className={classNames('link', className, { 'link--disabled': disabled })} {...props}>
        {children}
      </a>
    );
  }

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={classNames('link', 'link--button', className, { 'link--disabled': disabled })}
        {...props}
        type="button"
      >
        {children}
      </button>
    );
  }
};

export const asLink =
  (BaseComponent) =>
  ({ className, disabled, ...props }) =>
    <BaseComponent {...props} className={classNames('link', className, { 'link--disabled': disabled })} />;

export const LinkButton = ({ onClick, children, className, disabled, ...props }) => {
  return (
    <button
      onClick={onClick}
      className={classNames('link', 'link--button', className, { 'link--disabled': disabled })}
      {...props}
      type="button"
    >
      {children}
    </button>
  );
};

export const LinkGroup = ({ children }) => {
  return (
    <div className="link-group">
      {children.map((child, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i}>
            {i > 0 && ', '}
            {child}
          </span>
        );
      })}
    </div>
  );
};

export default Link;
