/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

/* eslint-disable import/prefer-default-export */

export class InviteAPI {
  constructor({ request }) {
    this.request = request;
  }

  validateInvitation({ token }) {
    return this.request('POST', `invites/${token}/validate`);
  }

  redeemInvitation({ token }) {
    return this.request('POST', `invites/${token}/redeem`);
  }
}
