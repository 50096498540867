/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

/* eslint-disable import/prefer-default-export */

export class SurveyAPI {
  constructor({ request }) {
    this.request = request;
  }

  // Retrieves given surveys.
  getSurveys({ workspace, surveys }) {
    return this.request(
      'GET',
      `workspaces/${workspace.id}/surveys`,
      {
        id: surveys.map((v) => v.id).sort((a, b) => a - b),
      },
      {}
    );
  }

  // Creates a new survey with the given questions. Each question must reference
  // a cue that exists in the same workspace as the survey. The questions will
  // be ordered according to their order inside the array.
  //
  // Example:
  //   client.survey.addSurvey({
  //     workspace, // i.e. { id: 1 }
  //     {
  //       title: 'A Lotta Questions'
  //     },
  //     [
  //       { cue: { id: 2 } },
  //       { cue: { id: 3 }, is_active: true },
  //     ]
  //   })
  addSurvey({ workspace, survey, questions }) {
    return this.request('PUT', `workspaces/${workspace.id}/surveys`, {}, { ...survey, questions });
  }

  getSurvey({ workspace, survey }) {
    return this.request('GET', `workspaces/${workspace.id}/surveys/${survey.id}`);
  }

  setSurvey({ workspace, survey }) {
    return this.request('POST', `workspaces/${workspace.id}/surveys/${survey.id}`, {}, survey);
  }

  deleteSurvey({ workspace, survey }) {
    return this.request('DELETE', `workspaces/${workspace.id}/surveys/${survey.id}`);
  }

  inviteParticipant({ workspace, survey, user }) {
    return this.request('PUT', `workspaces/${workspace.id}/surveys/${survey.id}/participants`, {}, user);
  }

  removeParticipant({ workspace, survey, subject }) {
    return this.request('DELETE', `workspaces/${workspace.id}/surveys/${survey.id}/participants/${subject.id}`);
  }

  addQuestionInspiration({ workspace, survey, question, inspiration }) {
    return this.request(
      'PUT',
      `workspaces/${workspace.id}/surveys/${survey.id}/questions/${question.id}/inspirations`,
      {},
      inspiration
    );
  }

  getQuestions({ workspace, survey }) {
    return this.request('GET', `workspaces/${workspace.id}/surveys/${survey.id}/questions`);
  }

  addAnswer({ workspace, survey, question, answer }) {
    return this.request(
      'PUT',
      `workspaces/${workspace.id}/surveys/${survey.id}/questions/${question.id}/answers`,
      {},
      answer
    );
  }

  setQuestion({ workspace, survey, question }) {
    return this.request(
      'POST',
      `workspaces/${workspace.id}/surveys/${survey.id}/questions/${question.id}`,
      {},
      question
    );
  }

  // Takes a collection of questions in the desired order.
  //
  // Example:
  //   client.survey.setQuestionPositions({
  //     workspace, // i.e. { id: 1 }
  //     survey, // i.e. { id: 2 }
  //     [
  //       { id: 2 },
  //       { id: 3 },
  //     ]
  //   })
  setQuestionPositions({ workspace, survey, questions }) {
    return this.request('POST', `workspaces/${workspace.id}/surveys/${survey.id}/questions/positions`, {}, questions);
  }
}
