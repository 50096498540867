/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { tracking } from '@fieldbyfield/business';
import { Paragraph, Button, Icon, LegacyTooltip, Dropdown, KeyVisual, Radar } from '@fieldbyfield/components';
import { useHistory, useParams } from '@fieldbyfield/router';

import DefaultLayout from '../DefaultLayout';
import InfoBlock from '../InfoBlock';
import MetaDataTable from '../MetaDataTable';
import { setTimeboxIsActive, setTotalTimeboxTime, setTimeboxIsRunning } from '../redux';
import './Introduction.scss';

const UserInfo = ({ email }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <span className="introduction-user-info" tabIndex={0}>
      additional information{' '}
      <span className="introduction-user-info__icon">
        <Icon icon="info" size="alpha" color="gray-600" />
      </span>
      <span className="introduction-user-info__tooltip">
        <LegacyTooltip tipPosition="left-top" captureFocus={false}>
          <Paragraph size="alpha">This data about you will be saved along the note:</Paragraph>
          <div className="introduction-user-info__table">
            <MetaDataTable
              data={[
                {
                  icon: 'user',
                  text: email,
                },
                // {
                //   icon: 'location-pin',
                //   text: 'Hamburg, Germany',
                // },
                {
                  icon: 'clock',
                  text: new Date().toLocaleString(),
                },
              ]}
            />
          </div>
        </LegacyTooltip>
      </span>
    </span>
  );
};

const UserInfoMobile = ({ email }) => {
  return (
    <div className="introduction-user-info-mobile">
      <Paragraph size="alpha">This data about you will be saved along the note:</Paragraph>
      <div className="introduction-user-info__table">
        <MetaDataTable
          data={[
            {
              icon: 'user',
              text: email,
            },
            // {
            //   icon: 'location-pin',
            //   text: 'Hamburg, Germany',
            // },
            {
              icon: 'clock',
              text: new Date().toLocaleString(),
            },
          ]}
        />
      </div>
    </div>
  );
};

const timeboxOptions = [
  { title: 'No Timebox', value: 'none' },
  { title: '15 minutes', value: 900 },
  { title: '30 minutes', value: 1800 },
  { title: '45 minutes', value: 2700 },
];

const Introduction = () => {
  const history = useHistory();

  const { workspaceId, surveyId } = useParams();

  const firstQuestionId = useSelector((state) => {
    const [first] = [...state.questions].sort((a, b) => a.position - b.position);
    return first ? first.id : null;
  });

  const productName = useSelector((state) => state.product.name);
  const productInfo = useSelector((state) => state.product.info);
  const subject = useSelector((state) => state.introduction.subject);
  const description = useSelector((state) => state.introduction.description);
  const moderator = useSelector((state) => state.introduction.moderator);
  const questionCount = useSelector((state) => state.questions.length);
  const email = useSelector((state) => state.user.email);

  const timeboxIsActive = useSelector((state) => state.user.timeboxIsActive);
  const timeboxTotal = useSelector((state) => state.user.timeboxTotal);

  const timebox = timeboxOptions.map((o) => ({
    ...o,
    selected: o.value === 'none' ? !timeboxIsActive : timeboxTotal === o.value,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const necessaryTime = questionCount * 120;
    let minDistance = Number.MAX_VALUE;
    let value;

    timeboxOptions.forEach((o) => {
      if (o.value === 'none') {
        return;
      }
      const distance = Math.abs(o.value - necessaryTime);
      if (distance < minDistance) {
        minDistance = distance;
        value = o.value;
      }
    });

    dispatch(setTimeboxIsActive(true));
    dispatch(setTotalTimeboxTime(value));
    dispatch(setTimeboxIsRunning(true));
  }, [dispatch, questionCount]);

  return (
    <DefaultLayout headerAccessory={moderator && `Invited by ${moderator.name || moderator.email}`}>
      <div className="introduction__intro">
        <h1 className="introduction__intro-title">
          {subject || `Help to describe “${productName}”, it only takes a few minutes.`}
        </h1>
        <div className="introduction__intro-text">
          <Paragraph size="gamma">
            {description ||
              'Your colleagues will answer the questions independently. This will lead to a diverse collection of contributions from different points of view. This collection is the starting point for a collective, shared and meaningful description of our product. Please write down at least three thoughts for every question on separate cards.'}
          </Paragraph>
        </div>
      </div>

      {productName && productInfo && (
        <div className="introduction__product-info">
          <div className="introduction__product-info-title">This is what we are talking about</div>
          <Paragraph>
            <strong className="introduction__product-info-name">{productName}</strong> — {productInfo}
          </Paragraph>
        </div>
      )}
      <div className="introduction__get-started-background">
        <KeyVisual fill className="introduction__get-started-key-visual" />
      </div>

      <div className="introduction__get-started">
        <div className="introduction__get-started-text">Ready? Let’s go:</div>
        <div className="introduction__get-started-line" />
        <div className="introduction__get-started-button">
          <Button
            type="primary"
            onClick={() => {
              tracking.amplitude().logEvent('collect: start survey');
              history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/questions/${firstQuestionId}`);
            }}
          >
            To the First Question
          </Button>

          <div className="introduction__get-started-radar">
            <Radar theme="highlight" />
          </div>
        </div>
      </div>

      <div className="introduction__info-background" />

      <div className="introduction__info">
        <div className="introduction__timebox">
          <InfoBlock
            title="Timebox"
            icon="stopwatch"
            iconColor="blueberry-pancakes"
            action={
              <Dropdown
                options={timebox}
                onChange={(v) => {
                  const selected = v.find((i) => i.selected);
                  if (selected.value === 'none') {
                    dispatch(setTimeboxIsActive(false));
                    dispatch(setTimeboxIsRunning(false));
                    dispatch(setTotalTimeboxTime(null));
                    return;
                  }

                  dispatch(setTimeboxIsActive(true));
                  dispatch(setTotalTimeboxTime(parseInt(selected.value)));
                  dispatch(setTimeboxIsRunning(true));
                }}
              />
            }
          >
            Optionally, you can set a timer for a certain duration that you want to give yourself for answering the
            questions. There is a total of {questionCount} questions to be answered. The timer is only for yourself –
            once it runs out, you can reset it or just keep going.
          </InfoBlock>
        </div>

        <InfoBlock title="Your Notes" icon="user" iconColor="peach-crumble">
          <Paragraph>Before submitting you can read, edit and sort all your notes in an overview.</Paragraph>
          <Paragraph>
            Your contributions are especially valuable when it is known that you are the author. That’s why we save{' '}
            <UserInfo email={email} /> about you along the note.
          </Paragraph>
          {/* <Paragraph>
              At the overview you may choose to anonymize your contributions individually.
            </Paragraph> */}

          <UserInfoMobile email={email} />
        </InfoBlock>

        <InfoBlock title="Privacy" icon="lock" iconColor="raspberry-sorbet">
          <Paragraph>
            Before you have reviewed and submitted your notes no information will be transmitted to Field.
            {/* Further explanation can be found in the privacy policies. */}
          </Paragraph>
        </InfoBlock>
      </div>
    </DefaultLayout>
  );
};

export default Introduction;
