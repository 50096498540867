/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import {
  ALL_ASPECTS,
  ASPECT_PRODUCT,
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
  ASPECT_PROBLEM,
  ASPECT_SOLUTION,
  ASPECT_UNIQUENESS,
  ASPECT_ALTERNATIVES,
} from './aspect.js';
import { SET_CORE, SET_CONTEXT } from './set.js';

export const LENS_ALL = ALL_ASPECTS;
export const LENS_CORE = SET_CORE;
export const LENS_CONTEXT = SET_CONTEXT;
export const LENS_ELEVATOR_PITCH = [
  ASPECT_USERS,
  ASPECT_MOTIVATIONS,
  ASPECT_PROBLEM,
  ASPECT_PRODUCT,
  ASPECT_SOLUTION,
  ASPECT_ALTERNATIVES,
  ASPECT_UNIQUENESS,
];
export const LENS_DESIGN_SPRINT = [
  ASPECT_USERS,
  ASPECT_MOTIVATIONS,
  ASPECT_PROBLEM,
  ASPECT_UNIQUENESS,
  ASPECT_SOLUTION,
  ASPECT_PRODUCTION,
];

export const LENS_VIABILITY = [ASPECT_GOALS, ASPECT_DRIVERS, ASPECT_UNIQUENESS];
export const LENS_FEASIBILITY = [ASPECT_ENABLERS, ASPECT_PRODUCTION, ASPECT_SOLUTION];
export const LENS_MARKETABILITY = [ASPECT_DISTRIBUTION, ASPECT_CUSTOMERS, ASPECT_ALTERNATIVES];
export const LENS_DESIRABILITY = [ASPECT_PROBLEM, ASPECT_MOTIVATIONS, ASPECT_USERS];

export const LENS_PROBLEM_SOLUTION = [ASPECT_PROBLEM, ASPECT_PRODUCT, ASPECT_SOLUTION];
export const LENS_UNIQUNESS_ALTERNATIVES = [ASPECT_UNIQUENESS, ASPECT_PRODUCT, ASPECT_ALTERNATIVES];

// prettier-ignore
export const ALL_LENSES = [
  LENS_ALL,
  LENS_CORE,
  LENS_CONTEXT,
  LENS_ELEVATOR_PITCH,
  LENS_DESIGN_SPRINT,
  LENS_PROBLEM_SOLUTION,
  LENS_UNIQUNESS_ALTERNATIVES,
  LENS_VIABILITY,
  LENS_FEASIBILITY,
  LENS_MARKETABILITY,
  LENS_DESIRABILITY
];
