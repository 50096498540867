/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

/**
 * An Entity Link Button Group aligns multiple Entity Link Buttons.
 *
 * @param {Object} props
 * @param {JSX.Element} props.children
 */
const EntityLinkButtonGroup = ({ children }) => <div className="entity-link-button-group">{children}</div>;

export default EntityLinkButtonGroup;
