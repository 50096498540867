/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './LoadingSpinner.scss';

const LoadingSpinner = ({ theme, size = 'big' }) => {
  return (
    <div
      className={classNames('loading-spinner', `loading-spinner--size-${size}`, {
        [`loading-spinner--theme-${theme}`]: !!theme,
      })}
    />
  );
};

export default LoadingSpinner;
