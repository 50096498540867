/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import { Floating, Overlay, Alert, Button, Icon } from '@fieldbyfield/components';

import MetaDataTable from '../MetaDataTable';
import TooltipContainer from '../TooltipContainer';

import './Cue.scss';
import CueCharacterCounter from './CueCharacterCounter';

let eventListener;

const MAX_LENGTH_OF_CUE = 50;

const Cue = (
  { text, id, user, date, small, create, anonymized, onAddCue, onUpdateCue, onDeleteCue, onDeanonymizeCue },
  forwardRef
) => {
  const textAreaRef = useRef();
  const [showDeletionAlert, setShowDeletionAlert] = useState(false);
  const [contextMenuIsVisible, setContextMenuIsVisible] = useState(false);

  const handleAdd = (value) => {
    if (!value || value === '') {
      return;
    }
    onAddCue(value);
    onUpdateCue({ text: '' });

    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  const handleChange = (ev) => {
    onUpdateCue({ id, text: ev.target.value });
  };

  const handleKey = (ev) => {
    if (ev.key === 'Enter' && !ev.metaKey) {
      if (onAddCue) {
        ev.stopPropagation();
        ev.preventDefault();
        handleAdd(ev.target.value);
      }
    }
  };

  const handleFocus = () => {
    eventListener = handleKey;
    document.addEventListener('keydown', eventListener);
  };

  const handleBlur = () => {
    document.removeEventListener('keydown', eventListener);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('keydown', eventListener);
    };
  }, []);

  let metadata = [
    {
      icon: 'user',
      text: user,
    },
    // {
    //   icon: 'location-pin',
    //   text: 'Hamburg, Germany',
    // },
    {
      icon: 'clock',
      text: new Date(date).toLocaleString(),
    },
  ];

  let actions = [
    // {
    //   title: 'Anonymize',
    //   onClick: () => {
    //     onAnonymizeCue(id);
    //   },
    // },
    {
      title: 'Delete',
      onClick: () => {
        setShowDeletionAlert(true);
      },
    },
  ];

  if (anonymized) {
    metadata = [
      {
        icon: 'clock',
        text: new Date(date).toLocaleDateString(),
      },
    ];

    actions = [
      {
        title: 'De-anonymize',
        onClick: () => {
          onDeanonymizeCue(id);
        },
      },
      {
        title: 'Delete',
        onClick: () => {
          setShowDeletionAlert(true);
        },
      },
    ];
  }

  const showTooltip = (text.match(/[\/,;]/g) || []).length > 2;

  return (
    <div
      className={classNames('cue', {
        'cue--small': small,
        'cue--create': create,
        'cue--anonymized': anonymized,
      })}
      key={id}
      ref={forwardRef}
    >
      {showDeletionAlert && (
        <Floating>
          <Overlay
            animated
            onClick={() => {
              setShowDeletionAlert(false);
            }}
          >
            <Alert
              headline="Delete Note"
              message="This note will be permanently deleted."
              confirmationLabel="Delete Note"
              icon="trash"
              onCancel={() => {
                setShowDeletionAlert(false);
              }}
              onConfirm={() => {
                onDeleteCue(id);
              }}
            />
          </Overlay>
        </Floating>
      )}

      <div className="cue__body">
        <textarea
          className="cue__input"
          value={text}
          ref={textAreaRef}
          onChange={handleChange}
          placeholder={create ? 'Start writing down your thought …' : 'Your Thought …'}
          rows={2}
          onFocus={handleFocus}
          onBlur={handleBlur}
          maxLength={MAX_LENGTH_OF_CUE * 1.75}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={create}
        />
      </div>
      <div className="cue__footer-create">
        <div className="cue__line" />
        <CueCharacterCounter
          maxCharacters={MAX_LENGTH_OF_CUE}
          currentCharacters={text.length}
          showTooltip={showTooltip}
        />
        <div>
          <Button
            onClick={() => {
              handleAdd(text);
            }}
            type="primary"
            disabled={text === ''}
          >
            Note
          </Button>
        </div>
      </div>
      <div className="cue__footer">
        {anonymized && (
          <div className="cue__anonymized-icon">
            <Icon icon="eye-strikethrough" color="gray-800" size="alpha" />{' '}
          </div>
        )}
        <div className="cue__meta">
          {anonymized ? new Date(date).toLocaleDateString() : new Date(date).toLocaleString()}
        </div>
        <div className="cue__options">
          <button
            className={classNames('cue__options-button', { 'cue__options-button--active': contextMenuIsVisible })}
            onClick={() => {
              setContextMenuIsVisible(true);
            }}
            type="button"
            aria-label="options"
          >
            <div className="cue__options-icon">
              <Icon icon="options" size={small ? 'alpha' : 'beta'} />
            </div>
          </button>
          <TooltipContainer
            tipPosition="right-bottom"
            visible={contextMenuIsVisible}
            actions={actions}
            onClickOutside={() => {
              setContextMenuIsVisible(false);
            }}
          >
            <MetaDataTable data={metadata} />
          </TooltipContainer>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(Cue);
