/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect, useState } from 'react';

import { LoadingView } from '@fieldbyfield/components';

import { useAuthentication } from '../../authentication.jsx';
import { useClient } from '../../client.js';
import { useAsyncError } from '../../crash';

import AcceptTerms from './AcceptTerms.jsx';

const RequireAuthentication = ({
  children,
  render,
  // Wait before rendering the children function. Hook for async functions
  // setting data: e.g. redux reducers. Otherwise, we render without the data
  // being present in the redux store, which eventually results in an error.
  shouldRenderChildren = true,
  onUser = () => {},
  onAuthToken = () => {},
  redirectURL = null,
}) => {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuthentication({
    onAuthToken,
  });
  const throwError = useAsyncError();

  const { createClient } = useClient();

  const [hasFetchedUser, setHasFetchedUser] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const isLoading = isAuthLoading || isUserLoading;

  const handleAcceptTerms = () => {
    setUser({ ...user, are_terms_accepted: true });

    createClient()
      .then((client) => {
        return client.basic.setUser({
          user: {
            id: user.id,
            are_terms_accepted: true,
          },
        });
      })
      .catch(throwError);
  };

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { target: redirectURL } });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, redirectURL]);

  useEffect(() => {
    if (isAuthenticated && !hasFetchedUser) {
      setIsUserLoading(true);
      setHasFetchedUser(true);

      (async () => {
        try {
          const client = await createClient();
          const data = await client.basic.me();

          setUser(data);

          setIsLoggedIn(true);
          setIsUserLoading(false);
        } catch (e) {
          throwError(e);
        }
      })();
    }
  }, [isAuthenticated, hasFetchedUser, setIsUserLoading, onUser, createClient, throwError]);

  useEffect(() => {
    if (isLoggedIn) {
      onUser(user);
    }
  }, [isLoggedIn, user, onUser]);

  if (!isAuthenticated || isLoading || !isLoggedIn) {
    return <LoadingView>Authenticating …</LoadingView>;
  }

  if (!user.are_terms_accepted) {
    return <AcceptTerms onAcceptTerms={handleAcceptTerms} />;
  }

  if (!shouldRenderChildren) {
    return <LoadingView>Loading …</LoadingView>;
  }

  if (render) {
    return render();
  }

  return children;
};

export default RequireAuthentication;
