/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { useParams as useReactRouterDomParams } from 'react-router-dom';

export const useParams = (typeMap) => {
  const params = useReactRouterDomParams();

  return typeConvertParams(params, typeMap);
};

export const typeConvertParams = (params, typeMap) => {
  if (!typeMap) {
    return params;
  }

  // We create a new object from the params that are passed in
  // because manipulating them directly can lead to rerenders
  return Object.keys(typeMap).reduce(
    (m, k) => {
      m[k] = typeMap[k](params[k]);
      return m;
    },
    { ...params }
  );
};

export const NullableNumber = (value) => {
  if (value === null || isNaN(value)) {
    return null;
  }

  return Number(value);
};

export const NullableString = (value) => {
  if (!value) {
    return null;
  }

  return String(value);
};
