/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import Button from '../Button';

import './ButtonWithFeedback.scss';

const STATE_DEFAULT = 'default';
const STATE_LOADING = 'loading';
const STATE_SUCCESS = 'success';
const STATE_ERROR = 'error';

const ButtonWithFeedback = ({
  onClick,
  confirmationMessage,
  loadingMessage,
  errorMessage,
  children,
  theme,
  ...props
}) => {
  const [state, setState] = useState(STATE_DEFAULT);

  useEffect(() => {
    if (state === STATE_SUCCESS) {
      const timeout = setTimeout(() => {
        setState(STATE_DEFAULT);
      }, 5000);

      return () => clearTimeout(timeout);
    }

    if (state === STATE_ERROR) {
      const timeout = setTimeout(() => {
        setState(STATE_DEFAULT);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [state]);

  let buttonTheme = theme;

  if (state === STATE_SUCCESS) {
    buttonTheme = {
      backgroundColor: '#95EBA7',
      color: '#246131',
      borderColor: 'transparent',
      iconColor: '#246131',
    };
  }
  if (state === STATE_ERROR) {
    buttonTheme = { backgroundColor: '#FDE9E6', color: '#D33B30', borderColor: 'transparent' };
  }
  if (state === STATE_LOADING) {
    buttonTheme = {
      backgroundColor: 'var(--gray-150)',
      color: 'var(--gray-600)',
      borderColor: 'transparent',
      iconColor: 'var(--gray-500)',
    };
  }

  let icon = props.icon;

  if (state === STATE_SUCCESS && icon) {
    icon = 'check';
  }

  return (
    <div
      className={classNames('button-with-feedback', state !== STATE_DEFAULT && 'button-with-feedback--showing-message')}
    >
      <Button
        {...props}
        onClick={() => {
          if (state !== STATE_DEFAULT) {
            return;
          }

          setState(STATE_LOADING);
          onClick()
            .then(() => {
              setState(STATE_SUCCESS);
            })
            .catch((e) => {
              setState(STATE_ERROR);
              console.log('error', e);
            });
        }}
        theme={buttonTheme}
        icon={icon}
      >
        {state === STATE_SUCCESS && confirmationMessage}
        {state === STATE_LOADING && (loadingMessage || 'Loading …')}
        {state === STATE_ERROR && (errorMessage || 'Error')}
        {state === STATE_DEFAULT && children}
      </Button>
    </div>
  );
};

export default ButtonWithFeedback;
