/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useRef, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { Icon, LegacyTooltip, Paragraph } from '@fieldbyfield/components';

import MetaDataTable from '../MetaDataTable/MetaDataTable';
import { setRemainingTimeboxTime, setTimeboxIsRunning } from '../redux';
import TimeboxIndicator from '../TimeboxIndicator';

import './Header.scss';

const ProductInfo = () => {
  const [mouseover, setMouseover] = useState(false);
  const ref = useRef();

  const productName = useSelector((state) => state.product.name);
  const productInfo = useSelector((state) => state.product.info);

  const mouseoverHandler = () => {
    setMouseover(true);
  };
  const mouseoutHandler = () => {
    setMouseover(false);
  };

  useEffect(() => {
    const productInfoDom = ref.current;
    if (productInfoDom) {
      productInfoDom.addEventListener('mouseover', mouseoverHandler);
      productInfoDom.addEventListener('mouseout', mouseoutHandler);
    }

    return () => {
      if (productInfoDom) {
        productInfoDom.removeEventListener('mouseover', mouseoverHandler);
        productInfoDom.removeEventListener('mouseout', mouseoutHandler);
      }
    };
  }, [ref]);

  return (
    <div
      className={classNames('header__product-info', { 'header__product-info--active': mouseover })}
      ref={ref}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onFocus={mouseoverHandler}
      onBlur={mouseoutHandler}
    >
      {productName}
      <div className="header__product-info__icon">
        <Icon icon="info" size="alpha" color="gray-500" />
      </div>
      <div className="header__product-info-tooltip">
        <LegacyTooltip captureFocus={false}>
          <div className="header__product-description">{productInfo}</div>
        </LegacyTooltip>
      </div>
    </div>
  );
};

/* eslint-disable no-unused-vars */
const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button onClick={() => loginWithRedirect()} type="button">
      Mit Field-Account anmelden
    </button>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button onClick={() => logout()} type="button">
      Aus Field-Account abmelden
    </button>
  );
};

const UserInfo = () => {
  const [mouseover, setMouseover] = useState(false);
  const ref = useRef();

  const user = useSelector((state) => state.user);

  const mouseoverHandler = () => {
    setMouseover(true);
  };
  const mouseoutHandler = () => {
    setMouseover(false);
  };

  useEffect(() => {
    const userInfoDom = ref.current;

    if (userInfoDom) {
      userInfoDom.addEventListener('mouseover', mouseoverHandler);
      userInfoDom.addEventListener('mouseout', mouseoutHandler);
    }

    return () => {
      if (userInfoDom) {
        userInfoDom.removeEventListener('mouseover', mouseoverHandler);
        userInfoDom.removeEventListener('mouseout', mouseoutHandler);
      }
    };
  }, [ref]);

  return (
    <div
      className={classNames('header__user-info', { 'header__user-info--active': mouseover })}
      ref={ref}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onFocus={mouseoverHandler}
      onBlur={mouseoutHandler}
    >
      <Icon icon="user" size="alpha" />
      <div className="header__user-info-tooltip">
        <LegacyTooltip
          captureFocus={false}
          tipPosition="top-right"
          footer={
            <>
              <a href="#that-is-me" className="header__user-description-link">
                That’s not me
              </a>
              <a href="#login-with-field-account" className="header__user-description-link">
                Login with Field account
              </a>
            </>
          }
        >
          <div className="header__user-description">
            <div className="header__user-description-intro">This data will be added to your notes:</div>
            <MetaDataTable
              data={[
                {
                  icon: 'user',
                  text: user.email,
                },
                {
                  icon: 'location-pin',
                  text: 'Hamburg, Germany',
                },
                {
                  icon: 'clock',
                  text: new Date().toLocaleString(),
                },
              ]}
            />
            <div className="header__user-description-main">
              <Paragraph size="alpha">
                After filling out the survey you can review and choose to anonymize individual notes.
              </Paragraph>
              <Paragraph size="alpha">
                Your data will not be sent to our server until you hit submit at the end of the survey.
              </Paragraph>
            </div>
          </div>
        </LegacyTooltip>
      </div>
    </div>
  );
};
/* eslint-enable no-unused-vars */

const Timebox = () => {
  const dispatch = useDispatch();

  const totalDuration = useSelector((state) => state.user.timeboxTotal);
  const remainingDuration = useSelector((state) => state.user.timeboxRemaining);
  const isRunning = useSelector((state) => state.user.timeboxIsRunning);

  const [timebox, setTimebox] = useState(remainingDuration === undefined ? totalDuration : remainingDuration);

  useEffect(() => {
    if (timebox % 10 === 0) {
      dispatch(setRemainingTimeboxTime(timebox));
    }
  }, [dispatch, timebox]);

  return (
    <TimeboxIndicator
      time={timebox}
      setTimeRemaining={setTimebox}
      resetTime={() => {
        setTimebox(totalDuration);
      }}
      isRunning={isRunning !== undefined ? isRunning : true}
      setIsRunning={(r) => {
        dispatch(setTimeboxIsRunning(r));
      }}
    />
  );
};

const Header = ({ aspect }) => {
  const timeboxIsActive = useSelector((state) => state.user.timeboxIsActive);
  const productName = useSelector((state) => state.product.name);
  const productInfo = useSelector((state) => state.product.info);

  return (
    <header className="header grid">
      <div className="header__left">
        <span className="header__logo">Field</span>
        {aspect && <span className="header__aspect">{aspect}</span>}
      </div>
      <div className="header__center">
        {productName && !productInfo && productName}
        {productName && productInfo && <ProductInfo />}
      </div>
      <div className="header__right">
        {timeboxIsActive && <Timebox />}
        {/* <UserInfo /> */}
      </div>
    </header>
  );
};

export default Header;
