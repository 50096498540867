/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import './Home.scss';

import DefaultPage from '../DefaultPage';
import InfoBlock from '../InfoBlock';

const Home = () => {
  return (
    <DefaultPage
      title="Welcome to Collect!"
      description="Collect is a part of Field. Field is the ideal tool for collecting distributed knowledge and relevant information throughout your organization and comparing it to developments in the market. It allows you to gain insights, establish how specific aspects influence one another, derive strategies, and plan execution. All stakeholders retain a clear view of product development and portfolio management at all times."
      theme="lime-pie"
      infoBlocks={
        <>
          <InfoBlock title="Learn More" icon="docs" iconColor="raspberry-sorbet">
            Collect helps you to gather knowledge and enable participation in your organization. To learn more about
            Collect make sure to check out our website,{' '}
            <a href="https://field.so" target="_blank" rel="noopener noreferrer">
              www.field.so
            </a>
            .
          </InfoBlock>
          <InfoBlock title="Where Can I Answer the Survey?" icon="pf-collect" iconColor="blueberry-pancakes">
            If you are looking for a specific survey, make sure to click on the link you received in an email. You can
            only answer the survey by following this link.{' '}
          </InfoBlock>
        </>
      }
    />
  );
};

export default Home;
