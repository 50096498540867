/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { BasicAPI } from './basic.js';
import { CoreAPI } from './core.js';
import { InviteAPI } from './invite.js';
import { PublicationAPI } from './publication';
import { request, withBase, withToken, withSecret, withAdditionalOptions } from './request.js';
import { SurveyAPI } from './survey.js';

export {
  USER_ROLE_ADMIN,
  USER_ROLE_USER,
  USER_ROLE_GUEST,
  ALL_USER_ROLES,
  WORKSPACE_PLAN_WAITLIST,
  WORKSPACE_PLAN_BASIC,
  WORKSPACE_PLAN_PRO,
  WORKSPACE_PLAN_PRO_TRIAL,
  WORKSPACE_PLAN_TEAM,
  WORKSPACE_PLAN_TEAM_TRIAL,
  WORKSPACE_PLAN_ENTERPRISE,
  ALL_WORKSPACE_PLANS,
  PRO_FEATURES_WORKSPACE_PLANS,
} from './basic.js';
export { CUE_PROP_ASPECT, CUE_PROP_LINKED_FIELDS, CUE_PROP_LINKED_CUES } from './core.js';
export { ClientError } from './error.js';

export const BINDING_STATUS = {
  Established: 'established',
  Expired: 'expired',
  Pending: 'pending',
  Void: 'void',
};

// Field API Client, with support for API version 0.
export class Client {
  constructor(options) {
    const { base, token, secret, ..._options } = options;

    const r = withAdditionalOptions(
      withSecret(
        withToken(withBase(request, base || 'https://api.fieldbyfield.com/v0'), token || null),
        secret || null
      ),
      _options
    );

    this.basic = new BasicAPI({ request: r });
    this.invite = new InviteAPI({ request: r });
    this.core = new CoreAPI({ request: r });
    this.survey = new SurveyAPI({ request: r });
    this.publication = new PublicationAPI({ request: r });
  }
}
