/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import SVGFrame from './SVGFrame.jsx';

const DefaultIcon = ({ size, theme }) => (
  <SVGFrame
    className={classNames(
      'cue-type-icon',
      `cue-type-icon--default`,
      `cue-type-icon--${size}`,
      `cue-type-icon--${theme}`
    )}
    height={140}
    width={160}
  >
    <path d="M30,10 h90 a30,30 0 0 1 30,30 v60 a30,30 0 0 1 -30,30 h-90 a30,30 0 0 1 -30,-30 v-60 a30,30 0 0 1 30,-30 z" />
  </SVGFrame>
);

export default DefaultIcon;
