/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

import './Breadcrumbs.scss';

/**
 * Breadcrumbs tell the user where they are in a nested navigation
 * and enable them to jump back to higher levels.
 *
 * @param {Object} props
 * @param {[{id: number | string, title: string}]} props.crumbs
 * @param {({activeCrumbId}) => void} props.onChange
 * @param {number | string} props.activeCrumbId
 */
const Breadcrumbs = ({ crumbs, onChange = () => {}, activeCrumbId, theme = 'default', Link }) => (
  <nav className={classNames('breadcrumbs', `breadcrumbs--theme-${theme}`)}>
    {crumbs.map((t, i) => {
      let element = (
        <button
          className={classNames('breadcrumbs__tab', {
            'breadcrumbs__tab--active': t.id === activeCrumbId,
          })}
          type="button"
          onClick={() => {
            onChange({ selectedCrumbId: t.id });
          }}
          role="tab"
          aria-selected={t.id === activeCrumbId}
        >
          {t.title}
        </button>
      );

      if (t.to && Link) {
        element = (
          <Link
            className={classNames('breadcrumbs__tab', {
              'breadcrumbs__tab--active': t.id === activeCrumbId,
            })}
            onClick={() => {
              onChange({ selectedCrumbId: t.id });
            }}
            to={t.to}
            role="tab"
            aria-selected={t.id === activeCrumbId}
          >
            {t.title}
          </Link>
        );
      }

      return (
        <div key={t.id} className="breadcrumbs__tab-container">
          {element}
          {i < crumbs.length - 1 && (
            <div className="breadcrumbs__chevron">
              <Icon icon="arrow-right" size="tiny" />
            </div>
          )}
        </div>
      );
    })}
  </nav>
);
export default Breadcrumbs;
