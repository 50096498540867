/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { Point, Polygon, intersect } from '@mathigon/euclid';

import {
  ASPECT_PRODUCT,
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
  ASPECT_PROBLEM,
  ASPECT_SOLUTION,
  ASPECT_UNIQUENESS,
  ASPECT_ALTERNATIVES,
} from './aspect.js';

// Areas of these aspects are on the left.
export const INNER_ASPECTS = [
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
  ASPECT_SOLUTION,
  ASPECT_UNIQUENESS,
];

// Areas of these aspects are the right.
export const OUTER_ASPECTS = [
  ASPECT_PROBLEM,
  ASPECT_ALTERNATIVES,
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
];

// Areas of these aspects are at the top.
export const INTANGIBLE_ASPECTS = [
  ASPECT_PROBLEM,
  ASPECT_UNIQUENESS,
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
];

// Areas of these aspects are at the bottom.
export const TANGIBLE_ASPECTS = [
  ASPECT_SOLUTION,
  ASPECT_ALTERNATIVES,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
];

export const QUADRANT_TOP_LEFT_ASPECTS = [
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
  ASPECT_MOTIVATIONS,
  ASPECT_UNIQUENESS,
  ASPECT_PROBLEM,
  ASPECT_SOLUTION,
];

export const QUADRANT_BOTTOM_LEFT_ASPECTS = [
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_UNIQUENESS,
  ASPECT_SOLUTION,
  ASPECT_ALTERNATIVES,
];

export const QUADRANT_BOTTOM_RIGHT_ASPECTS = [
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_SOLUTION,
  ASPECT_ALTERNATIVES,
  ASPECT_PROBLEM,
];

export const QUADRANT_TOP_RIGHT_ASPECTS = [
  ASPECT_GOALS,
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_UNIQUENESS,
  ASPECT_ALTERNATIVES,
  ASPECT_PROBLEM,
];

// This describes the areas on the canvas as multiple polygons, that put
// together form the canvas.

export const AREAS = {
  [ASPECT_PRODUCT]: [
    [-1.5, 0],
    [0, -1.5],
    [1.5, 0],
    [0, 1.5],
  ],
  [ASPECT_MOTIVATIONS]: [
    [0, -5],
    [0, Number.NEGATIVE_INFINITY],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
    [5, -5],
  ],
  [ASPECT_USERS]: [
    [5, 0],
    [Number.POSITIVE_INFINITY, 0],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY],
    [5, -5],
  ],
  [ASPECT_CUSTOMERS]: [
    [5, 0],
    [Number.POSITIVE_INFINITY, 0],
    [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY],
    [5, 5],
  ],
  [ASPECT_DISTRIBUTION]: [
    [0, 5],
    [0, Number.POSITIVE_INFINITY],
    [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY],
    [5, 5],
  ],
  [ASPECT_PRODUCTION]: [
    [0, 5],
    [0, Number.POSITIVE_INFINITY],
    [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
    [-5, 5],
  ],
  [ASPECT_ENABLERS]: [
    [-5, 0],
    [Number.NEGATIVE_INFINITY, 0],
    [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
    [-5, 5],
  ],
  [ASPECT_DRIVERS]: [
    [-5, 0],
    [Number.NEGATIVE_INFINITY, 0],
    [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY],
    [-5, -5],
  ],
  [ASPECT_GOALS]: [
    [0, -5],
    [0, Number.NEGATIVE_INFINITY],
    [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY],
    [-5, -5],
  ],
  [ASPECT_PROBLEM]: [
    [0, -1.5],
    [0, -5],
    [5, -5],
    [5, 0],
    [1.5, 0],
  ],
  [ASPECT_SOLUTION]: [
    [0, 1.5],
    [0, 5],
    [-5, 5],
    [-5, 0],
    [-1.5, 0],
  ],
  [ASPECT_UNIQUENESS]: [
    [0, -1.5],
    [0, -5],
    [-5, -5],
    [-5, 0],
    [-1.5, 0],
  ],
  [ASPECT_ALTERNATIVES]: [
    [0, 1.5],
    [0, 5],
    [5, 5],
    [5, 0],
    [1.5, 0],
  ],
};

export const BOUNDED_AREAS = Object.keys(AREAS).reduce((acc, k) => {
  acc[k] = AREAS[k].map((p) => p.map((c) => clamp(c, -10, 10)));
  return acc;
}, {});

const MAX_COORD = 10000;
const MIN_COORD = -10000;

function makeClamp(min, max) {
  return (number) => {
    return Math.min(Math.max(number, min), max);
  };
}

// Returns an aspect for a point with coordinates x and y on canvas.
export const getAspectAtPoint = (point) => {
  const coordClamp = makeClamp(MIN_COORD, MAX_COORD);
  return Object.keys(AREAS).find((aspect) => {
    return new Polygon(...AREAS[aspect].map((p) => new Point(...p.map(coordClamp)))).contains(
      new Point(...point.map(coordClamp))
    );
  });
};

const MAX_COORD_VISIBLE = 9;
const MIN_COORD_VISIBLE = -9;

// Returns a random point that is within the given aspect
export const getPointInAspect = (aspect) => {
  const [minX, minY, maxX, maxY] = AREAS[aspect].reduce(
    ([aMinX, aMinY, aMaxX, aMaxY], point) => {
      // Even though the areas are theoretically open, when generating new points within
      // an area, we want to make sure it is visible on the canvas, so we use smaller
      // bounds.
      const [x, y] = point.map((c) => clamp(c, MIN_COORD_VISIBLE, MAX_COORD_VISIBLE));
      return [Math.min(aMinX, x), Math.min(aMinY, y), Math.max(aMaxX, x), Math.max(aMaxY, y)];
    },
    [0, 0, 0, 0]
  );

  let point;

  do {
    point = [minX + Math.random() * (maxX - minX), minY + Math.random() * (maxY - minY)];
  } while (getAspectAtPoint(point) !== aspect);

  return point;
};

function clamp(number, min, max) {
  return Math.min(Math.max(number, min), max);
}

export function getTotalOverlappingArea(shapesA, shapesB) {
  let total = 0;

  shapesA.forEach((a) => {
    shapesB.forEach((b) => {
      const vv = intersect([a.polygon.points], [b.polygon.points]);

      if (vv.length) {
        const vvv = vv[0];
        const poly = new Polygon(...vvv);
        total += poly.area;
      }
    });
  });
  return total;
}

export function getOverlappingArea(polygonA, polygonB) {
  let total = 0;

  const vv = intersect([polygonA.points], [polygonB.points]);

  if (vv.length) {
    const vvv = vv[0];
    const poly = new Polygon(...vvv);
    total += poly.area;
  }

  return total;
}

export function getPolyInCircle({ circle, n = 9 }) {
  const points = [];

  for (let i = 0; i <= n; ++i) {
    const a = (4 * Math.PI * i + Math.PI * n + 2 * Math.PI) / (2 * n);
    const xx = circle.c.x + circle.r * Math.cos(a);
    const yy = circle.c.y + circle.r * Math.sin(a);
    points.push([xx, yy]);
  }

  return new Polygon(...points.map((p) => new Point(...p)));
}
