/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect, useState, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  Paragraph,
  Overlay,
  Floating,
  Modal,
  TextInput,
  FormElement,
  Button,
  InspirationList,
} from '@fieldbyfield/components';

import CueList from '../CueList';
import {
  addInspiration,
  deleteInspiration,
  updateInspiration,
  addCue,
  updateCue,
  deleteCue,
  anonymizeCue,
  deanonymizeCue,
  updateNewCue,
} from '../redux';

import './Question.scss';

const ShareQuestionModal = ({ question, onDismiss }) => {
  const [recipients, setRecipients] = useState('');

  return (
    <div className="share-question-modal">
      <Modal onDismiss={onDismiss} title="Forward Question">
        <div className="share-question-modal__question">
          <Paragraph size="gamma">{question}</Paragraph>
        </div>
        <div className="share-question-modal__form">
          <FormElement
            label="Recipients"
            annotation="You can add multiple receivers separated by comma."
            name="recipients"
          >
            <TextInput
              placeholder="mail@example.com, info@acmecorp.com"
              onChange={setRecipients}
              value={recipients}
              name="recipients"
              light
            />
          </FormElement>
          <div className="share-question-modal__button">
            <Button type="primary">Forward Question</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Question = ({ id, onNext }) => {
  const question = useSelector((state) => state.questions.find((q) => q.id === id).text);
  const inspirations = useSelector((state) => state.questions.find((q) => q.id === id).inspirations);
  const cues = useSelector((state) => state.questions.find((q) => q.id === id).cues);
  const newCue = useSelector((state) => state.questions.find((q) => q.id === id).newCue);
  const user = useSelector((state) => state.user.email);
  const reversedCues = cues.map((c) => ({ ...c, user })).reverse();

  const [showSharingModal, setShowSharingModal] = useState(false);

  const dispatch = useDispatch();

  const handleKey = useCallback(
    (ev) => {
      if (ev.key === 'Enter' && ev.metaKey) {
        onNext();
      }
    },
    [onNext]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKey);

    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, [handleKey]);

  return (
    <main className="question grid">
      {showSharingModal && (
        <Floating>
          <Overlay
            animated
            onClick={() => {
              setShowSharingModal(false);
            }}
          >
            <ShareQuestionModal
              question={question}
              onDismiss={() => {
                setShowSharingModal(false);
              }}
            />
          </Overlay>
        </Floating>
      )}

      <div className="question__question">{question}</div>
      {inspirations.length > 0 && (
        <div className="question__inspiration">
          <div className="question__inspiration-intro">Think about …</div>
          <InspirationList
            readOnly
            inspirations={inspirations}
            onAddItem={(text) => {
              dispatch(
                addInspiration({
                  question: id,
                  text,
                })
              );
            }}
            onDeleteItem={(itemId) => {
              dispatch(
                deleteInspiration({
                  question: id,
                  id: itemId,
                })
              );
            }}
            onChangeItem={(item) => {
              dispatch(
                updateInspiration({
                  question: id,
                  id: item.id,
                  text: item.text,
                })
              );
            }}
          />
        </div>
      )}
      <div className="question__answers">
        {/* <div className="question__options">
          <div className="question__options-card">
            <button className="question__options-button">Rephrase Question</button>
            <button
              className="question__options-button"
              onClick={() => {
                setShowSharingModal(true);
              }}
            >
              Who might be able to give a better answer to this question?
            </button>
          </div>
        </div> */}
        <div className="question__cues">
          <CueList
            cues={reversedCues}
            newCueText={newCue || ''}
            onAddCue={(text) => {
              dispatch(addCue({ question: id, text }));
            }}
            onUpdateCue={(cue) => {
              if (cue.id === 'new') {
                dispatch(updateNewCue({ question: id, ...cue }));
              } else {
                dispatch(updateCue({ question: id, ...cue }));
              }
            }}
            onDeleteCue={(cueId) => {
              dispatch(deleteCue({ question: id, id: cueId }));
            }}
            onAnonymizeCue={(cueId) => {
              dispatch(anonymizeCue({ question: id, id: cueId }));
            }}
            onDeanonymizeCue={(cueId) => {
              dispatch(deanonymizeCue({ question: id, id: cueId }));
            }}
          />
        </div>
      </div>
    </main>
  );
};

export default Question;
