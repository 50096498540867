/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './Checkbox.scss';

const Checkmark = () => {
  return (
    <svg
      className="checkbox-button__checkmark"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 8.82794L5.33368 13L14 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

/**
 * A Checkbox lets the user confirm their consent.
 *
 * @param {Object} props
 * @param {string} props.children
 * @param {boolean} props.selected
 * @param {(selected: boolean) => void} props.onChange
 * @param {string} props.name
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.invalid]
 * @param {boolean} [props.light]
 * @param {'default' | 'highlight'} [props.theme = "default"]
 */
const Checkbox = ({ children, selected, disabled, invalid, onChange, name, light, theme = 'default' }) => {
  return (
    <div
      className={classNames('checkbox', `checkbox--${theme}`, {
        'checkbox--disabled': disabled,
        'checkbox--invalid': invalid,
        'checkbox--light': light,
      })}
    >
      <label className="checkbox-button" key={children} htmlFor={name}>
        <input
          className="checkbox-button__input"
          type="checkbox"
          id={name}
          name={name}
          checked={selected || false}
          onChange={() => {
            onChange(!selected);
          }}
          disabled={disabled}
        />
        <div className="checkbox-button__indicator">
          <Checkmark />
        </div>
        {children && <span className="checkbox__label">{children}</span>}
      </label>
    </div>
  );
};

export default Checkbox;
