/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import UserAvatar from '../UserAvatar';

import './UserRole.scss';

const UserRole = ({ title, user, size, theme = 'default' }) => {
  return (
    <div className={classNames('user-role', `user-role--size-${size}`, `user-role--theme-${theme}`)} title={user.email}>
      <div className="user-role__avatar">
        <UserAvatar user={user} size={size === 'beta' ? 'gamma' : 'beta'} />
      </div>
      <div className="user-role__description">
        <div className="user-role__role">{title}</div>
        <div className="user-role__name">{user.name || user.email}</div>
      </div>
    </div>
  );
};

export default UserRole;
