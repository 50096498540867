/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState } from 'react';

import { Floating, Overlay, Button, Modal, TextInput, FormElement } from '@fieldbyfield/components';
import { useHistory, useParams } from '@fieldbyfield/router';

import DefaultPage from '../DefaultPage';
import InfoBlock from '../InfoBlock';
import './ThankYou.scss';

const SharingModal = ({ onDismiss }) => {
  const [recipients, setRecipients] = useState('');

  return (
    <div className="sharing-modal">
      <Modal onDismiss={onDismiss} title="Forward Survey">
        <div className="sharing-modal__form">
          <FormElement
            label="Recipients"
            annotation="You can add multiple recipients separated by comma."
            name="recipients"
          >
            <TextInput
              placeholder="mail@example.com, info@acmecorp.com"
              onChange={setRecipients}
              value={recipients}
              name="recipients"
              light
            />
          </FormElement>
          <div className="sharing-modal__button">
            <Button type="primary">Forward Survey</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ThankYou = () => {
  const [showSharingModal, setShowSharingModal] = useState(false);
  const history = useHistory();
  const { workspaceId, surveyId } = useParams();

  return (
    <>
      {showSharingModal && (
        <Floating>
          <Overlay
            animated
            onClick={() => {
              setShowSharingModal(false);
            }}
          >
            <SharingModal
              onDismiss={() => {
                setShowSharingModal(false);
              }}
            />
          </Overlay>
        </Floating>
      )}

      <DefaultPage
        theme="lime-pie"
        title="Thank you!"
        description="Every contribution makes a difference — together we can create a collective and meaningful description of
            our undertaking."
        infoBlocks={
          <>
            <InfoBlock
              title="Forgot something?"
              icon="idea"
              iconColor="blueberry-pancakes"
              action={
                <Button
                  onClick={() => {
                    history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/introduction`);
                  }}
                >
                  Start a new run to add more Notes
                </Button>
              }
            />
            {/* <InfoBlock
              title="Who else could add something?"
              icon="forward-message"
              iconColor="plum-compote"
              action={
                <Button
                  onClick={() => {
                    setShowSharingModal(true);
                  }}
                >
                  Forward Survey …
                </Button>
              }
            >
              Is there someone who could also add valuable contributions? You can forward this survey to this wonderful person!
            </InfoBlock> */}
          </>
        }
      />
    </>
  );
};

export default ThankYou;
