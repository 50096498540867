/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

const FOCUSABLE_NODES_SELECTORS = [
  'button:not([disabled])',
  '[href]',
  'input:not([type="radio"]):not([disabled])', // We only want to select the selected radiobutton
  'input[type="radio"][checked]:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  '[tabindex]:not([tabindex="-1"])',
];

export default function selectFocusableNodes(node = document) {
  return node.querySelectorAll(FOCUSABLE_NODES_SELECTORS.join(', '));
}
