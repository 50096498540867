/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export const ASPECT_PRODUCT = 'Product';
export const ASPECT_MOTIVATIONS = 'MOTIVATIONS';
export const ASPECT_USERS = 'USERS';
export const ASPECT_CUSTOMERS = 'CUSTOMERS';
export const ASPECT_DISTRIBUTION = 'DISTRIBUTION';
export const ASPECT_PRODUCTION = 'PRODUCTION';
export const ASPECT_ENABLERS = 'ENABLERS';
export const ASPECT_DRIVERS = 'DRIVERS';
export const ASPECT_GOALS = 'GOALS';
export const ASPECT_PROBLEM = 'problem';
export const ASPECT_SOLUTION = 'solution';
export const ASPECT_UNIQUENESS = 'uniqueness';
export const ASPECT_ALTERNATIVES = 'alternatives';

// All possible aspects.
export const ALL_ASPECTS = [
  ASPECT_PRODUCT,
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
  ASPECT_PROBLEM,
  ASPECT_SOLUTION,
  ASPECT_UNIQUENESS,
  ASPECT_ALTERNATIVES,
];

// Takes a string name of an aspect and returns the matching aspect constant.
// The matching is case-insensitive. Returns null if the name wasn't matched to
// a constant.
export function aspectFromName(name) {
  const lall = ALL_ASPECTS.map((v) => v.toLowerCase());
  const lname = name.toLowerCase();

  const index = lall.indexOf(lname);

  return index !== -1 ? ALL_ASPECTS[index] : null;
}
