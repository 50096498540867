/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import { CANVAS_DOTGRID_OPTIONS } from './constants.js';

// The DotGrid component produces the dots for each area individually, For two
// reasons:
//
// - we 'manually' identify the dots under label, as text length of
//   labels vary
// - we apply css class names which enables us to highlight the dots
//   of each areas with css rules
const DotGrid = ({ style }) => {
  let stepSize = 10 / 2;

  if (style === CANVAS_DOTGRID_OPTIONS.fine) {
    stepSize = 10 / 4;
  }

  if (style === CANVAS_DOTGRID_OPTIONS.coarse) {
    stepSize = 10;
  }

  const dots = [];

  let x;
  let y;
  // GOALS
  for (y = -80; y < -50; y += stepSize) {
    for (x = -80; x < 0; x += stepSize) {
      if (x <= -50 && x - y <= 0) {
        continue;
      }

      const classes = ['canvas__goals', 'canvas__idea', 'canvas__context'];
      if (y === -60 && x >= -50 && x <= -37) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // DRIVERS
  for (y = -80; y < 0; y += stepSize) {
    for (x = -80; x < -50; x += stepSize) {
      if (x <= -50 && x - y >= 0) {
        continue;
      }

      const classes = ['canvas__drivers', 'canvas__idea', 'canvas__context'];

      if (y === -50 && x >= -78 && x <= -60) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // ENABLERS
  for (y = 80; y > 0; y -= stepSize) {
    for (x = -80; x < -50; x += stepSize) {
      if (x <= -50 && x + y >= 0) {
        continue;
      }

      const classes = ['canvas__enablers', 'canvas__resources', 'canvas__context'];

      if (y === 50 && x >= -80 && x <= -60) {
        classes.push('canvas__dotgrid-dot--over-label ');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // PRODUCTION
  for (y = 80; y > 50; y -= stepSize) {
    for (x = -80; x < 0; x += stepSize) {
      if (x <= -50 && x + y <= 0) {
        continue;
      }

      const classes = ['canvas__production', 'canvas__resources', 'canvas__context'];
      if (y === 60 && x >= -50 && x <= -25) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // DISTRIBUTION
  for (y = 80; y > 50; y -= stepSize) {
    for (x = 80; x > 0; x -= stepSize) {
      if (x >= 50 && x - y >= 0) {
        continue;
      }

      const classes = ['canvas__distribution', 'canvas__market', 'canvas__context'];
      if (y === 60 && x >= 22 && x <= 50) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // CUSTOMERS
  for (y = 80; y > 0; y -= stepSize) {
    for (x = 80; x > 50; x -= stepSize) {
      if (x >= 50 && x - y <= 0) {
        continue;
      }

      const classes = ['canvas__customers', 'canvas__market', 'canvas__context'];

      if (y === 50 && x >= 60 && x <= 80) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // USERS
  for (y = -80; y < 0; y += stepSize) {
    for (x = 80; x > 50; x -= stepSize) {
      if (x >= 50 && x + y <= 0) {
        continue;
      }

      const classes = ['canvas__users', 'canvas__value', 'canvas__context'];

      if (y === -50 && x >= 60 && x <= 73) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // MOTIVATIONS
  for (y = -80; y < -50; y += stepSize) {
    for (x = 80; x > 0; x -= stepSize) {
      if (x >= 50 && x + y >= 0) {
        continue;
      }

      const classes = ['canvas__motivations', 'canvas__value', 'canvas__context'];
      if (y === -60 && x >= 22 && x <= 50) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // uniqueness
  for (y = -50; y < 0; y += stepSize) {
    for (x = -50; x < 0; x += stepSize) {
      if (x === -50 || y === -50) {
        continue;
      }

      const classes = ['canvas__uniqueness', 'canvas__idea', 'canvas__core'];

      if (x >= -13 && y >= -13 && x + y > -17) {
        classes.push('canvas__dotgrid-dot--over-product');
      }

      if (y === -25 && x >= -40 && x <= -20) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // problem
  for (y = -50; y < 0; y += stepSize) {
    for (x = 0; x < 50; x += stepSize) {
      if (x === 0 || y === -50) {
        continue;
      }

      const classes = ['canvas__problem', 'canvas__value', 'canvas__core'];

      if (x <= 13 && y >= -13 && x - y < 17) {
        classes.push('canvas__dotgrid-dot--over-product');
      }

      if (y === -25 && x >= 25 && x <= 40) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // solution
  for (y = 0; y < 50; y += stepSize) {
    for (x = -50; x < 0; x += stepSize) {
      if (x === -50 || y === 0) {
        continue;
      }

      const classes = ['canvas__solution', 'canvas__resources', 'canvas__core'];

      if (x >= -13 && y <= 13 && x - y > -17) {
        classes.push('canvas__dotgrid-dot--over-product');
      }

      if (y === 25 && x >= -40 && x <= -25) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // alternatives
  for (y = 0; y < 50; y += stepSize) {
    for (x = 0; x < 50; x += stepSize) {
      if (x === 0 || y === 0) {
        continue;
      }

      const classes = ['canvas__alternatives', 'canvas__market', 'canvas__core'];

      if (x <= 13 && y <= 13 && x + y < 17) {
        classes.push('canvas__dotgrid-dot--over-product');
      }

      if (y === 25 && x >= 17 && x <= 40) {
        classes.push('canvas__dotgrid-dot--over-label');
      }

      dots.push({
        x,
        y,
        classes,
      });
    }
  }

  // Underneith the lines

  // diagonals
  for (y = -80; y <= 80; y += stepSize) {
    for (x = -80; x <= 80; x += stepSize) {
      if ((x !== y && x !== -y) || (y >= -50 && y <= 50)) {
        continue;
      }

      dots.push({
        x,
        y,
        classes: ['canvas__dotgrid-dot--over-line', 'canvas__context'],
      });
    }
  }

  for (x = -80; x <= 80; x += stepSize) {
    const classes = [];

    if (x > -15 && x < 15) {
      classes.push('canvas__dotgrid-dot--over-product');
    } else {
      classes.push('canvas__dotgrid-dot--over-line');
    }

    if (x <= -50 || x >= 50) {
      classes.push('canvas__context');
    }

    if (x >= -50 && x <= 50) {
      classes.push('canvas__core');
    }

    dots.push({
      x,
      y: 0,
      classes,
    });
  }

  for (y = -80; y <= 80; y += stepSize) {
    const classes = [];

    if (y === 0) {
      continue;
    }

    if (y > -15 && y < 15) {
      classes.push('canvas__dotgrid-dot--over-product');
    } else {
      classes.push('canvas__dotgrid-dot--over-line');
    }

    if (y <= -50 || y >= 50) {
      classes.push('canvas__context');
    }

    if (y >= -50 && y <= 50) {
      classes.push('canvas__core');
    }

    dots.push({
      x: 0,
      y,
      classes,
    });
  }

  for (x = -50; x <= 50; x += stepSize) {
    if (x === 0) {
      continue;
    }

    dots.push({
      x,
      y: -50,
      classes: ['canvas__dotgrid-dot--over-line', 'canvas__context', 'canvas__core'],
    });
  }

  for (x = -50; x <= 50; x += stepSize) {
    if (x === 0) {
      continue;
    }

    dots.push({
      x,
      y: 50,
      classes: ['canvas__dotgrid-dot--over-line', 'canvas__context', 'canvas__core'],
    });
  }

  for (y = -50; y < 50; y += stepSize) {
    if (y === 0 || y === -50) {
      continue;
    }

    dots.push({
      x: -50,
      y,
      classes: ['canvas__dotgrid-dot--over-line', 'canvas__context', 'canvas__core'],
    });
  }

  for (y = -50; y < 50; y += stepSize) {
    if (y === 0 || y === -50) {
      continue;
    }

    dots.push({
      x: 50,
      y,
      classes: ['canvas__dotgrid-dot--over-line', 'canvas__context', 'canvas__core'],
    });
  }

  // Margin
  for (y = -100; y <= 100; y += stepSize) {
    for (x = -100; x <= 100; x += stepSize) {
      if (x >= -80 && x <= 80 && y >= -80 && y <= 80) {
        continue;
      }

      const classes = ['convas__margin'];

      // customers (the label goes into the margin)
      if (y === 50 && x >= 60 && x <= 85) {
        classes.push('canvas__dotgrid-dot--over-label', 'canvas__customers', 'canvas__context');
      }

      // under the dashed lines
      if (x === 0 && y >= -83 && y <= 83) {
        classes.push('canvas__dotgrid-dot--over-line', 'canvas__context');
      }
      if (y === 0 && x >= -83 && x <= 83) {
        classes.push('canvas__dotgrid-dot--over-line', 'canvas__context');
      }

      // under the markers for the elements
      if ((y === 80 || y === -80) && x >= -82.5 && x <= 82.5) {
        classes.push('canvas__dotgrid-dot--over-line', 'canvas__context', 'canvas__elements');
      }
      if ((x === 80 || x === -80) && y >= -82.5 && y <= 82.5) {
        classes.push('canvas__dotgrid-dot--over-line', 'canvas__context', 'canvas__elements');
      }

      // I D E A
      if (y >= -88 && y < -80 && x >= -88 && x < -80) {
        classes.push('canvas__dotgrid-dot--over-label canvas__elements canvas__idea canvas__idea-element');
      }

      // V A L U E
      if (y >= -88 && y < -80 && x > 80 && x <= 90) {
        classes.push('canvas__dotgrid-dot--over-label canvas__elements canvas__value canvas__value-element');
      }

      // R E S O U R C E S
      if (y > 80 && y <= 93 && x >= -93 && x < -80) {
        classes.push('canvas__dotgrid-dot--over-label canvas__elements canvas__resources canvas__resources-element');
      }

      // M A R K E T
      if (y > 80 && y <= 88 && x > 80 && x <= 93) {
        classes.push('canvas__dotgrid-dot--over-label canvas__elements canvas__market canvas__market-element');
      }

      dots.push({
        x,
        y,
        classes: [classes],
      });
    }
  }

  return (
    <g className="canvas__dotgrid">
      {dots.map((d) => (
        <circle
          cx={d.x}
          cy={d.y}
          r=".25"
          className={classNames('canvas__dotgrid-dot', ...d.classes)}
          key={`${d.x},${d.y}`}
        />
      ))}
    </g>
  );
};

export default DotGrid;
