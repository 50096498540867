/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

const TermsContent = (
  <>
    <h1>Privacy Policy</h1>

    <p>Effective: 6th February 2021</p>

    <h2>Scope</h2>

    <p>
      This Privacy Policy describes how Field collects, uses and discloses information and what choices you have with
      respect to the information.
    </p>

    <p>
      When we refer to “Field”, we mean the Field entity that acts as the controller or processor of your information,
      as explained in more detail in the “Identifying the Data Controller and Processor” section below.
    </p>

    <p>
      This Privacy Policy applies to Field’s Software as a Service, including the associated Field mobile and desktop
      applications (collectively the “Services”), field.so and other Field websites (collectively the “Websites”) and
      other interactions (e.g. customer service enquiries, user conferences, etc.) you may have with Field. If you do
      not agree with the terms, do not access or use the Services, Websites or any other aspect of Field’s business.
    </p>

    <p>
      This Privacy Policy does not apply to any third-party applications or software that integrate with the Services
      through the Field platform (“Third-Party Services”), or any other third-party products, services or businesses. In
      addition, a separate agreement governs delivery, access and use of the Services (the “Customer Agreement”),
      including the processing of any messages, files or other content submitted through Services accounts
      (collectively, “Customer Data”). The organisation (e.g., your employer or another entity or person) that entered
      into the Customer Agreement (“Customer”) controls its instance of the Services (its “Workspace”) and any
      associated Customer Data. If you have any questions about specific Workspace settings and privacy practices,
      please contact the Customer whose Workspace you use. If you have an account, you can check
      <a href="https://field.so/account/team" target="_blank" rel="noreferrer">
        https://field.so/account/team
      </a>{' '}
      for contact information of your Workspace owner(s) and administrator(s). If you have received an invitation to
      join a Workspace but have not yet created an account, you should request assistance from the Customer that sent
      the invitation.
    </p>

    <h2>Information we collect and receive</h2>

    <p>
      Field may collect and receive Customer Data and other information and data (“Other Information”) in a variety of
      ways:
    </p>

    <ul>
      <li>
        <strong>Customer Data.</strong> Customers or individuals granted access to a Workspace by a Customer
        (“Authorised Users”) routinely submit Customer Data to Field when using the Services.
      </li>

      <li>
        <strong>Other Information.</strong> Field also collects, generates and/or receives Other Information:
        <ol type="I">
          <li>
            Workspace and account information. To create or update a Workspace account, you or your Customer (e.g. your
            employer) supply Field with an email address, phone number, password, domain and/or similar account details.
            For details on Workspace creation, click here. In addition, Customers that purchase a paid version of the
            Services provide Field (or its payment processors) with billing details such as credit card information,
            banking information and/or a billing address.
          </li>

          <li>
            Usage information.
            <ul>
              <li>
                Services metadata. When an Authorised User interacts with the Services, metadata is generated that
                provides additional context about the way Authorised Users work. For example, Field logs the Workspaces,
                channels, people, features, content and links you view or interact with, the types of files shared and
                what Third-Party Services are used (if any).
              </li>

              <li>
                Log data. As with most websites and technology services delivered over the Internet, our servers
                automatically collect information when you access or use our Websites or Services and record it in log
                files. This log data may include the Internet Protocol (IP) address, the address of the web page visited
                before using the Website or Services, browser type and settings, the date and time the Services were
                used, information about browser configuration and plugins, language preferences and cookie data.
              </li>

              <li>
                Device information. Field collects information about devices accessing the Services, including type of
                device, what operating system is used, device settings, application IDs, unique device identifiers and
                crash data. Whether we collect some or all of this Other Information often depends on the type of device
                used and its settings.
              </li>

              <li>
                Location information. We receive information from you, your Customer and other third-parties that helps
                us approximate your location. We may, for example, use a business address submitted by your employer or
                an IP address received from your browser or device to determine approximate location. Field may also
                collect location information from devices in accordance with the consent process provided by your
                device.
              </li>
            </ul>
          </li>

          <li>
            <strong>Cookie information.</strong> Field uses a variety of cookies and similar technologies in our
            Websites and Services to help us collect Other Information. For more details about how we use these
            technologies, and your opt-out opportunities and other options, please see our Cookie policy.
          </li>

          <li>
            <strong>Third-Party Services.</strong> A Customer can choose to permit or restrict Third-Party Services for
            its Workspace. Typically, Third-Party Services are software that integrate with our Services, and a Customer
            can permit its Authorised Users to enable and disable these integrations for its Workspace. Field may also
            develop and offer Field applications that connect the Services with a Third-Party Service. Once enabled, the
            provider of a Third Party Service may share certain information with Field. For example, if a cloud storage
            application is enabled to permit files to be imported to a Workspace, we may receive the user name and email
            address of Authorised Users, along with additional information that the application has elected to make
            available to Field to facilitate the integration. Authorised Users should check the privacy settings and
            notices in these Third-Party Services to understand what data may be disclosed to Field. When a Third-Party
            Service is enabled, Field is authorised to connect and access Other Information made available to Field in
            accordance with our agreement with the Third-Party Provider and any permission(s) granted by the Customer
            (including by its Authorised User(s)). We do not, however, receive or store passwords for any of these
            Third-Party Services when connecting them to the Services. For more information on Third-Party Services,
            click here.
          </li>

          <li>
            <strong>Contact information.</strong> In accordance with the consent process provided by your device, any
            contact information that an Authorised User chooses to import (such as an address book from a device) is
            collected when using the Services.
          </li>

          <li>
            <strong>Third-party data.</strong> Field may receive data about organisations, industries, lists of
            companies that are customers, Website visitors, marketing campaigns and other matters related to our
            business from parent corporation(s), affiliates and subsidiaries, our partners or others that we use to make
            our own information better or more useful. This data may be combined with Other Information we collect and
            might include aggregate-level data, such as which IP addresses correspond to postcodes or countries. Or it
            might be more specific: for example, how well an online marketing or email campaign performed.
          </li>

          <li>
            <strong>Additional information provided to Field.</strong> We also receive Other Information when submitted
            to our Websites or in other ways, such as if you participate in a focus group, contest, activity or event,
            apply for a job, enrol in a certification programme or other educational programme hosted by Field or a
            vendor, request support, interact with our social media accounts or otherwise communicate with Field.
          </li>
        </ol>
      </li>
    </ul>

    <p>
      Generally, no one is under a statutory or contractual obligation to provide any Customer Data or Other Information
      (collectively “Information”). However, certain Information is collected automatically and if some Information,
      such as Workspace setup details, is not provided, we may be unable to provide the Services.
    </p>

    <h2>How we use information</h2>

    <p>
      Customer Data will be used by Field in accordance with the Customer’s instructions, including any applicable terms
      in the Customer Agreement and the Customer’s use of Services functionality, and as required by applicable law.
      Field is a Processor of Customer Data and the Customer is the Controller. The Customer may, for example, use the
      Services to grant and remove access to a Workspace, assign roles and configure settings, access, modify, export,
      share and remove Customer Data and otherwise apply its policies to the Services.
    </p>

    <p>
      Field uses Other Information in furtherance of our legitimate interests in operating our Services, Websites and
      business. More specifically, Field uses Other Information:
    </p>

    <ul>
      <li>
        To provide, update, maintain and protect our Services, Websites and business. This includes use of Other
        Information to support delivery of the Services under a Customer Agreement, prevent or address service errors,
        security or technical issues, analyse and monitor usage, trends and other activities or at an Authorised User’s
        request.
      </li>

      <li>As required by applicable law, legal process or regulation.</li>

      <li>
        To communicate with you by responding to your requests, comments and questions. If you contact us, we may use
        your Other Information to respond.
      </li>

      <li>
        To develop and provide search, learning and productivity tools and additional features. Field tries to make the
        Services as useful as possible for specific Workspaces and Authorised Users. For example, we may improve search
        functionality by using Other Information to help determine and rank the relevance of content, channels or
        expertise to an Authorised User, make Services or Third-Party Service suggestions based on historical use and
        predictive models, identify organisational trends and insights, customise a Services experience or create new
        productivity features and products.
      </li>

      <li>
        To send emails and other communications. We may send you service, technical and other administrative emails,
        messages and other types of communications. We may also contact you to inform you about changes in our Services,
        our Services offerings and important Services-related notices, such as security and fraud notices. These
        communications are considered part of the Services and you may not opt out of them. In addition, we sometimes
        send emails about new product features, promotional communications or other news about Field. These are
        marketing messages so you can control whether you receive them. If you have additional questions about a message
        you have received from Field please get in touch through the contact mechanisms described below.
      </li>

      <li>
        For billing, account management and other administrative matters. Field may need to contact you for invoicing,
        account management and similar reasons and we use account data to administer accounts and keep track of billing
        and payments.
      </li>

      <li>To investigate and help prevent security issues and abuse.</li>
    </ul>

    <p>
      If Information is aggregated or de-identified so that it is no longer reasonably associated with an identified or
      identifiable natural person, Field may use it for any business purpose. To the extent Information is associated
      with an identified or identifiable natural person and is protected as personal data under applicable data
      protection law, it is referred to in this Privacy Policy as “Personal Data”.
    </p>

    <h2>Data retention</h2>

    <p>
      Field will retain Customer Data in accordance with a Customer’s instructions, including any applicable terms in
      the Customer Agreement and the Customer’s use of Services functionality, and as required by applicable law.
      Depending on the Services subscription, the Customer may be able to customise their retention settings and apply
      those customised settings at the workspace level, channel level or other level. The Customer may also apply
      different settings to messages, files or other types of Customer Data. The deletion of Customer Data and other use
      of the Services by the Customer may result in the deletion and/or de-identification of certain associated Other
      Information. For more detail, please review the Help Centre or contact the Customer. Field may retain Other
      Information pertaining to you for as long as necessary for the purposes described in this Privacy Policy. This may
      include keeping your Other Information after you have deactivated your account for the period of time needed for
      Field to pursue legitimate business interests, conduct audits, comply with (and demonstrate compliance with) legal
      obligations, resolve disputes and enforce our agreements.
    </p>

    <h2>How we share and disclose information</h2>

    <p>
      This section describes how Field may share and disclose Information. Customers determine their own policies and
      practices for the sharing and disclosure of Information. Field does not control how they or any other third
      parties choose to share or disclose Information.
    </p>

    <ul>
      <li>
        <strong>The Customer’s instructions.</strong> Field will solely share and disclose Customer Data in accordance
        with a Customer’s instructions, including any applicable terms in the Customer Agreement and the Customer’s use
        of Services functionality and in compliance with applicable law and legal process.
      </li>

      <li>
        <strong>Displaying the Services.</strong> When an Authorised User submits Other Information, it may be displayed
        to other Authorised Users in the same or connected Workspaces. For example, an Authorised User’s email address
        may be displayed with their Workspace profile. Please consult the Help Centre for more information on Services
        functionality.
      </li>

      <li>
        <strong>Collaborating with others.</strong> The Services provide different ways for Authorised Users working in
        independent Workspaces to collaborate, such as Field Connect or email interoperability. Other Information, such
        as an Authorised User’s profile Information, may be shared, subject to the policies and practices of the other
        Workspace(s).
      </li>

      <li>
        <strong>Customer access.</strong> Owners, administrators, Authorised Users and other Customer representatives
        and personnel may be able to access, modify, or restrict access to Other Information. This may include, for
        example, your employer using Service features to export logs of Workspace activity, or accessing or modifying
        your profile details. For information about your Workspace settings, please see
        <a href="https://field.so/account/settings" target="_blank" rel="noreferrer">
          https://field.so/account/settings
        </a>
        .
      </li>

      <li>
        <strong>Third-Party service providers and partners.</strong> We may engage Third-Party companies or individuals
        as service providers or business partners to process Other Information and support our business. These third
        parties may, for example, provide virtual computing and storage services or we may share business information to
        develop strategic partnerships with Third-Party Service providers to support our common customers. Additional
        information about the subprocessors we use to support delivery of our Services is set out at Field
        subprocessors.
      </li>

      <li>
        <strong>Third-Party Services.</strong> The Customer may enable or permit Authorised Users to enable Third-Party
        Services. We require each Third-Party Service to disclose all permissions for information access in the
        Services, but we do not guarantee that they do so. When enabled, Field may share Other Information with
        Third-Party Services. Third-Party Services are not owned or controlled by Field and third parties that have been
        granted access to Other Information may have their own policies and practices for its collection, use and
        sharing. Please check the permissions, privacy settings and notices for these Third-Party Services or contact
        the provider for any questions.
      </li>

      <li>
        <strong>Corporate affiliates.</strong> Field may share Other Information with its corporate affiliates, parents
        and/or subsidiaries.
      </li>

      <li>
        <strong>During a change to Field’s business.</strong> If Field engages in a merger, acquisition, bankruptcy,
        dissolution, reorganisation, sale of some or all of Field’s assets or stock, financing, public offering of
        securities, acquisition of all or a portion of our business, a similar transaction or proceeding or steps in
        contemplation of such activities, some or all Other Information may be shared or transferred, subject to
        standard confidentiality arrangements.
      </li>

      <li>
        <strong>Aggregated or de-identified Data.</strong> We may disclose or use aggregated or de-identified Other
        Information for any purpose. For example, we may share aggregated or de-identified Other Information with
        prospects or partners for business or research purposes, such as telling a prospective Field customer the
        average amount of time spent within a typical Workspace.
      </li>

      <li>
        <strong>To comply with laws.</strong> If we receive a request for information, we may disclose Other Information
        if we reasonably believe disclosure is in accordance with or required by any applicable law, regulation or legal
        process. Please see the Data request policy to understand how Field responds to requests to disclose data from
        government agencies and other sources.
      </li>

      <li>
        <strong>To enforce our rights, prevent fraud and for safety.</strong> To protect and defend the rights, property
        or safety of Field or third parties, including enforcing contracts or policies, or in connection with
        investigating and preventing fraud or security issues.
      </li>

      <li>
        <strong>With consent.</strong> Field may share Other Information with third parties when we have consent to do
        so.
      </li>
    </ul>

    <h2>Data Processing and Security</h2>

    <p>
      When using Field, personal data may be processed. In terms of data protection legislation, the concept “personal
      data” refers to all information concerning an identified or identifiable person. An IP address may also be a
      personal date. An IP address is assigned by the internet provider to every device connected to the Internet, so
      that it is able to send and receive data. We process personal data in compliance with the relevant data protection
      provisions, in particular of the GDPR and the BDSG. Any data processing on our part occurs exclusively on the
      basis of legal authorization. We process personal data only for the fulfilment of a contract, to which you are a
      contracting party, or at your request for implementing pre-contractual measures (Art. 6 Par 1 b) GDPR), for
      compliance with a legal obligation (Art. 6 Par. 1 c) GDPR), or if the processing is necessary to secure our
      legitimate interests or the legitimate interests of a third party, insofar as your interests or fundamental rights
      and freedoms, which require the protection of personal data, do not override these (Art. 6 Par. 1 f) GDPR).
    </p>

    <p>
      Field takes security of data very seriously. Field works hard to protect Other Information you provide from loss,
      misuse and unauthorised access or disclosure. These steps take into account the sensitivity of the Other
      Information we collect, process and store and the current state of technology. Given the nature of communications
      and information processing technology, Field cannot guarantee that Information during transmission through the
      Internet or while stored on our systems or otherwise in our care will be absolutely safe from intrusion by others.
      When you click a link to a third-party site, you will be leaving our site and we don’t control or endorse what is
      on third-party sites.
    </p>

    <h2>Age limitations</h2>

    <p>
      To the extent prohibited by applicable law, Field does not allow use of our Services and Websites by anyone
      younger than 16 years old. If you learn that anyone younger than 16 has unlawfully provided us with personal data,
      please contact us and we will take steps to delete such information.
    </p>

    <h2>Changes to this Privacy Policy</h2>

    <p>
      Field may change this Privacy Policy from time to time. Laws, regulations and industry standards evolve, which may
      make those changes necessary, or we may make changes to our services or business. We will post the changes to this
      page and encourage you to review our Privacy Policy to stay informed. If we make changes that materially alter
      your privacy rights, Field will provide additional notice, such as via email or through the Services. If you
      disagree with the changes to this Privacy Policy, you should deactivate your Services account. Contact the
      Customer if you wish to request the removal of Personal Data under their control.
    </p>

    <h2>Data Protection Officer</h2>

    <p>
      To communicate with our Data Protection Officer, please email <a href="mailto:dpo@field.so">dpo@field.so</a>.
    </p>

    <h2>Identifying the Data Controller and Processor</h2>

    <p>
      Data protection law in certain jurisdictions differentiates between the “Controller” and “Processor” of
      information. In general, the Customer is the Controller of Customer Data. In general, Field is the Processor of
      Customer Data and the Controller of Other Information.
    </p>

    <h2>Your rights</h2>

    <p>
      Individuals located in certain countries, including the European Economic Area and the United Kingdom, have
      certain statutory rights in relation to their personal data. Subject to any exemptions provided by law, you may
      have the right to request access to Information, as well as to seek to update, delete or correct this Information.
      You can usually do this using the settings and tools provided in your Services account. If you cannot use the
      settings and tools, contact the Customer who controls your workspace for additional access and assistance. Please
      check{' '}
      <a href="https://field.so/account/settings" target="_blank" rel="noreferrer">
        https://field.so/account/settings
      </a>{' '}
      for Customer contact information.
    </p>

    <p>
      To the extent that Field’s processing of your Personal Data is subject to the General Data Protection Regulation,
      Field relies on its legitimate interests, described above, to process your data. Field may also process Other
      Information that constitutes your Personal Data for direct marketing purposes and you have a right to object to
      Field’s use of your Personal Data for this purpose at any time.
    </p>

    <h2>Contacting Field</h2>

    <p>
      Please also feel free to contact Field if you have any questions about this Privacy Policy or Field’s practices or
      if you are seeking to exercise any of your statutory rights. Field will respond within a reasonable timeframe. You
      can contact us at <a href="mailto:privacy@field.so">privacy@field.so</a> or at our postal address below:
    </p>

    <address>
      Product Field Works GmbH
      <br />
      Eimsbütteler Str. 119
      <br />
      22769 Hamburg
      <br />
      Germany
      <br />
    </address>
  </>
);
export default TermsContent;
