/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './Pagination.scss';

const MAX_PAGES = 18;

const Pagination = ({
  pages,
  currentPage,
  maxPages = MAX_PAGES,
  onClick,
  theme = 'dark',
  backgroundColor = 'gray-800',
  highlightColor = 'peach-crumble-bright',
  noFallback,
}) => {
  const currentPageIndex = pages.indexOf(currentPage);

  return (
    <div
      className={classNames('pagination', `pagination--${theme}`, {
        'pagination--no-fallback': noFallback,
        'pagination--show-fallback': !noFallback && pages.length > maxPages,
      })}
      style={{ '--highlight-color': `var(--${highlightColor})`, '--background-color': `var(--${backgroundColor})` }}
    >
      {pages.map((i) => (
        <button
          className={classNames('pagination__item', {
            'pagination__item--active': i === currentPage,
          })}
          type="button"
          onClick={() => onClick(i)}
          label={`Go to ${i}`}
          key={i}
        />
      ))}

      <div className="pagination__fallback">{currentPageIndex >= 0 && `${currentPageIndex + 1} / ${pages.length}`}</div>
    </div>
  );
};

export default Pagination;
