/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

// Calculate a SHA1 has over given string, and returns the hash as a hex string.
// See: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
export const hash = (v) => {
  const encoder = new TextEncoder();

  return crypto.subtle.digest('SHA-1', encoder.encode(JSON.stringify(v))).then((buf) => {
    return Array.from(new Uint8Array(buf))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  });
};
