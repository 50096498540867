/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';
import { paramCase } from 'param-case';

import './EntityLinkButton.scss';

/**
 * An Entity Link Button is a minimal representation of a Cue or a Group.
 *
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @param {() => {}} props.onClick
 * @param {'~none' | 'Product' | 'Person' | 'Question' | 'ActionItem' | 'Group'} [props.type = "~none"]
 * @param {"default" | "bright" | "minimal"} [props.theme = "default"]
 */
const EntityLinkButton = ({ children, onClick, theme, type = '~none', size }) => {
  return (
    <button
      onClick={onClick}
      className={classNames('entity-link-button', {
        [`entity-link-button--${paramCase(type)}`]: paramCase(type) !== 'group',
        'entity-link-button--group': paramCase(type) === 'group',
        [`entity-link-button--theme-${theme}`]: theme,
        [`entity-link-button--size-${size}`]: size,
      })}
      type="button"
    >
      {children}
    </button>
  );
};

export default EntityLinkButton;
