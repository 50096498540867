/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState } from 'react';

import classNames from 'classnames';

import Tooltip from '../Tooltip';

import './CharacterCountIndicator.scss';

const MINIMUM_OPACITY = 0.5;

const CharacterCountIndicator = ({ maxCharacters = 50, currentCharacters, message, forceShowTooltip }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const opacity = Math.max(MINIMUM_OPACITY, scale(currentCharacters / maxCharacters, 0, 1, -1, 1.5));

  return (
    <Tooltip
      asChild
      side="bottom"
      theme="dark"
      tooltipContent={<div className="character-count-indicator__tooltip-content">{message}</div>}
      open={showTooltip || forceShowTooltip || currentCharacters > maxCharacters * 0.85}
      onOpenChange={setShowTooltip}
    >
      <div
        className={classNames('character-count-indicator', {
          'character-count-indicator--too-long': currentCharacters > maxCharacters * 0.85 || forceShowTooltip,
        })}
      >
        <div className="character-count-indicator__count" style={{ opacity }}>
          {maxCharacters - currentCharacters}
        </div>

        <svg
          className="character-count-indicator__circle-chart"
          viewBox="0 0 39.83098862 39.83098862"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="character-count-indicator__circle-chart-background"
            strokeWidth="5"
            fill="none"
            cx="19.91549431"
            cy="19.91549431"
            r="15.91549431"
          />
          {currentCharacters > 0 && (
            <circle
              className="character-count-indicator__circle-chart-circle"
              strokeWidth="5"
              strokeDasharray={`${(currentCharacters / maxCharacters) * 100},100`}
              strokeLinecap="round"
              fill="none"
              cx="19.91549431"
              cy="19.91549431"
              r="15.91549431"
            />
          )}
        </svg>
      </div>
    </Tooltip>
  );
};

function scale(value, inMin, inMax, outMin, outMax) {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

export default CharacterCountIndicator;
