/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import { Icon } from '@fieldbyfield/components';

import './MetaDataTable.scss';

const MetaDataTable = ({ data }) => {
  return (
    <div className="meta-data-table">
      {data.map((d) => (
        <React.Fragment key={d.text}>
          <div>
            <Icon size="alpha" color="highlight-light" icon={d.icon} />
          </div>
          <div>{d.text}</div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default MetaDataTable;
