/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export class PublicationAPI {
  constructor({ request }) {
    this.request = request;
  }

  publishObject({ workspace, object, context = {} }) {
    return this.request(
      'PUT',
      `workspaces/${workspace.id}/publications`,
      {},
      {
        object,
        context,
      }
    );
  }

  resolveSecret({ secret }) {
    return this.request('GET', `publications/${secret}`);
  }

  unpublishObject({ workspace, object }) {
    return this.request(
      'DELETE',
      `workspaces/${workspace.id}/publications`,
      {},
      {
        object,
      }
    );
  }
}
