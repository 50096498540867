/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import {
  CUE_TYPE_ACTION_ITEM,
  CUE_TYPE_PERSON,
  CUE_TYPE_PRODUCT,
  CUE_TYPE_QUESTION,
} from '@fieldbyfield/product-field';

import ActionItemIcon from './Icons/ActionItemIcon.jsx';
import DefaultIcon from './Icons/DefaultIcon.jsx';
import GroupIcon from './Icons/GroupIcon.jsx';
import PersonIcon from './Icons/PersonIcon.jsx';
import ProductIcon from './Icons/ProductIcon.jsx';
import QuestionIcon from './Icons/QuestionIcon.jsx';
import UnlinkedProductIcon from './Icons/UnlinkedProductIcon.jsx';

import './EntityTypeIcon.scss';

export const ICON_TYPE_GROUP = 'Group';
export const ICON_TYPE_UNLINKED_PRODUCT = 'Product_unlinked';

const TYPE_COMPONENTS_MAPPINGS = {
  [CUE_TYPE_ACTION_ITEM]: ActionItemIcon,
  [CUE_TYPE_PERSON]: PersonIcon,
  [CUE_TYPE_PRODUCT]: ProductIcon,
  [CUE_TYPE_QUESTION]: QuestionIcon,
  [ICON_TYPE_GROUP]: GroupIcon,
  [ICON_TYPE_UNLINKED_PRODUCT]: UnlinkedProductIcon,
};

/**
 * An Entity Type Icon represents a type of entity.
 *
 * @param {Object} props
 * @param {'~none' | 'Product' | 'Product_unlinked' | 'Person' | 'Question' | 'ActionItem' | 'Group'} [props.type = "~none"]
 * @param {'alpha' | 'beta' | 'gamma' | 'delta' | 'epsilon' | 'zeta' | 'eta'} [props.size = "beta"]
 * @param {"default" | "bright" | "minimal"} [props.theme = "bright"]
 */
const CueTypeIcon = ({ type = '~none', size = 'beta', theme = 'bright' }) => {
  const IconComponent = TYPE_COMPONENTS_MAPPINGS[type] || DefaultIcon;

  return <IconComponent size={size} theme={theme} />;
};

export default React.memo(CueTypeIcon);
