/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export const ELEMENT_IDEA = 'I D E A';
export const ELEMENT_VALUE = 'V A L U E';
export const ELEMENT_MARKET = 'M A R K E T';
export const ELEMENT_RESOURCES = 'R E S O U R C E S';

// prettier-ignore
export const ALL_ELEMENTS = [
  ELEMENT_IDEA,
  ELEMENT_VALUE,
  ELEMENT_MARKET,
  ELEMENT_RESOURCES,
];

/**
 * get the element at a specific position
 * as we always want to return a result:
 *  - points where y == 0 are mapped to the upper half
 *    of the field (IDEA, VALUE)
 *  - points where x == 0 are mapped to the left half
 *    of the field (IDEA, RESOURCES)
 */
export function getElementAtPoint([x, y]) {
  if (y === 0) {
    y--;
  }
  if (x === 0) {
    x--;
  }
  if (x < 0 && y / x > 0) {
    return ELEMENT_IDEA;
  }
  if (x < 0 && y / x < 0) {
    return ELEMENT_RESOURCES;
  }
  if (x > 0 && y / x > 0) {
    return ELEMENT_MARKET;
  }
  if (x > 0 && y / x < 0) {
    return ELEMENT_VALUE;
  }
}
