/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import { KeyVisual } from '@fieldbyfield/components';

import './DefaultLayout.scss';

const DefaultLayout = ({ children, headerAccessory, theme }) => {
  return (
    <>
      <header className="default-layout-header grid">
        <div className="default-layout-header__background" />
        <span className="default-layout-header__logo">Field</span>
        {headerAccessory && <div className="default-layout-header__accessory">{headerAccessory}</div>}
      </header>
      <main className="default-layout grid">
        {children}

        <div className={classNames('default-layout__ornament', `default-layout__ornament--${theme}`)}>
          <KeyVisual fill vertical />
        </div>
      </main>
    </>
  );
};

export default DefaultLayout;
