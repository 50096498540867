/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';

import classNames from 'classnames';

import './TabBar.scss';

/**
 * A TabBar lets users switch between multiple panels of content,
 * of which only one is visible at a given moment.
 *
 * This component does not manage the content of the Tabs, only
 * the TabBar.
 *
 * @param {Object} props
 * @param {[{id: number | string, title: string}]} props.tabs
 * @param {({activeTabId}) => void} props.onChange
 * @param {number | string} props.activeTabId
 */
const TabBar = ({ tabs, onChange, activeTabId, theme = 'default', Link }) => {
  const [indicatorPosition, setIndicatorPosition] = useState([0, 0]);
  const [activeTab, setActiveTab] = useState(null);

  const refs = useRef([]);

  function handleKeyEvent(ev) {
    const key = ev.keyCode;
    const tabIndex = tabs.indexOf(activeTab);
    let nextIndex;

    switch (key) {
      case 37:
        // left
        if (tabIndex > 0) {
          nextIndex = tabIndex - 1;
        } else {
          nextIndex = tabs.length - 1;
        }
        break;
      case 39:
        // right
        if (tabs.length > tabIndex + 1) {
          nextIndex = tabIndex + 1;
        } else {
          nextIndex = 0;
        }
        break;
      case 35:
        // end
        nextIndex = tabs.length - 1;
        ev.preventDefault();
        break;
      case 36:
        // home
        nextIndex = 0;
        ev.preventDefault();
        break;
      default:
        break;
    }

    if (nextIndex !== undefined) {
      onChange({ selectedTabId: tabs[nextIndex].id });
      refs.current[nextIndex].focus();
    }
  }

  useEffect(() => {
    setActiveTab(tabs.find((t) => t.id === activeTabId));
  }, [tabs, activeTabId]);

  useLayoutEffect(() => {
    let offsetLeft = 0;
    let width = 0;

    if (activeTab) {
      const tabIndex = tabs.indexOf(activeTab);
      const element = refs.current[tabIndex];

      if (!element) {
        return;
      }

      offsetLeft = element.offsetLeft;
      width = element.getBoundingClientRect().width;
    }

    setIndicatorPosition([offsetLeft, width]);
  }, [refs, tabs, activeTab]);

  return (
    <nav className={classNames('tab-bar', `tab-bar--theme-${theme}`)}>
      {tabs.map((t, i) => {
        let element = (
          <button
            className={classNames('tab-bar__tab', {
              'tab-bar__tab--active': t.id === activeTabId,
            })}
            onClick={(ev) => {
              ev.preventDefault();
              onChange({ selectedTabId: t.id });
            }}
            key={t.id}
            role="tab"
            aria-selected={t.id === activeTabId}
            tabIndex={t.id === activeTabId ? 0 : -1}
            onKeyDown={handleKeyEvent}
            ref={(e) => {
              refs.current[i] = e;
            }}
            type="button"
          >
            <div className="tab-bar__tab-title-sizer" aria-hidden>
              {t.title}
            </div>
            <div className="tab-bar__tab-title">{t.title}</div>
          </button>
        );

        if (t.to && Link) {
          element = (
            <Link
              className={classNames('tab-bar__tab', {
                'tab-bar__tab--active': t.id === activeTabId,
              })}
              onClick={() => {
                onChange({ selectedTabId: t.id });
              }}
              to={t.to}
              role="tab"
              aria-selected={t.id === activeTabId}
            >
              <div className="tab-bar__tab-title-sizer" aria-hidden>
                {t.title}
              </div>
              <div className="tab-bar__tab-title">{t.title}</div>
            </Link>
          );
        }

        return element;
      })}

      <div className="tab-bar__indicator" style={{ left: indicatorPosition[0] + 3, width: indicatorPosition[1] - 6 }} />
    </nav>
  );
};

export default TabBar;
