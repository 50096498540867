/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import scrub from './scrubber';

const attachmentURLFromDSN = (dsn, eventId) => {
  const { host, path, projectId, port, protocol, user } = dsn;
  return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
    path !== '' ? `/${path}` : ''
  }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;
};

const crash = {
  haveConsent: () => {
    return true;
  },

  // Enables crash reporting optionally with tracing and
  // support for react router.
  //
  // Usage:
  //   const history = createBrowserHistory();
  //   crash.enable({ history, context: 'prod', trace: false });
  //   ...
  //   <Router history={history}>
  //     ...
  //   </Router>
  enable: ({ history, trace, context, store }) => {
    const integrations = [];

    if (trace) {
      if (history) {
        // Assume all apps use the same react router version.
        // See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        integrations.push(
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          })
        );
      } else {
        integrations.push(new Integrations.BrowserTracing());
      }
    }

    Sentry.init({
      dsn: process.env.SENTRY_DSN,

      environment: context,

      integrations,

      // How deep we want to send the app state
      normalizeDepth: 3,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,

      // Don't send anything to sentry if we don't have consent
      beforeBreadcrumb: (event) => {
        if (!crash.haveConsent()) {
          return null;
        }

        return event;
      },
      beforeSend: (event) => {
        if (!crash.haveConsent()) {
          return null;
        }

        return event;
      },
    });

    // Upload redux-state to attachments on error. We use the hint object to figure out, if it's an
    // event. See: https://docs.sentry.io/platforms/node/configuration/filtering/#hints-for-events
    if (store) {
      Sentry.addGlobalEventProcessor(async (event, { originalException, syntheticException }) => {
        if (originalException || syntheticException) {
          try {
            const client = Sentry.getCurrentHub().getClient();
            const endpoint = attachmentURLFromDSN(client.getDsn(), event.event_id);
            const formData = new FormData();
            formData.append(
              'redux-state',
              new Blob([JSON.stringify(scrub(store.getState()))], {
                type: 'application/json',
              }),
              `redux-state-${event.event_id}.json`
            );
            await fetch(endpoint, {
              method: 'POST',
              body: formData,
            });
          } catch (e) {
            // We have to catch this otherwise it throws an infinite loop in Sentry.
            console.error(e);
          }
        }
        return event;
      });
    }
  },
  setUser: ({ id }) => {
    Sentry.setUser({ id });
  },

  // Add crash reporting support for redux.
  //
  // See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
  //
  // Usage:
  //   const store = configureStore({
  //     reducer,
  //     ...
  //     enhancers: [crash.reduxEnhancer()]
  //   });
  reduxEnhancer: () => {
    return Sentry.createReduxEnhancer({
      actionTransformer: ({ payload, ...action }) => {
        // Sentry add a payload key here, even if payload is undefined.
        return scrub(payload ? { ...action, payload } : action);
      },
      stateTransformer: () => {
        return null;
      },
    });
  },

  // Provides direct access to the Sentry library object. Usually on of the
  // above methods should be used to add crash reporting to an app. As we better
  // centralize configuration here.
  sentry() {
    return Sentry;
  },
};

export default crash;
