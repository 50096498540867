/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useLayoutEffect } from 'react';

import { Route as ReactRouterDomRoute, useRouteMatch } from 'react-router-dom';

const Route = ({ onEnter, children, path, ...routeParams }) => {
  const match = useRouteMatch(path);

  useLayoutEffect(() => {
    if (typeof onEnter === 'function' && match) {
      onEnter(match);
    }
  }, [onEnter, match]);

  return (
    <ReactRouterDomRoute path={path} {...routeParams}>
      {children}
    </ReactRouterDomRoute>
  );
};

export default Route;
