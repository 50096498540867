/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';
import './RadioButtonList.scss';

/**
 * A Radio Button List lets the user select a single option from two to five offered choices in the context of a classic form.
 *
 * @param {Object} props
 * @param {[{ title: string, value: string, selected: boolean }]} props.options
 * @param {([{ title: string, value: string, selected: boolean }]) => void} props.onChange
 * @param {string} props.name
 * @param {string} props.labelId The entire RadioButtonList should be labeld by a FormElement. Its id needs to be passed here.
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.invalid]
 * @param {boolean} [props.light]
 */
const RadioButtonList = ({ options, disabled, invalid, onChange, labelId, name, light }) => {
  function changeEvent(ev) {
    onChange(
      options.map((o) => {
        if (o.value === ev.target.value || (!o.value && o.title === ev.target.value)) {
          return { ...o, selected: true };
        }
        return { ...o, selected: false };
      })
    );
  }

  return (
    <div
      className={classNames('radio-button-list', {
        'radio-button-list--disabled': disabled,
        'radio-button-list--invalid': invalid,
        'radio-button-list--light': light,
      })}
      role="radiogroup"
      aria-labelledby={labelId}
      data-testid="radio-button-list"
    >
      {options.map((o, i) => {
        const id = `${labelId}-option-${i}`;
        return (
          <label className="radio-button-list-button" key={o.title} htmlFor={id}>
            <input
              id={id}
              className="radio-button-list-button__input"
              type="radio"
              name={name}
              value={o.value || o.title}
              checked={o.selected || false}
              onChange={changeEvent}
              disabled={disabled}
            />
            <div className="radio-button-list-button__indicator" />
            {o.title}
          </label>
        );
      })}
    </div>
  );
};

export default RadioButtonList;
