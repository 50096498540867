/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import LoadingSpinner from '../LoadingSpinner';

import './LoadingView.scss';

const DEFAULT_LOADING_VIEW_INDICATOR_DELAY = 200;
const LoadingView = ({ children, theme, delay = DEFAULT_LOADING_VIEW_INDICATOR_DELAY, noAnimation }) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    // In order not to have flashing loading messages, we only display one if the loading takes longer than 200ms
    const t = setTimeout(() => {
      setShowLoading(true);
    }, delay);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let spinnerTheme = 'default';

  switch (theme) {
    case 'public':
      spinnerTheme = 'light';
      break;
    case 'highlight':
      spinnerTheme = 'dark';
      break;
    default:
      break;
  }

  return (
    <div
      className={classNames('loading-view', {
        'loading-view--show': showLoading || noAnimation,
        [`loading-view--${theme}`]: !!theme,
      })}
    >
      <div className="loading-view__content">
        <LoadingSpinner theme={spinnerTheme} />

        <div className="loading-view__text">{children || 'Loading …'}</div>
      </div>
    </div>
  );
};

export default LoadingView;
