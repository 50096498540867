/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

export const ERROR_TYPE = {
  App: 'APP_ERROR', // Errors that arise from within our apps.
  API: 'API_ERROR', // Errors from interactions with our own API.
  VendorAPI: 'VENDOR_API_ERROR', // Errors from interactions with APIs of 3rd party providers.
  Unknown: 'UNKOWN_ERROR',
};

// Generates an error ID string.
export function generateErrorID() {
  return `ERR${Date.now().toString(36).toUpperCase()}`;
}

// See https://github.com/facebook/react/issues/14981#issuecomment-468460187
export const useAsyncError = () => {
  const [, setError] = React.useState();
  return React.useCallback(
    (e) => {
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};
