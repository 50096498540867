/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import Icon from '../Icon';
import Link from '../Link';

import './ErrorPage.scss';

const ErrorPage = ({ errorId, path }) => {
  const supportMailBody = encodeURIComponent(`[Please describe the issue in a few words.]

---

Error ID: ${errorId || '<not available>'}
Active path: ${path || '<not available>'}
`);

  return (
    <div className="error-page__error" data-testid="error">
      <div className="error-page__error-message">
        <Icon color="stop" icon="warning" size="delta" />
        <div>
          Unfortunately an error has occured.
          <div className="error-page__try">Please try to reload the page to see if that helps.</div>
          <div className="error-page__support">
            For further help please&nbsp;
            <Link href={`mailto:support@field.so?subject=Issue:%20Error%20${errorId}&body=${supportMailBody}`}>
              contact support to report the issue
            </Link>
            .&nbsp;
            {errorId && (
              <>
                The error code <span data-testid="error-id">{errorId}</span> has been automatically associated with this
                error. You can help us to resolve the issue by providing the code with your support request.
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
