/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { Component } from 'react';

import { ClientError } from '@fieldbyfield/client';
import { ErrorPage } from '@fieldbyfield/components';

import crash, { ERROR_TYPE, generateErrorID } from '../../crash';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,

      // Debug Information...
      derivedErrorId: null,
      generatedErrorId: generateErrorID(), // Assume ErrorBoundary catches only once.

      path: window.location.pathname,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, derivedErrorId: error.id };
  }

  componentDidCatch(error, errorInfo) {
    const { derivedErrorId, generatedErrorId } = this.state;

    const extra = {
      id: derivedErrorId || generatedErrorId,
      type: ERROR_TYPE.Unknown,
    };

    if (error instanceof ClientError) {
      extra.type = ERROR_TYPE.API;
      extra.http_status = error.http_status;
    }

    crash.sentry().captureException(error, {
      extra: {
        ...extra,
        ...errorInfo,
      },
    });
  }

  render() {
    const { hasError, derivedErrorId, generatedErrorId, path } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPage errorId={derivedErrorId || generatedErrorId} path={path} />;
    }
    return children;
  }
}

export default ErrorBoundary;
