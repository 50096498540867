/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useRef, useEffect } from 'react';

import className from 'classnames';

import AutogrowingInput from '../AutogrowingInput';
import Icon from '../Icon';

import './InspirationList.scss';

let eventListener;

const InspirationItem = ({ custom, id, text, onChange, onDelete, add, readOnly, theme }) => {
  const inputRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleKey = (ev) => {
    if (ev.key === 'Enter') {
      inputRef.current.blur();
      ev.preventDefault();
    }

    if (ev.key === 'Escape') {
      // This works because the event captures the state of value
      // when it is added (=> at the start of editing)
      setValue(value);
      inputRef.current.blur();
    }

    if (ev.key === 'Backspace') {
      if (ev.target.value === '') {
        inputRef.current.blur();
        onDelete(id);
      }
    }
  };

  const handleFocus = () => {
    eventListener = handleKey;
    document.addEventListener('keydown', eventListener);
  };

  const handleBlur = () => {
    document.removeEventListener('keydown', eventListener);

    setIsEditing(false);

    if (value !== '') {
      onChange({
        id,
        text: value,
      });

      if (add) {
        setValue('');
      }
    } else {
      onDelete(id);
    }
  };

  return (
    <div
      className={className('inspiration-item', {
        'inspiration-item--custom': custom,
        'inspiration-item--add': add,
        'inspiration-item--is-editing': isEditing,
      })}
      onDoubleClick={() => {
        if (inputRef.current) {
          setIsEditing(true);
          inputRef.current.focus();
        }
      }}
    >
      {(custom || add) && (
        <div className="inspiration-item__input">
          <AutogrowingInput
            value={value || ''}
            readOnly={readOnly || !isEditing}
            placeholder="Your Inspiration"
            onChange={(ev) => {
              ev.stopPropagation();
              setValue(ev.target.value);
            }}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={inputRef}
          />
        </div>
      )}
      {!custom && !add && <div className="inspiration-item__label">{text}</div>}
      {!readOnly && custom && !isEditing && (
        <button
          className="inspiration-item__button"
          onClick={() => {
            setIsEditing(true);
            inputRef.current.focus();
          }}
          type="button"
        >
          <Icon icon="edit" size="alpha" color={theme === 'yellow' ? 'lemon-tarte-dark' : 'gray-700'} />
        </button>
      )}
      {!readOnly && custom && isEditing && value !== '' && (
        <button
          className="inspiration-item__button"
          onClick={() => {
            setIsEditing(false);
          }}
          type="button"
        >
          <Icon icon="check" size="alpha" color={theme === 'yellow' ? 'lemon-tarte-dark' : 'gray-700'} />
        </button>
      )}
      {!readOnly && custom && isEditing && value === '' && (
        <button
          className="inspiration-item__button"
          onClick={() => {
            setIsEditing(false);
          }}
          type="button"
        >
          <Icon
            icon="trash"
            size="alpha"
            color={theme === 'yellow' ? 'lemon-tarte-dark' : 'gray-700'}
            onClick={() => {
              onDelete(id);
            }}
          />
        </button>
      )}
      {!readOnly && add && (
        <button
          className="inspiration-item__button"
          onClick={() => {
            setIsEditing(true);
            inputRef.current.focus();
          }}
          type="button"
        >
          <Icon icon="plus" size="alpha" color="gray-700" />
        </button>
      )}
    </div>
  );
};

const InspirationList = ({ inspirations, onAddItem, onDeleteItem, onChangeItem, readOnly, theme = 'default' }) => {
  return (
    <div className={className('inspiration-list', `inspiration-list--${theme}`)}>
      {inspirations.map((i) => {
        return (
          <InspirationItem
            {...i}
            key={i.id}
            onChange={onChangeItem}
            onDelete={onDeleteItem}
            readOnly={readOnly}
            theme={theme}
          />
        );
      })}

      {!readOnly && (
        <InspirationItem
          key={0}
          add
          onChange={(i) => {
            if (i.text && i.text !== '') {
              onAddItem(i.text);
            }
          }}
          onDelete={() => {}}
          theme={theme}
        />
      )}
    </div>
  );
};

export default InspirationList;
