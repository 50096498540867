/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

// eslint-disable-next-line import/prefer-default-export
export class ClientError extends Error {
  constructor(message, info) {
    super(message);
    this.info = info;
  }

  get id() {
    return this.info.id;
  }

  get http_status() {
    return this.info.http_status;
  }
}
