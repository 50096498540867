/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useRef, useEffect, useCallback } from 'react';

import classNames from 'classnames';

import Menu from './Menu.jsx';

import './ContextMenu.scss';

/**
 * @typedef MenuItemButton
 * @property {string} title
 * @property {CONTEXT_MENU_ITEM_TYPES.button} type
 * @property {() => {}} onClick
 * @property {string} [subtitle]
 * @property {boolean} [disabled]
 * @property {boolean} [hidden]
 * @property {string} [icon]
 * @property {string} [iconColor]
 */

/**
 * @typedef MenuItemSelect
 * @property {string} title
 * @property {CONTEXT_MENU_ITEM_TYPES.select} type
 * @property {(value: string) => {}} onChange
 * @property {[{title: string, value: string, selected: boolean, disabled: boolean, hidden: boolean, icon: string, checkmarkColor: string }]} options
 * @property {boolean} [hidden]
 * @property {string} [icon]
 * @property {string} [iconColor]
 */

/**
 * @typedef MenuItemSeparator
 * @property {CONTEXT_MENU_ITEM_TYPES.separator} type
 */

/**
 * @typedef MenuItemSubmenu
 * @property {string} title
 * @property {CONTEXT_MENU_ITEM_TYPES.menu} type
 * @property {[MenuItemButton | MenuItemSelect | MenuItemSeparator | MenuItemSubmenu]} children
 * @property {boolean} [disabled]
 * @property {boolean} [hidden]
 * @property {string} [icon]
 * @property {string} [iconColor]
 * @property {boolean} [wide]
 */

/**
 * A Context Menu shows additional actions conditionally, for example after the user right-clicked on an element.
 *
 * @param {Object} props
 * @param {[MenuItemButton | MenuItemSelect | MenuItemSeparator | MenuItemSubmenu]} props.menuItems
 * @param {() => {}} props.onDismiss
 */
const ContextMenu = ({ menuItems, onDismiss }) => {
  if (!menuItems) {
    menuItems = [];
  }

  const wrapperRef = useRef(null);

  const handleClick = useCallback(
    (ev) => {
      if (wrapperRef.current && !wrapperRef.current.contains(ev.target)) {
        ev.stopPropagation();

        if (onDismiss) {
          onDismiss();
        }
      }
    },
    [onDismiss]
  );

  const handleKey = useCallback(() => {
    // if (ev.key !== 'Tab' && ev.key !== ' ' && ev.key !== 'Enter' && ev.key !== 'Shift') {
    //   if (onDismiss) {
    //     onDismiss();
    //   }
    // }
  }, []);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('keyup', handleKey);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keyup', handleKey);
    };
  }, [handleClick, handleKey]);

  return (
    <div className={classNames('context-menu-wrapper')} ref={wrapperRef}>
      <Menu menu={menuItems} />
    </div>
  );
};

export default ContextMenu;
