/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { createAction, createReducer, createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import { Client } from '@fieldbyfield/client';

export const fetchUser = createAsyncThunk('user/fetch', (_, { getState }) => {
  const client = new Client({
    base: `${process.env.REACT_APP_CALLS_EXTERNAL_URL}/v0`,
    token: getState().auth.token,
  });
  return client.basic.me();
});

export const fetchSurvey = createAsyncThunk(
  'survey/fetch',
  async ({ workspace, survey }, { getState }) => {
    const client = new Client({
      base: `${process.env.REACT_APP_CALLS_EXTERNAL_URL}/v0`,
      token: getState().auth.token,
    });
    const response = await client.survey.getSurvey({
      workspace,
      survey,
    });
    const questions = await client.survey.getQuestions({
      workspace,
      survey,
    });
    return { ...response, questions };
  },
  {
    condition: ({ workspace, survey }, { getState }) => {
      const { auth } = getState();
      return auth.token !== null && workspace.id !== null && survey.id !== null;
    },
  }
);
export const submitSurvey = createAsyncThunk(
  'survey/submit',
  ({ workspace, survey }, { getState }) => {
    const { auth, questions } = getState();

    const reqs = [];

    const client = new Client({
      base: `${process.env.REACT_APP_CALLS_EXTERNAL_URL}/v0`,
      token: auth.token,
    });

    questions.forEach((question) => {
      question.cues.forEach((answer) => {
        reqs.push(
          client.survey.addAnswer({
            workspace,
            survey,
            question,
            answer: {
              text: answer.text,
              published: answer.date,
            },
          })
        );
      });
    });

    /*
    for (const question of questions) {
      // Inspiration updates of the questions
      for (const inspiration of question.inspirations.filter((v) => v.custom === true)) {
        reqs.push(
          client.survey.addQuestionInspiration({
            workspace,
            survey,
            question,
            inspiration: {
              text: inspiration.text,
            },
          })
        );
      }
    }
    */

    return Promise.all(reqs);
  },
  {
    condition: ({ workspace, survey }, { getState }) => {
      const { auth } = getState();
      return auth.token !== null && workspace.id !== null && survey.id !== null;
    },
  }
);

export const addCue = ({ question, text }) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      const id = nanoid();

      dispatch(addCueLocal({ question, text, id }));
      resolve({ id });
    });
  };
};

export const setAuthToken = createAction('auth/setToken');
const addCueLocal = createAction('cue/add');
export const deleteCue = createAction('cue/delete');
export const updateCue = createAction('cue/update');
export const anonymizeCue = createAction('cue/anonymize');
export const deanonymizeCue = createAction('cue/deanonymize');
export const updateNewCue = createAction('newCue/update');
export const addInspiration = createAction('inspiration/add');
export const deleteInspiration = createAction('inspiration/delete');
export const updateInspiration = createAction('inspiration/update');
export const setRemainingTimeboxTime = createAction('timebox/setRemaining');
export const setTotalTimeboxTime = createAction('timebox/setTotal');
export const setTimeboxIsRunning = createAction('timebox/setIsRunning');
export const setTimeboxIsActive = createAction('timebox/setIsActive');

export const reducer = createReducer([], {
  [setAuthToken]: (state, action) => {
    state.auth.token = action.payload;
  },
  [fetchUser.fulfilled]: (state, action) => {
    state.user.id = action.payload.id;
    state.user.name = action.payload.name;
    state.user.email = action.payload.email;
  },
  [fetchUser.rejected]: (state) => {
    state.ui.authError = true;
  },
  [fetchSurvey.fulfilled]: (state, action) => {
    state.survey.isLoaded = true;
    state.survey.workspace = action.meta.arg.workspace.id;
    state.survey.id = action.meta.arg.survey.id;

    state.introduction = {
      subject: action.payload.subject,
      description: action.payload.description,
      moderator: action.payload.moderator,
    };

    state.product = {
      name: action.payload.product_title,
      info: action.payload.product_description,
    };

    // Before reloading we save the user’s unsubmitted data in localStorage
    // here we try to retrieve it and check if it is actaully data for
    // this specific survey. If yes, we use it to preload the questions,
    // so users don’t lose their work when accidentally reloading or leaving
    // the page.
    const serializedState = localStorage.getItem('state');
    const localState = JSON.parse(serializedState);
    const localQuestions = {};

    if (
      localState &&
      localState.data &&
      localState.data.workspace === action.meta.arg.workspace.id &&
      localState.data.survey === action.meta.arg.survey.id
    ) {
      localState.data.questions.forEach((q) => {
        localQuestions[q.id] = q;
      });
    }

    const questions = action.payload.questions
      .filter((question) => {
        return question.is_active;
      })
      .sort((a, b) => a.position - b.position) // sort by position ascending
      .map((question) => {
        const localQuestion = localQuestions[question.id];
        const localCues = localQuestion?.cues || [];
        const localInspirations = localQuestion?.inspirations || [];

        return {
          id: question.id,
          aspect: question.aspect,
          text: question.text,
          cues: [...localCues],
          newCue: localQuestion?.newCue || '',
          inspirations: [
            ...(question.inspirations || []).map((text) => {
              return { id: nanoid(), text, custom: false };
            }),
            ...localInspirations,
          ],
          position: question.position,
        };
      });
    state.questions = questions;
  },
  [fetchSurvey.rejected]: (state) => {
    state.ui.authError = true;
  },
  // TODO: Retrieve answers to questions, and add them otherwise
  //       the answers will be empty after submission. Maybe not yet
  //       a problem, if the user doesn't see the survey after submission.
  [submitSurvey.fulfilled]: (state) => {
    state.survey.isSubmitted = true;
    state.questions = state.questions.map((q) => ({
      ...q,
      cues: [],
      inspirations: q.inspirations.filter((i) => !i.custom),
    }));
  },
  [setTotalTimeboxTime]: (state, action) => {
    state.user.timeboxTotal = action.payload;
    state.user.timeboxRemaining = action.payload;
  },
  [setRemainingTimeboxTime]: (state, action) => {
    state.user.timeboxRemaining = action.payload;
  },
  [setTimeboxIsRunning]: (state, action) => {
    state.user.timeboxIsRunning = action.payload;
  },
  [setTimeboxIsActive]: (state, action) => {
    state.user.timeboxIsActive = action.payload;
  },
  [addCueLocal]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.cues.push({
      id: action.payload.id || nanoid(),
      text: action.payload.text,
      date: Date.now(),
    });
  },
  [deleteCue]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.cues = question.cues.filter((i) => i.id !== action.payload.id);
  },
  [updateCue]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.cues = question.cues.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, text: action.payload.text };
      }

      return item;
    });
  },
  [anonymizeCue]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.cues = question.cues.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, anonymized: true };
      }

      return item;
    });
  },
  [deanonymizeCue]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.cues = question.cues.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, anonymized: false };
      }

      return item;
    });
  },
  [updateNewCue]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.newCue = action.payload.text;
  },
  [addInspiration]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.inspirations.push({
      id: nanoid(),
      text: action.payload.text,
      custom: true,
      date: Date.now(),
    });
  },
  [deleteInspiration]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.inspirations = question.inspirations.filter((i) => i.id !== action.payload.id);
  },
  [updateInspiration]: (state, action) => {
    const question = state.questions.find((q) => q.id === action.payload.question);
    question.inspirations = question.inspirations.map((item) => {
      if (item.id === action.payload.id) {
        return { ...item, text: action.payload.text };
      }

      return item;
    });
  },
});
