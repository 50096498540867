/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import {
  ASPECT_PRODUCT,
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
  ASPECT_PROBLEM,
  ASPECT_SOLUTION,
  ASPECT_UNIQUENESS,
  ASPECT_ALTERNATIVES,
} from './aspect.js';

// The area of the set's single aspect is in the center.
// prettier-ignore
export const SET_CENTER = [
  ASPECT_PRODUCT,
];

// The areas of these aspects are in the inner square.
// prettier-ignore
export const SET_CORE = [
  ASPECT_PROBLEM,
  ASPECT_SOLUTION,
  ASPECT_UNIQUENESS,
  ASPECT_ALTERNATIVES,
];

// The areas of these aspects are towards the outside.
// prettier-ignore
export const SET_CONTEXT = [
  ASPECT_MOTIVATIONS,
  ASPECT_USERS,
  ASPECT_CUSTOMERS,
  ASPECT_DISTRIBUTION,
  ASPECT_PRODUCTION,
  ASPECT_ENABLERS,
  ASPECT_DRIVERS,
  ASPECT_GOALS,
];

// prettier-ignore
export const ALL_SETS = [
  SET_CENTER,
  SET_CORE,
  SET_CONTEXT,
];
