/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { createContext, useContext } from 'react';

import * as Sentry from '@sentry/react';

import crash from './config.js';

const ErrorTrackingContext = createContext(crash);
ErrorTrackingContext.displayName = 'ErrorTrackingContext';

export const useErrorTrackingContext = () => {
  return useContext(ErrorTrackingContext);
};

export const withErrorTracking = (App, { enabled, history, trace, context, store }) => {
  const ErrorTracking = () => {
    if (enabled) {
      crash.enable({ history, trace, context, store });
    }

    return (
      <ErrorTrackingContext.Provider value={crash}>
        <App />
      </ErrorTrackingContext.Provider>
    );
  };

  return trace ? Sentry.withProfiler(ErrorTracking) : ErrorTracking;
};
