/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './Radar.scss';

const DEFAULT_SIZE = 140;
const DEFAULT_DURATION = 15;

const Radar = ({ theme, size = DEFAULT_SIZE, duration = DEFAULT_DURATION }) => {
  const style = {
    '--size': size,
    '--duration': duration,
  };

  return (
    <div
      className={classNames('radar', {
        [`radar--${theme}`]: theme,
      })}
      style={style}
    >
      <div className="radar__indicator--1" />
      <div className="radar__indicator--2" />
      <div className="radar__indicator--3" />
    </div>
  );
};

export default Radar;
