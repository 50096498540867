/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

import './Dropdown.scss';

export const DROPDOWN_OPTION_SEPARATOR = 'SEPARATOR';

/**
 * A Dropdown lets the user select a single option from a pulldown menu.
 *
 * @param {Object} props
 * @param {[{ title: string, value: string | DROPDOWN_OPTION_SEPARATOR, selected: boolean }]} props.options
 * @param {([{ title: string, value: string, selected: boolean }]) => void} props.onChange
 * @param {string} props.name
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.invalid]
 * @param {boolean} [props.light]
 * @param {string} [props.placeholder]
 */
const Dropdown = ({ options, disabled, invalid, onChange, name, light, placeholder }) => {
  function changeEvent(ev) {
    onChange(
      options.map((o) => {
        if (`${o.value}` === ev.target.value || (!o.value && o.title === ev.target.value)) {
          return { ...o, selected: true };
        }
        return { ...o, selected: false };
      })
    );
  }

  const selected = options.find((o) => o.selected);

  return (
    <div
      className={classNames('dropdown', {
        'dropdown--disabled': disabled,
        'dropdown--invalid': invalid,
        'dropdown--light': light,
      })}
    >
      <select
        className="dropdown__select"
        onChange={changeEvent}
        disabled={disabled}
        name={name}
        id={name}
        value={selected ? selected.value || selected.title : ''}
        data-testid="dropdown"
        required
      >
        {placeholder && (
          <option value="" hidden disabled>
            {placeholder}
          </option>
        )}
        {options.map((o, i) => {
          if (o.value === DROPDOWN_OPTION_SEPARATOR || o.title === DROPDOWN_OPTION_SEPARATOR) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <option disabled key={`separator-${i}`}>
                ──────────
              </option>
            );
          }

          return (
            <option value={o.value === undefined ? o.title : o.value} key={o.value || o.title}>
              {o.title || o.value}
            </option>
          );
        })}
      </select>
      <div className="dropdown__icon">
        <Icon icon="arrow-down" size="alpha" />
      </div>
    </div>
  );
};

export default Dropdown;
