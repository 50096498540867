/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

const extractInitials = (name) => {
  if (!name) {
    return '';
  }

  const initials = name
    .split(' ')
    .map((p) => (p[0] ? p[0].toUpperCase() : p[0]))
    .join('');

  if (initials.length > 2) {
    return `${initials[0]}${initials[initials.length - 1]}`;
  }

  return initials;
};

const FallbackImage = ({ name, id }) => {
  const initials = name ? extractInitials(name) : '?';

  return (
    <svg
      className={classNames('user-avatar__fallback', `user-avatar__fallback--${id % 10}`)}
      height="100%"
      width="100%"
      viewBox="0 0 50 50"
    >
      <text x="50%" y="54%" dominantBaseline="middle" textAnchor="middle">
        {initials}
      </text>
    </svg>
  );
};

export default FallbackImage;
