/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useRef, useEffect } from 'react';

import className from 'classnames';

import AutogrowingInput from '../AutogrowingInput';
import Icon from '../Icon';

import './TagList.scss';

let eventListener;

const Tag = ({ text, id, onChange, onDelete, isAddButton, readOnly }) => {
  const inputRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleKey = (ev) => {
    if (ev.key === 'Enter') {
      inputRef.current.blur();
      ev.preventDefault();
    }

    if (ev.key === 'Escape') {
      // This works because the event captures the state of value
      // when it is added (=> at the start of editing)
      setValue(value);
      inputRef.current.blur();
    }

    if (ev.key === 'Backspace') {
      if (ev.target.value === '') {
        inputRef.current.blur();
        onDelete(id);
      }
    }
  };

  const handleFocus = () => {
    eventListener = handleKey;
    document.addEventListener('keydown', eventListener);
  };

  const handleBlur = () => {
    document.removeEventListener('keydown', eventListener);

    setIsEditing(false);

    if (value !== '') {
      onChange({
        id,
        text: value,
      });

      if (isAddButton) {
        setValue('');
      }
    } else {
      onDelete(id);
    }
  };

  return (
    <div
      className={className('tag', {
        'tag--is-add-button': isAddButton,
        'tag--is-editing': isEditing,
      })}
      onDoubleClick={() => {
        if (inputRef.current) {
          setIsEditing(true);
          inputRef.current.focus();
        }
      }}
    >
      {!isAddButton && (
        <div className="tag__icon">
          <Icon icon="tag" size="tiny" />
        </div>
      )}
      <div className="tag__input">
        <AutogrowingInput
          value={value || ''}
          readOnly={readOnly || !isEditing}
          placeholder="Tag"
          onChange={(ev) => {
            ev.stopPropagation();
            setValue(ev.target.value);
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          ref={inputRef}
        />
      </div>

      {!readOnly && !isEditing && !isAddButton && (
        <button
          className="tag__button"
          onClick={() => {
            setIsEditing(true);
            inputRef.current.focus();
          }}
          type="button"
        >
          <Icon icon="edit" size="alpha" />
        </button>
      )}
      {isEditing && value !== '' && (
        <button
          className="tag__button"
          onClick={() => {
            setIsEditing(false);
          }}
          type="button"
        >
          <Icon icon="check" size="alpha" />
        </button>
      )}
      {isEditing && value === '' && (
        <button
          className="tag__button"
          onClick={() => {
            setIsEditing(false);
          }}
          type="button"
        >
          <Icon
            icon="trash"
            size="alpha"
            onClick={() => {
              onDelete(id);
            }}
          />
        </button>
      )}
      {isAddButton && !isEditing && (
        <button
          className="tag__button"
          onClick={() => {
            setIsEditing(true);
            inputRef.current.focus();
          }}
          type="button"
        >
          <Icon icon="plus" size="alpha" />
        </button>
      )}
    </div>
  );
};

export default Tag;
