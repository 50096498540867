/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import { LoadingView } from '@fieldbyfield/components';
import { Switch, Route, useParams, useLocation, useHistory } from '@fieldbyfield/router';

import Footer from '../Footer';
import Header from '../Header';
import Introduction from '../Introduction';
import Overview from '../Overview';
import Question from '../Question';
import { fetchSurvey, submitSurvey } from '../redux';
import ThankYou from '../ThankYou';

import './Survey.scss';

const questionsSelector = createSelector(
  (state) => state.questions,
  (qs) =>
    qs
      .map((q) => ({ id: q.id, aspect: q.aspect, cuesCount: q.cues.length, position: q.position }))
      .sort(({ position: a }, { position: b }) => a - b)
);

const Survey = ({ saveStateToLocaleStorage }) => {
  const { workspaceId, surveyId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const questions = useSelector(questionsSelector);

  const activeQuestion = questions.find((q) => location.pathname.endsWith(`/questions/${q.id}`));
  const activeQuestionIndex = questions.indexOf(activeQuestion);

  const surveyIsLoaded = useSelector((state) => state.survey.isLoaded);

  useEffect(() => {
    dispatch(
      fetchSurvey({
        workspace: { id: parseInt(workspaceId) },
        survey: { id: parseInt(surveyId) },
      })
    );
  }, [dispatch, workspaceId, surveyId]);

  if (!surveyIsLoaded) {
    return (
      <div className="survey__loading">
        <LoadingView theme="light">Loading Survey …</LoadingView>
      </div>
    );
  }

  const handlePrev = () => {
    saveStateToLocaleStorage();

    if (activeQuestionIndex === 0) {
      history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/introduction`);
      return;
    }

    if (location.pathname.endsWith('/thank-you')) {
      history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/questions/overview`);
      return;
    }

    if (activeQuestionIndex > 0) {
      history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/questions/${questions[activeQuestionIndex - 1].id}`);
      return;
    }
    if (location.pathname.endsWith('/overview')) {
      history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/questions/${questions[questions.length - 1].id}`);
    }
  };

  const handleNext = () => {
    if (location.pathname.endsWith('/overview')) {
      dispatch(
        submitSurvey({
          workspace: { id: workspaceId },
          survey: { id: surveyId },
        })
      );
      history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/thank-you`);
      return;
    }

    saveStateToLocaleStorage();

    if (questions.length > activeQuestionIndex + 1) {
      history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/questions/${questions[activeQuestionIndex + 1].id}`);
      return;
    }

    if (questions.length === activeQuestionIndex + 1) {
      history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/questions/overview`);
    }
  };

  return (
    <>
      <Switch>
        <Route
          path="/workspaces/:workspaceId/surveys/:surveyId/introduction"
          key="introduction"
          component={Introduction}
        />
        <Route path="/workspaces/:workspaceId/surveys/:surveyId/questions">
          <Header />

          <Route
            path="/workspaces/:workspaceId/surveys/:surveyId/questions/overview"
            key="overview"
            component={Overview}
          />

          <Switch>
            {questions.map((q) => (
              <Route path={`/workspaces/:workspaceId/surveys/:surveyId/questions/${q.id}`} key={`question-${q.id}`}>
                <Question id={q.id} onNext={handleNext} />
              </Route>
            ))}
          </Switch>
        </Route>
        <Route path="/workspaces/:workspaceId/surveys/:surveyId/thank-you" key="thank-you" component={ThankYou} />
      </Switch>
      <Footer onNext={handleNext} onPrev={handlePrev} activeQuestionIndex={activeQuestionIndex} />
    </>
  );
};
export default Survey;
