/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useLayoutEffect, useState } from 'react';

import * as RadixTooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';

import './Tooltip.scss';

const Tooltip = ({
  tooltipContent,
  children,
  side,
  sideOffset,
  align,
  delay = 0,
  open,
  onOpenChange,
  asChild,
  theme = 'default',
  noPadding,
}) => {
  const [floatingLayer, setFloatingLayer] = useState(document.querySelector('#floating'));

  useLayoutEffect(() => {
    setFloatingLayer(document.querySelector('#floating'));
  }, []);

  return (
    <RadixTooltip.Provider delayDuration={delay}>
      <RadixTooltip.Root open={open} onOpenChange={onOpenChange}>
        <RadixTooltip.Trigger asChild={asChild}>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal container={floatingLayer}>
          <RadixTooltip.Content
            className={classNames(
              'visual-tooltip',
              `visual-tooltip--theme-${theme}`,
              noPadding && 'visual-tooltip--no-padding'
            )}
            side={side}
            align={align}
            sideOffset={sideOffset || 0}
            collisionPadding={12}
          >
            <RadixTooltip.Arrow className="visual-tooltip__arrow" width={12} height={6} />
            {tooltipContent}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default Tooltip;
