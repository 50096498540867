/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import SVGFrame from './SVGFrame.jsx';

const GroupIcon = ({ size, theme }) => (
  <SVGFrame
    className={classNames('cue-type-icon', `cue-type-icon--group`, `cue-type-icon--${size}`, `cue-type-icon--${theme}`)}
    height={140}
    width={160}
    transformOrigin="left bottom"
  >
    <path
      d="M110.07 2.57444L20.4128 10.4185C12.4865 11.1119 5.16043 14.9256 0.0460911 21.0207C-5.06825 27.1157 -7.55187 34.9928 -6.85842 42.919L-2.50063 92.7287C-1.80718 100.655 2.00655 107.981 8.10158 113.095C14.1965 118.21 22.0733 120.693 29.9993 120C29.9995 120 29.9997 120 29.9999 120L116.933 143.294C124.619 145.353 132.807 144.275 139.698 140.297C146.588 136.318 151.616 129.766 153.676 122.08L166.617 73.7842C168.676 66.0988 167.598 57.9102 163.62 51.0196C161.665 47.6344 159.089 44.6987 156.063 42.3438C155.092 37.4222 152.894 32.7765 149.611 28.8648C147.099 25.8704 144.052 23.4266 140.663 21.6329C138.852 16.9546 135.88 12.7613 131.969 9.47895C125.874 4.36462 117.996 1.88099 110.07 2.57444Z"
      fill="none"
    />
    <g transform="rotate(15, 30, 120)">
      <path
        className="cue-type-icon--group-very-dark"
        d="M30,10 h90 a30,30 0 0 1 30,30 v50 a30,30 0 0 1 -30,30 h-90 a30,30 0 0 1 -30,-30 v-50 a30,30 0 0 1 30,-30 z"
      />
    </g>
    <g transform="rotate(5, 30, 120)">
      <path
        className="cue-type-icon--group-dark"
        d="M30,10 h90 a30,30 0 0 1 30,30 v50 a30,30 0 0 1 -30,30 h-90 a30,30 0 0 1 -30,-30 v-50 a30,30 0 0 1 30,-30 z"
      />
    </g>
    <g transform="rotate(-5, 30, 120)">
      <path d="M30,10 h90 a30,30 0 0 1 30,30 v50 a30,30 0 0 1 -30,30 h-90 a30,30 0 0 1 -30,-30 v-50 a30,30 0 0 1 30,-30 z" />
    </g>
  </SVGFrame>
);

export default GroupIcon;
