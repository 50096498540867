/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { Button, Icon, Pagination } from '@fieldbyfield/components';
import { useLocation, useParams, useHistory, NavLink } from '@fieldbyfield/router';

import './Footer.scss';

const questionsSelector = createSelector(
  (state) => state.questions,
  (qs) => qs.map((q) => ({ id: q.id, aspect: q.aspect, cuesCount: q.cues.length }))
);

const Footer = ({ onPrev, onNext, activeQuestionIndex }) => {
  const location = useLocation();
  const history = useHistory();
  const { workspaceId, surveyId } = useParams();

  const questions = useSelector(questionsSelector);
  const cuesCount = questions.map((q) => q.cuesCount).reduce((a, v) => a + v, 0);

  const prevButtonLabel = () => {
    if (location.pathname.endsWith('/overview')) {
      return 'Back';
    }

    if (location.pathname.endsWith('/thank-you')) {
      return 'Back';
    }

    if (activeQuestionIndex === 0) {
      return 'Back to Introduction';
    }

    return 'Previous Question';
  };

  const nextButtonLabel = () => {
    if (location.pathname.endsWith('/introduction')) {
      return 'Let’s Start!';
    }

    if (location.pathname.endsWith('/overview')) {
      return 'Submit';
    }

    if (questions.length === activeQuestionIndex + 1) {
      return 'To Overview';
    }

    return 'Next Question';
  };

  return (
    <footer className="footer grid">
      <div className="footer__back">
        {!location.pathname.endsWith('/introduction') && (
          <Button
            theme="light"
            onClick={() => {
              if (activeQuestionIndex === 0) {
                history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/introduction`);
                return;
              }

              if (location.pathname.endsWith('/thank-you')) {
                history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/questions/overview`);
                return;
              }

              if (activeQuestionIndex > 0) {
                history.push(
                  `/workspaces/${workspaceId}/surveys/${surveyId}/questions/${questions[activeQuestionIndex - 1].id}`
                );
                return;
              }
              if (location.pathname.endsWith('/overview')) {
                history.push(
                  `/workspaces/${workspaceId}/surveys/${surveyId}/questions/${questions[questions.length - 1].id}`
                );
              }
            }}
          >
            {prevButtonLabel()}
          </Button>
        )}
      </div>
      <div className="footer__back--mobile">
        {!location.pathname.endsWith('/introduction') && (
          <Button theme="light" onClick={onPrev} aria-label={prevButtonLabel()}>
            <Icon icon="arrow-left" color="white" size="alpha" />
          </Button>
        )}
      </div>
      <nav className="footer__navigation">
        <NavLink
          className="footer__link"
          activeClassName="footer__link--is-active"
          to={`/workspaces/${workspaceId}/surveys/${surveyId}/introduction`}
        >
          Introduction
        </NavLink>
        <div className="footer__separator" />

        <Pagination
          pages={questions.map((_q, i) => i)}
          onClick={(i) => {
            history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/questions/${questions[i].id}`);
          }}
          currentPage={activeQuestionIndex}
          theme="dark"
          backgroundColor="gray-800"
        />

        <div className="footer__separator" />
        <NavLink
          className="footer__link"
          activeClassName="footer__link--is-active"
          to={`/workspaces/${workspaceId}/surveys/${surveyId}/questions/overview`}
        >
          Overview
        </NavLink>
      </nav>
      <div className="footer__forward">
        {!location.pathname.endsWith('thank-you') && (
          <Button
            theme="light"
            type="primary"
            disabled={location.pathname.endsWith('/overview') && cuesCount === 0}
            onClick={onNext}
          >
            {nextButtonLabel()}
          </Button>
        )}
      </div>
    </footer>
  );
};

export default Footer;
