/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import { Point, Circle, Polygon } from '@mathigon/euclid';

import { getLargestEmptyCirclesCleaned } from './emptyCircles';
import { BOUNDED_AREAS, getOverlappingArea, getPolyInCircle } from './geographic';

export const MARKERS = [
  [0, -8.5],
  [0, 8.5],
  [8.5, 0],
  [-8.5, 0],
  [-4, -9],
  [-4, 9],
  [9, -4],
  [-9, -4],
  [4, -9],
  [4, 9],
  [9, 4],
  [-9, 4],
  // [0, 0],
  [-8, -8],
  // [-6, -6],
  [-8, 8],
  // [-6, 6],
  [8, 8],
  // [6, 6],
  [8, -8],
  // [6, -6],
];

const N_RESULTS = 6;
const MIN_RADIUS = 1;
const MAX_RADIUS = 6;

export function getWeakRegions(cues, thresholds) {
  // run one analysis per threshold
  const defaultThresholds = [0, 0.5, 0.75];
  if (!thresholds) {
    thresholds = defaultThresholds;
  }
  const noConfidenceRating = cues.every((c) => c.confidence === 0);

  if (noConfidenceRating) {
    const coords = cues.map((c) => [c.x, c.y]);
    return getLargestEmptyCirclesCleaned([...MARKERS, ...coords], N_RESULTS, MIN_RADIUS, MAX_RADIUS);
  }
  const analysis = thresholds.map((t) => {
    const coords = cues
      .filter((c) => c.confidence !== 0)
      .filter((c) => c.confidence > t)
      .map((c) => [c.x, c.y]);
    if (!coords.length) {
      return [];
    }
    // console.log('T ', t, ' n ', coords.length);
    return getLargestEmptyCirclesCleaned([...MARKERS, ...coords], N_RESULTS, MIN_RADIUS, MAX_RADIUS);
  });

  if (analysis.length === 1) {
    return analysis[0];
  }

  // choose the analysis where circles have distinct size
  const scoredAnalysis = analysis.map((areas) => {
    const score = areas.reduce(
      (acc, a) => {
        const radiusComparison = areas.reduce((cacc, othera) => {
          return a.radius - othera.radius;
        }, 0);
        const distanceComparison = areas.reduce((cacc, othera) => {
          return Point.distance(a, othera);
        }, 0);
        acc.radius += radiusComparison;
        acc.distance += distanceComparison;
        return acc;
      },
      { radius: 0, distance: 0 }
    );
    return {
      areas,
      score,
      weightedScore: score.radius + score.distance / 10,
    };
  });

  const sortedAnalysis = scoredAnalysis.sort((a, b) => {
    return -Math.sign(a.weightedScore - b.weightedScore);
  });

  return sortedAnalysis[0].areas;
}

/**
 * https://github.com/aribambang/minmaxscaler/blob/master/index.js
 * Compute the minimum and maximum to be used for later scaling.
 * @param {Array} X - input data.
 * @param {integer} min - minimum value of the feature range.
 * @param {integer} max - maximum value of the feature range.
 * @return {Array} X_scaled - Final scaled array fitted within Feature Range.
 */
function minMaxScale(v, min = 0, max = 1) {
  const X_max = Math.max.apply(null, v);
  const X_min = Math.min.apply(null, v);

  const X_minArr = v.map((values) => values - X_min);
  // X_std = (X - X.min()) / (X.max() - X.min())
  const X_std = X_minArr.map((values) => values / (X_max - X_min));
  // X_scaled = X_std * (max - min) + min
  const X_scaled = X_std.map((values) => values * (max - min) + min);

  return X_scaled;
}

export function normalizeRelevance(cues) {
  const scaled = minMaxScale(cues.map((c) => c.relevance));
  return cues.map((c, i) => {
    return {
      ...c,
      relevance: isNaN(scaled[i]) ? 0 : scaled[i],
    };
  });
}

const groupBy = (key) => (array) =>
  array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
    }),
    {}
  );

const groupByAspect = groupBy('aspect');

export function normalizeRelevanceByAspect(cues) {
  const grouped = groupByAspect(cues);
  return Object.keys(grouped)
    .map((k) => {
      const scaled = minMaxScale(grouped[k].map((c) => c.relevance));
      return grouped[k].map((c, i) => {
        return {
          ...c,
          relevance: isNaN(scaled[i]) ? 0 : scaled[i],
        };
      });
    })
    .reduce((acc, val) => acc.concat(val), []);
}

export function getMethods(cues, methods, thresholds) {
  return sortMethods(methods, getWeakRegions(cues, thresholds));
}

export function complexGetMethods(cues, methods) {
  const thresholds = [0.3, 0.5, 0.7, 0.9];
  const weakRegionSamples = [];
  const sortedMethodSamples = [];

  for (let i = 0; i < thresholds.length; i++) {
    weakRegionSamples[i] = getWeakRegions(cues, [thresholds[i]]);
    sortedMethodSamples[i] = sortMethods(methods, weakRegionSamples[i]);
  }

  sortedMethodSamples.forEach((ms) => {
    ms.slice(0, 3).forEach((m) => {
      methods = methods.map((method) => {
        if (method.id !== m.id) {
          return method;
        }
        return { ...method, totalArea: (method.totalArea || 0) + m.overlapArea };
      });
    });
  });

  return methods.sort((a, b) => b.totalArea - a.totalArea);
}

export function sortMethods(methods, weakSpaces) {
  const weakPolygons = weakSpaces.map((w) => getPolyInCircle({ circle: new Circle(new Point(w.x, w.y), w.radius) }));

  return methods
    .map((m) => {
      let methodOverlapArea = 0;
      m.aspects.forEach((a) => {
        const area = new Polygon(...BOUNDED_AREAS[a.aspect].map((p) => new Point(...p)));

        weakPolygons.forEach((wP) => {
          const overlap = getOverlappingArea(area, wP);
          methodOverlapArea += overlap * a.weight;
        });
      });

      return { ...m, overlapArea: methodOverlapArea };
    })
    .sort((a, b) => b.overlapArea - a.overlapArea);
}
