/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';
import './Table.scss';

const Table = ({ data, size = 'alpha' }) => {
  if (data.length < 1) {
    return null;
  }

  const headerCells = Object.keys(data[0]).map((key) => (
    <th className="table__header-cell" key={key}>
      {key}
    </th>
  ));

  return (
    <table className={classNames('table', `table--${size}`)}>
      <thead>
        <tr className="table__row">{headerCells}</tr>
      </thead>
      <tbody className="table__body">
        {data.map((d, i) => (
          <tr key={Object.values(d).join('-')} className="table__row">
            {Object.values(d).map((value, k) => (
              <td key={value || `${i}-${k}`} className="table__cell">
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
