/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import SVGFrame from './SVGFrame.jsx';

const ProductIcon = ({ size, theme }) => (
  <SVGFrame
    className={classNames(
      'cue-type-icon',
      `cue-type-icon--product`,
      `cue-type-icon--${size}`,
      `cue-type-icon--${theme}`
    )}
    height={140}
    width={140}
  >
    <path d="M 70,0 L 140,70 L 70,140 L 0,70 z" />
  </SVGFrame>
);

export default ProductIcon;
