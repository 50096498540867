/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './Card.scss';

/**
 * A Card is used to prompt the user to take action or inform them about important information.
 *
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @param {JSX.Element} [props.actions]
 * @param {string} [props.headline]
 * @param {('default' | 'dark' | 'highlight' | 'lime-pie' | 'lemon-tarte' | 'peach-crumble' | 'plum-compote' | 'blueberry-pancakes')} [props.theme = "default"]
 */
const Card = ({ children, headline, actions, theme = 'default', compact }) => {
  return (
    <div
      className={classNames('card', `card--theme-${theme}`, {
        'card--no-headline': !headline,
        'card--compact': compact,
      })}
    >
      <div className="card__text">
        {headline && <div className="card__headline">{headline}</div>}
        <div className="card__message">{children}</div>
      </div>
      {actions && <div className="card__footer">{actions}</div>}
    </div>
  );
};

export default Card;
