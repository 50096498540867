/**
 * Copyright 2022 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './Pill.scss';

/**
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @param {('default' | 'highlight' | 'marketing' | 'dark')} [props.theme = "default"]
 */
const Pill = ({ children, theme = 'default' }) => {
  return <div className={classNames('pill', `pill--theme-${theme}`)}>{children}</div>;
};

export default Pill;
