/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import Button, { ButtonGroup } from '../Button';
import FormElement from '../FormElement';
import Icon from '../Icon';
import TextInput from '../TextInput';
import selectFocusableNodes from '../utils/selectFocusableNodes';

import './Alert.scss';

let previouslyFocusedDomNode;

/**
 * An Alert is used to confirm the user’s consent in case of type="destructive" actions.
 *
 * @param {Object} props
 * @param {string} props.headline,
 * @param {string} props.message,
 * @param {string} [props.icon = 'warning'],
 * @param {string} props.confirmationLabel,
 * @param {function} props.onCancel,
 * @param {function} props.onConfirm,
 * @param {boolean} [props.extremelyDangerous = false],
 * @param {string} [props.securityPhrase = 'DELETE'] A phrase the user has to type to confirm they know what they are doing. Only visible when “extremelyDangerous” is set to true.
 */
const Alert = ({
  headline,
  message,
  icon = 'warning',
  confirmationLabel,
  onCancel,
  onConfirm,
  extremelyDangerous,
  securityPhrase = 'DELETE',
}) => {
  const alertRef = useRef();
  const [confirmation, setConfirmation] = useState(false);

  const handleClick = (ev) => {
    // This is to prevent a click on the body of an alert from
    // reaching its parent, typically an Overlay (clicks on
    // which typically dismiss the alert.)
    ev.stopPropagation();
  };

  useLayoutEffect(() => {
    previouslyFocusedDomNode = document.activeElement;

    if (!alertRef.current) {
      return;
    }
    const focusableNodes = selectFocusableNodes(alertRef.current);
    focusableNodes[0].focus();

    return () => {
      if (previouslyFocusedDomNode) {
        previouslyFocusedDomNode.focus();
      }
    };
  }, []);

  useLayoutEffect(() => {
    const handleKey = (ev) => {
      if (ev.key === 'Escape') {
        ev.stopPropagation();
        onCancel();
      }
      if (ev.key === 'Enter' && (!extremelyDangerous || confirmation)) {
        onConfirm();
      }
      // Captures the focus and keeps it within the modal.

      // Backwards
      if (ev.key === 'Tab' && ev.getModifierState('Shift')) {
        if (alertRef.current) {
          const focusableNodes = selectFocusableNodes(alertRef.current);

          // If the focus is outside or on the last element focus the first one
          if (!alertRef.current.contains(document.activeElement) || document.activeElement === focusableNodes[0]) {
            ev.preventDefault();
            if (focusableNodes.length > 0) {
              focusableNodes[focusableNodes.length - 1].focus();
            }
            return;
          }
        }
        return;
      }

      // Forwards
      if (ev.key === 'Tab') {
        if (alertRef.current) {
          const focusableNodes = selectFocusableNodes(alertRef.current);

          // If the focus is outside or on the last element focus the first one
          if (
            !alertRef.current.contains(document.activeElement) ||
            document.activeElement === focusableNodes[focusableNodes.length - 1]
          ) {
            ev.preventDefault();
            if (focusableNodes.length > 0) {
              focusableNodes[0].focus();
            }
          }
        }
      }
    };

    const alertDom = alertRef.current;
    if (!alertRef.current) {
      return;
    }

    alertDom.addEventListener('keydown', handleKey);

    return () => {
      alertDom.removeEventListener('keydown', handleKey);
    };
  }, [extremelyDangerous, confirmation, alertRef, onConfirm, onCancel]);

  const handleTextInput = useCallback(
    (value) => {
      if (value === securityPhrase) {
        setConfirmation(true);
      } else {
        setConfirmation(false);
      }
    },
    [securityPhrase, setConfirmation]
  );

  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
  return (
    <div className="alert" role="dialog" aria-modal ref={alertRef} aria-labelledby="alert-title" onClick={handleClick}>
      {/* eslint-enable */}
      <div className="alert__content">
        <div className="alert__icon">
          <Icon icon={icon} size="delta" color="gray-500" />
        </div>
        <div className="alert__body">
          <div className="alert__message">
            <div className="alert__headline" id="alert-title">
              {headline}
            </div>
            {message}
          </div>
          {extremelyDangerous && (
            <div className="alert__check">
              <FormElement label={`Type “${securityPhrase}” to Confirm`} name="confirmation">
                <TextInput value="" name="confirmation" onChange={handleTextInput} />
              </FormElement>
            </div>
          )}
          <div className="alert__actions">
            <ButtonGroup>
              <Button onClick={onCancel} theme="light">
                Cancel
              </Button>
              <Button onClick={onConfirm} type="destructive" disabled={extremelyDangerous && !confirmation}>
                {confirmationLabel}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
