/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import LoadingSpinner from '../LoadingSpinner';

import './InlineLoadingIndicator.scss';

const InlineLoadingIndicator = ({ children }) => {
  return (
    <div className="inline-loading-indicator">
      <LoadingSpinner size="alpha" />
      <div className="inline-loading-indicator__text">{children || 'Loading …'}</div>
    </div>
  );
};

export default InlineLoadingIndicator;
