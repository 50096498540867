/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export const CONTEXT_MENU_ITEM_TYPES = {
  button: 'button',
  select: 'select',
  menu: 'menu',
  separator: 'separator',
};
