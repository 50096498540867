/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import SVGFrame from './SVGFrame.jsx';

const UnlinkedProductIcon = ({ size, theme }) => (
  <SVGFrame
    className={classNames(
      'cue-type-icon',
      `cue-type-icon--product-unlinked`,
      `cue-type-icon--${size}`,
      `cue-type-icon--${theme}`
    )}
    height={140}
    width={140}
  >
    <defs>
      <clipPath id="inner">
        <path d="M 70,-250 L 390,70 L 70,390 L -250,70 z M  25,70 L 70,115 L 115,70 L 70,25 z" />
      </clipPath>
    </defs>

    <path d="M 70,0 L 140,70 L 70,140 L 0,70 z M  25,70 L 70,115 L 115,70 L 70,25 z" clipPath="url(#inner)" />
  </SVGFrame>
);

export default UnlinkedProductIcon;
