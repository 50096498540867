/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import keyVisualVertical from './key-visual-vertical.png';
import keyVisual from './key-visual.png';

import './KeyVisual.scss';

const KeyVisual = ({ fill, vertical, className }) => {
  return (
    <img
      src={vertical ? keyVisualVertical : keyVisual}
      className={classNames('key-visual', className, {
        'key-visual--fill': fill,
      })}
      alt=""
    />
  );
};

export default KeyVisual;
