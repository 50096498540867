/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import './TooltipContainer.scss';
import classNames from 'classnames';

import { LegacyTooltip } from '@fieldbyfield/components';

const TooltipContainer = ({ children, tipPosition = 'top-center', visible, actions, onClickOutside }) => {
  return (
    <div
      className={classNames('tooltip-container', `tooltip-container--${tipPosition}`, {
        'tooltip-container--is-visible': visible,
      })}
    >
      {visible && (
        <LegacyTooltip
          tipPosition={tipPosition}
          onClickOutside={onClickOutside}
          footer={
            <>
              {actions.map((a) => (
                <button
                  key={a.title}
                  className="tooltip-container__action"
                  onClick={() => {
                    if (onClickOutside) {
                      onClickOutside();
                    }
                    a.onClick();
                  }}
                  type="button"
                >
                  {a.title}
                </button>
              ))}
            </>
          }
        >
          {children}
        </LegacyTooltip>
      )}
    </div>
  );
};

export default TooltipContainer;
