/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect, useRef } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useSelector, useDispatch, batch } from 'react-redux';

import { tracking } from '@fieldbyfield/business';
import { Term, Icon, Paragraph, Button, InspirationList, KeyVisual, Radar } from '@fieldbyfield/components';
import { useHistory, useParams } from '@fieldbyfield/router';

import Cue from '../Cue';
import {
  addInspiration,
  deleteInspiration,
  updateInspiration,
  addCue,
  updateCue,
  deleteCue,
  anonymizeCue,
  deanonymizeCue,
  submitSurvey,
  updateNewCue,
} from '../redux';

import './Overview.scss';

const Question = ({ id }) => {
  const question = useSelector((state) => state.questions.find((q) => q.id === id).text);
  const aspect = useSelector((state) => state.questions.find((q) => q.id === id).aspect);
  const inspirations = useSelector((state) => state.questions.find((q) => q.id === id).inspirations);
  const cues = useSelector((state) => state.questions.find((q) => q.id === id).cues);
  const user = useSelector((state) => state.user.email);
  const unsavedCue = useSelector((state) => state.questions.find((q) => q.id === id).newCue);
  const dispatch = useDispatch();

  const cueRefs = useRef({});

  useEffect(() => {
    if (unsavedCue && unsavedCue !== '') {
      batch(() => {
        dispatch(addCue({ question: id, text: unsavedCue }));
        dispatch(updateNewCue({ question: id, text: '' }));
      });
    }
  }, [unsavedCue, dispatch, id]);

  const handleAddNewCue = () => {
    dispatch(addCue({ question: id, text: '' })).then(({ id: cueId }) => {
      const dom = cueRefs.current[cueId];
      if (!dom) {
        return;
      }
      dom.querySelector('.cue__input').focus();
    });
  };

  return (
    <div className="overview-question">
      {aspect && (
        <div className="overview-question__aspect">
          <Term>{aspect}</Term>
        </div>
      )}
      <div className="overview-question__question">{question}</div>

      {inspirations.length > 0 && (
        <div className="overview-question__inspiration">
          <div className="overview-question__label">Inspirations</div>
          <InspirationList
            inspirations={inspirations}
            onAddItem={(text) => {
              dispatch(
                addInspiration({
                  question: id,
                  text,
                })
              );
            }}
            onDeleteItem={(itemId) => {
              dispatch(
                deleteInspiration({
                  question: id,
                  id: itemId,
                })
              );
            }}
            onChangeItem={(item) => {
              dispatch(
                updateInspiration({
                  question: id,
                  id: item.id,
                  text: item.text,
                })
              );
            }}
          />
        </div>
      )}
      <div className="overview-question__cues">
        <div className="overview-question__label">Your Notes</div>
        <div className="overview-question__cue-list">
          {cues.map((c) => {
            return (
              <div className="overview-question__cue" key={c.id}>
                <Cue
                  {...c}
                  onAddCue={handleAddNewCue}
                  onUpdateCue={(cue) => {
                    dispatch(updateCue({ question: id, ...cue }));
                  }}
                  onDeleteCue={(cueId) => {
                    dispatch(deleteCue({ question: id, id: cueId }));
                  }}
                  onAnonymizeCue={(cueId) => {
                    dispatch(anonymizeCue({ question: id, id: cueId }));
                  }}
                  onDeanonymizeCue={(cueId) => {
                    dispatch(deanonymizeCue({ question: id, id: cueId }));
                  }}
                  small
                  user={user}
                  ref={(el) => {
                    cueRefs.current[c.id] = el;
                  }}
                />
              </div>
            );
          })}

          <button className="overview-question__cue-add" key="add" onClick={handleAddNewCue} type="button">
            <Icon icon="plus" size="delta" color="gray-400" />
          </button>
        </div>
      </div>
    </div>
  );
};

const questionsSelector = createSelector(
  (state) => state.questions,
  (qs) => qs.map((q) => ({ id: q.id, cuesCount: q.cues.length }))
);

const Overview = () => {
  const history = useHistory();
  const { workspaceId, surveyId } = useParams();

  const dispatch = useDispatch();
  const questions = useSelector(questionsSelector);
  const cuesCount = questions.map((q) => q.cuesCount).reduce((a, v) => a + v, 0);

  const questionsWithCues = questions.map((q) => q.cuesCount).reduce((a, v) => (v === 0 ? a : a + 1), 0);

  return (
    <main className="overview">
      <div className="overview__intro grid">
        <div className="overview__intro-content">
          <div className="overview__intro-kicker">Overview</div>
          <h1 className="overview__intro-title">
            {cuesCount > 0 && (
              <>
                You added {cuesCount === 1 ? 'one' : cuesCount} {cuesCount > 1 ? 'notes' : 'note'}{' '}
                {questionsWithCues > 1 ? `to ${questionsWithCues} questions` : ''}.
              </>
            )}
            {cuesCount === 0 && <>You have not added any notes yet.</>}
          </h1>
          <div className="overview__intro-text">
            <Paragraph size="gamma">
              Here you can see an overview of all the notes you added. Before submitting you can review, rephrase or
              delete them.
            </Paragraph>
            <div className="overview__intro-button">
              <Button
                type="primary"
                theme="light"
                disabled={cuesCount === 0}
                onClick={() => {
                  dispatch(
                    submitSurvey({
                      workspace: { id: workspaceId },
                      survey: { id: surveyId },
                    })
                  );

                  tracking.amplitude().logEvent('collect: submit answers');
                  history.push(`/workspaces/${workspaceId}/surveys/${surveyId}/thank-you`);
                }}
              >
                Submit Notes
              </Button>
            </div>
          </div>
        </div>

        <KeyVisual fill />
      </div>

      <div className="overview__questions grid">
        <div className="overview__questions-content">
          {questions.map((q) => (
            <Question id={q.id} key={q.id} />
          ))}
        </div>
      </div>

      <div className="overview__outro grid">
        <div className="overview__outro-content">
          <h1 className="overview__outro-title">Ready to go?</h1>

          <div className="overview__outro-radar">
            <Radar size={240} />
          </div>
        </div>

        <KeyVisual fill />
      </div>
    </main>
  );
};
export default Overview;
