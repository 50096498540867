/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

const TermsContent = (
  <>
    <h1>Terms of Service</h1>

    <p>Effective: 6th February 2021</p>

    <h2>Scope</h2>

    <p>
      These terms of service by Product Field Works GmbH for the use of the Field SaaS (as applicable from time to time
      “ToS”) apply for contracts between Product Field Works GmbH (hereinafter referred to as “Field”) and its customers
      as business persons (section 14 German Civil Code, Bürgerliches Gesetzbuch (BGB)) (hereinafter referred to as
      “Customer”) regarding the use of the ‘Field’ IT tool (software-as-a-service) (hereinafter referred to as
      “Software” or “Tool”) as described in detail on{' '}
      <a href="https://field.so" target="_blank" rel="noreferrer">
        https://field.so
      </a>{' '}
      (hereinafter referred to as “Website”) and and related services (the Software and related services jointly the
      “Service(s)”). The provided scope of the Services depends on the package the Customer subscribes for.
    </p>

    <p>
      The Customer shall be fully responsible for the compliance with these ToS by its respective users using the
      Services.
    </p>

    <p>The Customer shall support Field in respect to the Services within reasonable scope.</p>

    <h2>Availability and Changes to the ToS</h2>

    <p>
      The ToS are available on the Website (
      <a href="https://field.so/en/terms" target="_blank" rel="noreferrer">
        https://field.so/en/terms
      </a>
      ) at any time.
    </p>

    <p>
      Field is entitled to change these ToS at any time with a reasonable notice period. After the publication of an
      amendment notice, the Customer has an extraordinary right of termination of the agreement based in these ToS. The
      changes shall be deemed approved, if the Customer does not object in writing to the changes within the reasonable
      notice period and has been notified of such effect in the respective amendment notification.
    </p>

    <h2>Services provided by Field</h2>

    <p>
      The use of the Software requires the successful registration as well as the set-up of a user administration
      account (“Account”) via the Website. Customers must fill in all required fields in the sign-up form truthfully and
      completely. By clicking on the respective sign-up-button the Customer submits an offer on the conclusion of an
      agreement to use the Tool (and related Services) and agrees to these ToS valid at the time of registration. Field
      accepts this offer in writing or by providing the Service.
    </p>

    <p>
      There is no legal entitlement for using the Service provided. Field has the right to stop or deny any registration
      without giving any reasons.
    </p>

    <p>
      Field makes the Tool available on its own servers or on the server of an engaged data processor (“Data Centre”).
      The Software is kept available via this Data Centre along with the data that is recorded, elevated, used and held,
      saved onto the open platform and kept available for remote data access in the interest of intended polling. Use of
      the Tool will be made available to the Customer by Field from the router output via the Data Centre. The
      establishing and maintaining of the data connection between the Tool user’s end device and the delivery point
      operated by Field falls under the Customer’s responsibilities. Accessing the data saved onto the Tool will no
      longer be possible for the Customer after termination of the agreement.
    </p>

    <p>
      Access to the Tool and the stored data is possible at all times outside of the maintenance window, unless Field is
      required to carry out emergency support works or take other urgent measures in order to maintain the Tool’s
      usability. The object or source code of the Software will not be disclosed.
    </p>

    <p>
      Field is not obliged to make a document or a separate instructions manual available to the Customer. Field will
      give an overview of the Tool’s basic functions and will give the Customer access to the relevant information, if
      necessary.
    </p>

    <h2>Intellectual Property Rights</h2>

    <p>
      Customer acknowledges that all copyrights, trademarks, and other intellectual property rights in and relating to
      the Services are owned by or licensed to Field.
    </p>

    <h2>Rights in case of defects, Changes to and development of the Tool</h2>

    <p>
      The Software made available to Customer shall essentially correspond to the product description according to this
      agreement. Warranty claims do not exist in the case of an insignificant deviation from an agreed or presumed
      quality and in the case of only insignificant impairment of usability.
    </p>

    <p>
      As a matter of principle, the statutory provisions for warranty in rental contracts shall apply. The provisions
      set forth in section 536b BGB (<em>Kenntnis des Mieters vom Mangel bei Vertragsschluss oder Annahme</em>) and
      section 536c BGB (<em>Während der Mietzeit auftretende Mängel; Mängelanzeige durch den Vermieter</em>) shall
      apply. However, the provisions set forth in section 536a para. 2 BGB (<em>Selbstbeseitigungsrecht des Mieters</em>
      ) are excluded. Also excluded is the application of Section 536a para. 1 BGB (
      <em>Schadensersatzpflicht des Vermieters</em>) insofar as such provision causes liability regardless of fault and
      not due to any guarantee.
    </p>

    <p>In all other respects, the provisions of service contract law (section 611 et seq. BGB) shall apply.</p>

    <p>
      According to § 2 above, Field is entitled to change the design and to adapt the structure and functions of the
      Tool and will inform the Customer of any significant changes. If Field is obliged to remove any errors /
      shortcomings of the Tool and to bring about / maintain the contractually elaborated nature of the Tool, Field is
      entitled to choose the method in which it removes said shortcomings. Field is entitled to decline the use of the
      Tool and the saved data once a week for a maximum of two (2) hours, in order to be able to bring changes to the
      tool or to carry out other maintenance activities. Field will try to keep the limitations to a minimum, for
      example by carrying out the works at night. Customer notes that Field may not be responsible for any interruptions
      of the Tool caused by the engaged third party providers. Any binding statutory rights of Customer related to
      rights in case of defects shall remain unaffected.
    </p>

    <p>
      In the event of any defects or errors relating to the Services Customer shall report such defects or errors via
      email to <a href="mailto:support@field.so">support@field.so</a> and include sufficient details relating to the
      defect in such reporting.
    </p>

    <h2>Content and Rights Grant</h2>

    <p>
      When using the Tool and Services, the Customer (or its users) may provide or upload content in or as part of the
      Services such as texts, photos, graphics, etc. (“Customer Content”). In general, all copyrights, trademarks, and
      other intellectual property rights relating to such Customer Content shall be owned by or licensed by the
      Customer.
    </p>

    <p>
      The Customer bears responsibility for ensuring that the Customer Content entered complies with the relevant laws
      and regulations, and, importantly, does not infringe any third party’s rights.
    </p>

    <p>
      Field stores, uses and accesses Customer Content for the limited purposes of: (a) performing Field’s obligations
      under this Agreement and any applicable Order Form; (b) responding to service issues and requests for support; (c)
      complying with Customer’s instructions; and (d) maintaining and improving the Application Services.
    </p>

    <p>
      Field may also use Customer Content in a de-identified and aggregated form (“Aggregated Data”) for Field’s own
      business purposes, including use, duplication, modification, and creation of derivative works regarding usage and
      performance of Aggregated Data. Aggregated Data does not directly or indirectly identify Customer, End Users, or
      individual data subjects. Field shall own all right, title and interest to the Aggregated Data and any derivative
      works thereof.
    </p>

    <h2>Customer’s Warranty</h2>

    <p>
      Customer hereby represents and warrants that, as applicable, (i) it owns such Content provided, holds the
      necessary rights or is otherwise authorized to use such Content for the purpose of the Service, and (ii) such
      Content does not infringe any third party rights (copyrights, trademarks, other intellectual property rights) or
      infringes such third party’s privacy.
    </p>

    <p>
      Should Field be notified that an account contains unlawful content (such as texts, photos, videos etc.), Field is
      legally entitled to block this profile temporarily or permanently, Field will inform the Customer of the third
      party’s complaint, allow the Customer (or its user) to justify the related statement and forward the justification
      to the third party if reasonable.
    </p>

    <h2>Prohibited Use</h2>

    <p>
      Customer agrees not to upload, transmit, support, incite, promote or otherwise make available any content that is
      or could reasonably be viewed as unlawful, racist, hostile, violent, discriminatory (including relating to race,
      religion, sex, sexual orientation, age, disability, ancestry or national origin), harmful, harassing, defamatory,
      vulgar, obscene or otherwise objectionable or which contains software viruses or any other computer code, files or
      programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or
      telecommunications equipment. Field has the right to remove uploaded content, if Field decides in its sole
      discretion that it results in or from a breach of law or any part of these ToS.
    </p>

    <p>In particular, Customer (or users) may not</p>

    <ul>
      <li>
        use the Services for any other purpose than provided for in these ToS, in particular, not offer the Services to
        unauthorized third parties or sell, sub-license, lease, transfer or otherwise commercially exploit the Software;
      </li>

      <li>interfere with or disrupt the Services or servers or networks;</li>

      <li>remove or amend any proprietary notices or other ownership information from the Service;</li>

      <li>collect or harvest any personally identifiable information, including account names, from the Service;</li>

      <li>
        decompile, reverse engineer, disassemble or hack any of the Service or defeat or overcome any of Field’s (or
        engaged third party providers’) encryption technologies or security measures;
      </li>

      <li>
        conduct any action that restricts or inhibits anyone’s use or enjoyment of the Service or may harm or offend or
        expose Field or its users to liability, in particular refrain from use that can disable, overburden, damage or
        impair the Service (or the ability to engage in real time activities via the Service) or use of robots, spiders
        or other automatic device, process or mean (to access the Service);
      </li>

      <li>
        use any device, software or routine to interfere with the proper working of the Service, in particular, not use
        any viruses, trojan horses, worms, logic bombs or anything malicious or technologically harmful;
      </li>

      <li>
        perform attempts for unauthorised access, interference, damage or disrupt, copying, distributing or disclosing
        any parts of the Service;
      </li>

      <li>transmit or procure of any junk mail, chain letter, spam, or any other similar solicitation;</li>

      <li>harm minors by exposure of inappropriate content, including but not limited to Content;</li>

      <li>impersonate Field, Field’s employees or other users of the Service or their employees;</li>

      <li>pursue any threatening, fraudulent, harmful purpose or activity; and/or</li>

      <li>use the Services in any other way not permitted by these ToS.</li>
    </ul>

    <p>
      In the event of a violation of the above by the Customer, Field shall be entitled to deny access to the Services
      (temporarily) and - if reasonable - immediately terminate the agreement.
    </p>

    <h2>Customer Account and Information</h2>

    <p>
      Depending on the plan the Customer has chosen for the use of the Tool, the Customer may assign users as “field
      owners (‘seats’)” via the admin Account and control and edit the admin rights relating to such additional seats.
      The Customer shall be solely responsible for such Account and additional user accounts and seats (if any)
      according to the details set out in these ToS.
    </p>

    <p>
      If there are any changes to the Customer’s relevant information, e.g. billing or email address, Customer is
      obliged to inform Field of the new data immediately in writing, for example via changing the settings in the
      Account, provided this is relevant to the fulfillment of the agreement.
    </p>

    <h2>Back-up rights and obligations</h2>

    <p>
      Field is entitled to regularly make backups (backup copies) to comply with its legal obligations (including
      retention periods), secure an optimal running of the Tool or in case Field has a legitimate interest in the backup
      / archiving of contents, for example if a third party has published claims against Field or its users, Field is
      entitled to keep the relevant backup / archived content for such purposes, in general until the legal ending of
      these proceedings.
    </p>

    <h2>Liability of Field</h2>

    <p>
      For services by Field that are free of charge, the following shall apply: To the extent that Field provides
      services to you free of charge, Field shall only be liable in accordance with the statutory provisions, provided
      that claims for damages are based on willful intent (<em>Vorsatz</em>) or gross negligence (
      <em>grobe Fahrlässigkeit</em>).
    </p>

    <p>
      For services by Field against payment the following shall apply: To the extent that Field provides services to you
      against payment, Field shall only be liable for any loss or damages caused by willful intent (<em>Vorsatz</em>) or
      gross negligence (<em>grobe Fahrlässigkeit</em> ). Field shall only be liable for slight negligence, if an
      essential contractual obligation is violated, which is indispensable for the duly execution of the contract and
      thereby jeopardizes the achievement of the contract purpose.
    </p>

    <p>
      The limitation of liability in the above mentioned paragraphs of this Section does not apply to claims for damages
      that result from injury to life, body or health, in cases of warranting for the condition of a product or service,
      under the Product Liability Act and in cases of fraudulent concealment of defects by Field.
    </p>

    <p>
      The limitation and/or restriction of Field’s liability in this Section shall equally apply to the personal
      liability of its legal representatives, officers, employees, assistants in performance and agents.
    </p>

    <h2>Remuneration and Price List</h2>

    <p>
      The remuneration for the Services is determined by the current price list at the moment of entering into the
      agreement based on these ToS as displayed on the Website{' '}
      <a href="https://field.so" target="_blank" rel="noreferrer">
        https://field.so
      </a>{' '}
      (“Pricing”). All prices are excluding VAT.
    </p>

    <h2>Payments and Billing</h2>

    <p>
      Field uses Stripe{' '}
      <a href="https://stripe.com" target="_blank" rel="noreferrer">
        https://stripe.com
      </a>{' '}
      as a third party payment processor (”Payment Processor(s)”) to bill the Customer through a payment account linked
      to its Account (“Billing Account”) for use of the paid Services. The processing of payments may be subject to the
      terms and conditions as well as the privacy policies of the Payment Processors in addition to this agreement.
      Field is not responsible for any defects related to or services by the Payment Processors. By choosing to use paid
      Services, the Customer agrees to pay Field through the Payment Processors, all charges at the prices then in
      effect for any use of such paid Services in accordance with the applicable payment terms and Customer authorizes
      Field, through the Payment Processors, to charge the chosen payment provider (“Payment Method”). Customer agrees
      to make payments using that selected Payment Method. All invoices are denominated, and Customer must pay as set
      out in the Price List. Customer shall be obliged to pay monthly on or about the first day of each month, if not
      otherwise agreed in writing with Field. On rare occasions, the Customer may be billed an amount up to the
      Customer’s current balance in an effort to verify the authenticity of the Customer’s account information. This
      process ensures that Customers without a payment history are not subjected to additional scrutiny. Customers
      outside of the European Union (EU) are entirely responsible for the payment of all taxes. For specific pricing
      policies, please refer to the current Price List. Monthly fees and renewal fees will be billed at the rate agreed
      between the parties at the time of successful subscription.
    </p>

    <p>
      The Customer must provide current, complete and accurate information for its Billing Account, must promptly update
      all information to keep them current, complete and accurate (such as a change in billing address, credit card
      number, or credit card expiration date), and promptly notify Field or our Payment Processors if the payment method
      is canceled (for example for loss or theft) or if Customer becomes aware of a potential breach of security, such
      as the unauthorized disclosure or use of user name or password. Changes to such information can be made under
      ‘Billing settings’ in the Account of the Customer. If Customer fails to provide any of the foregoing information,
      Customer agrees that Field may continue charging Customer for any use of paid services under the Billing Account
      unless Customer has terminated the paid services as set forth herein.
    </p>

    <p>
      Payments not made within ten (10) days of invoicing will be deemed in arrears. For accounts in arrears, if any
      amount is more than ten (10) days overdue, without the requirement of providing notice of such arrears, Field may
      suspend service to such account and bring legal action to collect the full amount due, including any attorneys’
      fees and costs. If a Customer is past due on their balance, Field may send up to three (3) email notifications
      within a fifteen (15) day period before suspending the respective Account.
    </p>

    <h2>Term and Termination</h2>

    <p>
      The agreement based on the ToS is entered into for an indeterminate period of time and can be terminated by each
      party by the end of the month with a notice period of 14 days. The right to termination for cause remains
      unaffected. Any termination shall be in text form. The Customer may cancel the Services at any time by logging
      into the Account. After the cancellation being effective, the customer’s Account will be inactivated and the
      Customer will no longer be able to log into the Website and/or have any access to the Services.
    </p>

    <h2>Confidentiality</h2>

    <p>
      Each party agrees and undertakes, with respect to any information received by the respective other party marked as
      ‘confidential’ or appearing confidential (“Confidential Information”):
    </p>

    <ul>
      <li>
        to examine and use all Confidential Information only for the purpose of the Services, and not to use the
        Confidential Information for its own benefit or to compete or obtain advantage vis-à-vis each party’s in any
        commercial activity or transaction which may adversely affect such party;
      </li>

      <li>
        not to disclose Confidential Information to any third party, whether in whole or in part either directly or
        indirectly in any shape or form, except to each party’s employees or representatives and/or other users of the
        Service as is reasonably required in connection with using the Service;
      </li>

      <li>
        not to copy, reproduce or reduce to writing any part of the Confidential Information except as may be reasonably
        necessary pursuant to the first bullet point above and to ensure that any copies, reproductions or reductions to
        writing so made shall be the property of the respective party.
      </li>
    </ul>

    <p>
      This obligation shall not apply to any information that is or becomes generally known to the public through no
      fault or breach of these ToS or any other confidentiality obligation applicable; is legally known to a party at
      the time of disclosure without an obligation of confidentiality; or a party rightfully obtains from a third party
      without restriction on use or disclosure and without a breach of a confidentiality obligation by such third party.
    </p>

    <p>
      Each party is obligated to inform the respective other party immediately if such party becomes aware of any
      violation of this Section.
    </p>

    <p>
      Nothing in these ToS grant any rights to any party under any patent, copyright, trade secret or other intellectual
      property right nor will these ToS grant any rights in or to the Confidential Information except as expressly set
      forth in these ToS.
    </p>

    <h2>Personal Data and Privacy</h2>

    <p>
      Please refer to Field’s{' '}
      <a href="https://field.so/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>{' '}
      for information on how Field collects, uses and discloses personal data from its users. For personal data
      processing by Field on behalf of the Customer the Field{' '}
      <a href="https://field.so/dpa" target="_blank" rel="noreferrer">
        Data Processing Agreement
      </a>{' '}
      applies.
    </p>

    <h2>References and Feedback</h2>

    <p>
      The Customer agrees that Field may identify the Customer (or company and use company’s logo) on the Website and in
      marketing materials to identify Customer (or company and use company’s logo) as a user of the Services, and
      Customer hereby grants Field a non-exclusive, royalty-free license to do so on the Website or in any media now or
      later developed in connection with any marketing, promotion or advertising of the Website or the Services, and if
      Customer provides suggestions, ideas, feedback, or recommendations to Field regarding the Website or the Services
      (“Feedback”), Field will be free to use, disclose, reproduce, license or otherwise distribute, and exploit such
      Feedback as Field sees fit, without any obligation or restriction of any kind to Customer.
    </p>

    <h2>Final Provisions</h2>

    <p>
      If these ToS refer to written form, text form according to section 126b BGB (for example via email) shall be
      sufficient.
    </p>

    <p>
      This agreement and its interpretation are subject to the jurisdiction of the Federal Republic of Germany, to the
      exclusion of the Convention of the United Nations on Contracts for the International Sale of Goods (CISG). Any
      statutory provisions on the limitation of the choice of law as well as the applicability of mandatory laws
      especially by the state, in which a consumer has his usual place of residence, shall not be affected.
    </p>

    <p>
      Place of jurisdiction for each and any legal dispute arising from or being in connection with this contract shall
      be the registered office of Field, provided the Customer is a business person and to the extent permitted by and
      compatible with the law.
    </p>

    <p>
      In case individual provisions in these ToS, including this provision, are or become invalid, or in case of gaps
      arising in these ToS, the validity of all other provisions will not be deemed affected thereby. Instead of the
      ineffective provision or for replenishment of gaps an adequate provision will apply. In case this may constitute
      an unreasonable hardship for one of the contracting parties the contract shall become ineffective as a whole.
    </p>

    <h2>Contacting Field</h2>

    <p>
      Please also feel free to contact Field if you have any questions about this ToS or Field’s practices or if you are
      seeking to exercise any of your statutory rights. Field will respond within a reasonable timeframe. You can
      contact us at <a href="mailto:hello@field.so">hello@field.so</a> or at our postal address below:
    </p>

    <address>
      Product Field Works GmbH
      <br />
      Eimsbütteler Str. 119
      <br />
      22769 Hamburg
      <br />
      Germany
    </address>
  </>
);
export default TermsContent;
