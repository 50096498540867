/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import './AppFrame.scss';

/**
 * The App Frame is a utility component that provides the application
 * with certain default properties and behaviors, such as the CSS variables,
 * keyboard focus management and the floating layer.
 *
 * @param {Object} props
 * @param {JSX.Element} props.children
 */
const AppFrame = ({ children, isDemo }) => {
  const [keyboardFocus, setKeyboardFocus] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    // via https://stackoverflow.com/a/7000222
    setIsFirefox(navigator.userAgent.toLowerCase().indexOf('firefox') > -1);

    // via https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
    const handleFirstTab = (e) => {
      if (e.keyCode === 9) {
        setKeyboardFocus(true);

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
      }
    };

    const handleMouseDownOnce = () => {
      setKeyboardFocus(false);

      window.removeEventListener('mousedown', handleMouseDownOnce);
      window.addEventListener('keydown', handleFirstTab);
    };

    window.addEventListener('keydown', handleFirstTab);

    return () => {
      window.removeEventListener('keydown', handleFirstTab);
      window.removeEventListener('mousedown', handleMouseDownOnce);
    };
  }, []);

  return (
    <div
      className={classNames('app-frame', {
        'keyboard-focus': keyboardFocus,
        'app-frame--demo': isDemo,
        firefox: isFirefox,
      })}
      data-testid="app-frame"
    >
      <div className="app-frame__floating" id="floating" data-testid="floating" />
      {children}
    </div>
  );
};

export default AppFrame;
