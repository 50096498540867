/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './CheckboxList.scss';

const Checkmark = () => {
  return (
    <svg
      className="checkbox-list-button__checkmark"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 8.82794L5.33368 13L14 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

/**
 * A Checkbox List lets the user select multiple options from a list.
 *
 * @param {Object} props
 * @param {[{ title: string, value: string, selected: boolean }]} props.options
 * @param {([{ title: string, value: string, selected: boolean }]) => void} props.onChange
 * @param {string} props.name
 * @param {string} props.labelId The entire CheckboxList should be labeld by a FormElement. Its id needs to be passed here.
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.invalid]
 * @param {boolean} [props.light]
 */
const CheckboxList = ({ options, disabled, invalid, onChange, labelId, name, light }) => {
  function changeEvent(ev) {
    onChange(
      options.map((o) => {
        if (String(o.value) === ev.target.value || (!o.value && o.title === ev.target.value)) {
          return { ...o, selected: !o.selected };
        }
        return { ...o };
      })
    );
  }

  return (
    <div
      className={classNames('checkbox-list', {
        'checkbox-list--disabled': disabled,
        'checkbox-list--invalid': invalid,
        'checkbox-list--light': light,
      })}
      role="group"
      aria-labelledby={labelId}
    >
      {options.map((o, i) => {
        const id = `${labelId}-option-${i}`;
        return (
          <label
            className={classNames('checkbox-list-button', { 'checkbox-list-button--disabled': o.disabled })}
            key={o.value || o.title}
            htmlFor={id}
          >
            <input
              id={id}
              className="checkbox-list-button__input"
              type="checkbox"
              name={name}
              value={o.value || o.title}
              checked={o.selected || false}
              onChange={changeEvent}
              disabled={disabled || o.disabled}
            />
            <div className="checkbox-list-button__indicator">
              <Checkmark />
            </div>
            {o.title}
          </label>
        );
      })}
    </div>
  );
};

export default CheckboxList;
