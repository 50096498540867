/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useRef, useEffect, useState } from 'react';

import classNames from 'classnames';
import ReactDOM from 'react-dom';

import './Floating.scss';

const Floating = ({ children, x, y }) => {
  const floatingWrapperRef = useRef();
  const [position, setPosition] = useState('right');

  // The expansion class is provided to the wrapped components
  // in order for them to decide which direction to expand into
  const [expansion, setExpansion] = useState('right');

  const floatingLayer = document.querySelector('#floating');

  useEffect(() => {
    if (x && y && floatingWrapperRef.current) {
      const wrapperRect = floatingWrapperRef.current.getBoundingClientRect();
      if (x + wrapperRect.width + 12 > window.innerWidth) {
        setPosition('left');

        if (x + 120 > window.innerWidth) {
          setExpansion('left');
        }
      } else if (x + wrapperRect.width + 120 > window.innerWidth) {
        setExpansion('left');
      }
    }
  }, [x, y]);

  if (!floatingLayer) {
    return <>No floatingLayer</>;
  }

  if (x && y) {
    return ReactDOM.createPortal(
      <div
        className={classNames(
          'floating__wrapper',
          `floating__wrapper--${position}`,
          `floating__wrapper--expand-${expansion}`
        )}
        style={{ left: `${(x / window.innerWidth) * 100}%`, top: `${(y / window.innerHeight) * 100}%` }}
        ref={floatingWrapperRef}
      >
        {children}
      </div>,
      floatingLayer
    );
  }

  return ReactDOM.createPortal(children, floatingLayer);
};

export default Floating;
