/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { Tooltip } from '@fieldbyfield/components';

import './CueCharacterCounter.scss';

const MINIMUM_OPACITY = 0.2;

const CueCharacterCounter = ({ maxCharacters, currentCharacters, showTooltip }) => {
  const opacity = Math.max(MINIMUM_OPACITY, scale(currentCharacters / maxCharacters, 0, 1, -1, 1.5));

  const [open, setOpen] = useState(showTooltip || currentCharacters > maxCharacters * 0.7);

  useEffect(() => {
    setOpen(showTooltip || currentCharacters > maxCharacters * 0.7);
  }, [showTooltip, currentCharacters, maxCharacters]);

  return (
    <Tooltip
      theme="dark"
      side="bottom"
      open={open}
      onOpenChange={setOpen}
      tooltipContent={
        <div className="cue-character-counter__tooltip-content">
          <strong>Every thought deserves its own card!</strong> Please don’t write more than one thought, idea or fact
          on a single card – write multiple cards instead.
        </div>
      }
      asChild
    >
      <div
        className={classNames('cue-character-counter', {
          'cue-character-counter--too-long': currentCharacters > maxCharacters * 0.85,
          'cue-character-counter--almost-too-long': currentCharacters > maxCharacters * 0.7 || showTooltip,
        })}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <div className="cue-character-counter__count" style={{ opacity }}>
          {maxCharacters - currentCharacters}
        </div>

        <svg
          className="cue-character-counter__circle-chart"
          viewBox="0 0 39.83098862 39.83098862"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="cue-character-counter__circle-chart-background"
            strokeWidth="5"
            fill="none"
            cx="19.91549431"
            cy="19.91549431"
            r="15.91549431"
          />
          {currentCharacters > 0 && (
            <circle
              className="cue-character-counter__circle-chart-circle"
              strokeWidth="5"
              strokeDasharray={`${(currentCharacters / maxCharacters) * 100},100`}
              strokeLinecap="round"
              fill="none"
              cx="19.91549431"
              cy="19.91549431"
              r="15.91549431"
            />
          )}
        </svg>
      </div>
    </Tooltip>
  );
};

function scale(value, inMin, inMax, outMin, outMax) {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

export default CueCharacterCounter;
