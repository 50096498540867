/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import './FormElement.scss';

// labelId is used to set aria-labeledby on non-standard elements, such as RadioButtonGroups
const FormElement = ({ children, disabled, invalid, annotation, label, labelId, name, size = 'beta' }) => {
  return (
    <div
      className={classNames('form-element', `form-element--${size}`, {
        'form-element--disabled': disabled,
        'form-element--invalid': invalid,
      })}
    >
      {label && (
        <label className="form-element__label" htmlFor={name} id={labelId}>
          {label}
        </label>
      )}
      {annotation && <div className="form-element__annotation">{annotation}</div>}
      <div className="form_element__content">{children}</div>
    </div>
  );
};

export default FormElement;
