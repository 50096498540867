/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

import './MetaDataTable.scss';

/**
 * A MetaDataTable that displays content
 * @param {Object} props
 * @param {[{title: string, value: string, icon: string}]} props.data
 * @param {boolean} [props.compact = false]
 * @param {('default' | 'dark' | 'light')} [props.theme = "default"]
 */
const MetaDataTable = ({ data, compact, theme }) => {
  return (
    <table
      className={classNames('meta-data-table', `meta-data-table--${theme}`, {
        'meta-data-table--compact': compact,
      })}
    >
      <tbody>
        {data.map((d) => (
          <tr className="meta-data-table__row" key={`${d.title}-${d.value}`}>
            <td className="meta-data-table__cell meta-data-table__icon">
              <Icon size="alpha" icon={d.icon} />
            </td>
            {!compact && <td className="meta-data-table__cell meta-data-table__title">{d.title}</td>}
            <td className="meta-data-table__cell meta-data-table__datum">{d.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MetaDataTable;
