/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import Vector from './utils/vector.js';

const RELEVANT_DISTANCE_STRENGTH = 2;
const RELEVANT_DISTANCE_DIRECTION = 5;
const EMITTER_DIRECTION_INFLUCE_BOOST = 2;

function envelope(value, distance, relevantDistance) {
  if (distance >= relevantDistance) {
    return 0;
  }
  return value * ((relevantDistance - distance) / relevantDistance);
}

export function computeGroundPotentialAtPoint(point) {
  const x = 1;
  const y = parseFloat(Math.sign(point.y) * Math.sign(point.x) * Math.abs(point.x));
  return new Vector(x, y);
}

export function computeStrengthAtPoint(emitters, point) {
  const strength = emitters.reduce((sum, emitter) => {
    return sum + envelope(emitter.strength, point.distance(emitter.position), RELEVANT_DISTANCE_STRENGTH);
  }, 0);

  return strength;
}

export function computeCharacterAtPoint(emitters, point) {
  const character = emitters.reduce((sum, emitter) => {
    return (
      sum +
      envelope(
        emitter.position.x * Math.abs(emitter.strength),
        point.distance(emitter.position),
        RELEVANT_DISTANCE_STRENGTH
      )
    );
  }, 0);

  return character;
}

export function computeDirectionAtPoint(emitters, point) {
  // The strength at the point defines the general direction
  const strength = computeStrengthAtPoint(emitters, point);
  const groundPotential = computeGroundPotentialAtPoint(point);

  if (strength === 0) {
    return groundPotential;
  }

  const groundPotentialVector = new Vector(
    groundPotential.x * Math.sign(strength),
    groundPotential.y * Math.sign(strength)
  );

  // All the emitters influence the direction
  const direction = emitters.reduce((vector, emitter) => {
    const emitterDirection = computeGroundPotentialAtPoint(emitter.position);

    const emitterInfluenceVector = new Vector(
      envelope(emitterDirection.x, point.distance(emitter.position), RELEVANT_DISTANCE_DIRECTION) *
        EMITTER_DIRECTION_INFLUCE_BOOST *
        emitter.strength,
      envelope(emitterDirection.y, point.distance(emitter.position), RELEVANT_DISTANCE_DIRECTION) *
        EMITTER_DIRECTION_INFLUCE_BOOST *
        emitter.strength
    );

    return vector.add(emitterInfluenceVector);
  }, groundPotentialVector);

  return direction.normalize();
}

export function getForceForPoint(emitters, point) {
  return {
    strength: computeStrengthAtPoint(emitters, point), // [-1 .. 1]  = [negative .. positive]
    character: computeCharacterAtPoint(emitters, point), // [-1 .. 1] = [push .. pull]
    direction: computeDirectionAtPoint(emitters, point), // Vector(x, y)
    position: point,
  };
}
