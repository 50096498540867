/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useRef, useEffect, useCallback } from 'react';

import classNames from 'classnames';

import selectFocusableNodes from '../utils/selectFocusableNodes';

import './LegacyTooltip.scss';

let previouslyFocusedDomNode;

/**
 * @typedef Props
 * @property {JSX.Element} children
 * @property {JSX.Element} footer
 * @property {('top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' | 'left-top' | 'left-center' | 'left-bottom' | 'right-top' | 'right-center' | 'right-bottom')} [tipPosition = "top-center"] The position of the tip
 */

/**
 * A LegacyTooltip
 * @param {Props} props
 */
const LegacyTooltip = ({
  children,
  footer,
  tipPosition = 'top-center',
  light,
  onClickOutside,
  captureFocus = true,
  noPadding,
}) => {
  const tooltipRef = useRef();

  const handleKey = useCallback(
    (ev) => {
      if (onClickOutside && ev.key === 'Escape') {
        onClickOutside();
      }

      // Captures the focus and keeps it within the tooltip.

      // Backwards
      if (ev.key === 'Tab' && ev.getModifierState('Shift')) {
        if (tooltipRef.current) {
          const focusableNodes = selectFocusableNodes(tooltipRef.current);

          // If the focus is outside or on the last element focus the first one
          if (!tooltipRef.current.contains(document.activeElement) || document.activeElement === focusableNodes[0]) {
            ev.preventDefault();
            if (focusableNodes.length > 0) {
              focusableNodes[focusableNodes.length - 1].focus();
            }
            return;
          }
        }
        return;
      }

      // Forwards
      if (ev.key === 'Tab') {
        if (tooltipRef.current) {
          const focusableNodes = selectFocusableNodes(tooltipRef.current);

          // If the focus is outside or on the last element focus the first one
          if (
            !tooltipRef.current.contains(document.activeElement) ||
            document.activeElement === focusableNodes[focusableNodes.length - 1]
          ) {
            ev.preventDefault();
            if (focusableNodes.length > 0) {
              focusableNodes[0].focus();
            }
          }
        }
      }
    },
    [onClickOutside]
  );

  const handleClick = (ev) => {
    // This is to prevent a click on the body of an tooltip from
    // reaching its parent, typically an Overlay (clicks on
    // which typically dismiss the tooltip.)
    ev.stopPropagation();
  };

  const handleClickOutside = useCallback(
    (ev) => {
      if (tooltipRef.current && !tooltipRef.current.contains(ev.target)) {
        if (onClickOutside) {
          onClickOutside();
          ev.stopPropagation();
        }
      }
    },
    [onClickOutside]
  );

  useEffect(() => {
    if (captureFocus) {
      document.addEventListener('keydown', handleKey);
    }
    document.addEventListener('mousedown', handleClickOutside);

    previouslyFocusedDomNode = document.activeElement;

    return () => {
      document.removeEventListener('keydown', handleKey);
      document.removeEventListener('mousedown', handleClickOutside);

      if (captureFocus && previouslyFocusedDomNode) {
        previouslyFocusedDomNode.focus();
      }
    };
  }, [captureFocus, handleClickOutside, handleKey]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames('legacy-tooltip', `legacy-tooltip--${tipPosition}`, {
        'legacy-tooltip--has-footer': footer,
        'legacy-tooltip--light': light,
        'legacy-tooltip--no-padding': noPadding,
      })}
      ref={tooltipRef}
      onClick={handleClick}
    >
      {children && <div className="legacy-tooltip__main">{children}</div>}
      {footer && <div className="legacy-tooltip__footer">{footer}</div>}
    </div>
  );
};
export default LegacyTooltip;
