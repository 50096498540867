/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import useDebounce from '../utils/useDebounce';

import './Counter.scss';

/**
 * A Counter lets the user enter a number.
 *
 * @param {Object} props
 * @param {number} props.value
 * @param {(number) => {}} props.onChange
 * @param {string} props.name
 * @param {string} [props.placeholder]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.invalid]
 * @param {boolean} [props.light]
 * @param {number} [props.debounce]
 * @param {string} [props.labeledBy] When an element is used as a label that is not a FormElement, an id can be passed here to connect the component with the label.
 */
const Counter = ({
  value,
  disabled,
  invalid,
  onChange,
  placeholder,
  name,
  light,
  labelledBy,
  debounce: debounceDelay = 0,
}) => {
  const [immidiateValue, setImmidiateValue] = useState(value);
  const debounce = useDebounce();

  useEffect(() => {
    setImmidiateValue(value);
  }, [value]);

  return (
    <div
      className={classNames('counter', {
        'counter--disabled': disabled,
        'counter--invalid': invalid,
        'counter--light': light,
      })}
    >
      <input
        className="counter__input"
        type="number"
        value={immidiateValue}
        onChange={(e) => {
          const { value: v } = e.target;
          setImmidiateValue(parseInt(v));
          debounce(() => onChange(v), debounceDelay);
        }}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        id={name}
        aria-labelledby={labelledBy}
      />
    </div>
  );
};

export default Counter;
