/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

// Properties of a cue for use with getCuePropCandidates.
export const CUE_PROP_ASPECT = 'aspect';
export const CUE_PROP_LINKED_FIELDS = 'linked_fields';
export const CUE_PROP_LINKED_CUES = 'linked_cues';

export class CoreAPI {
  constructor({ request }) {
    this.request = request;
  }

  // Fields...
  getFields({ workspace }) {
    return this.request('GET', `workspaces/${workspace.id}/fields`);
  }

  getWorkspaceCue({ workspace, cue }) {
    return this.request('GET', `workspaces/${workspace.id}/cues/${cue.id}`);
  }

  getWorkspaceCues({ workspace, cues }) {
    return this.request(
      'GET',
      `workspaces/${workspace.id}/cues`,
      {
        id: cues.map((v) => v.id).sort((a, b) => a - b),
      },
      {}
    );
  }

  addField({ workspace, field }) {
    return this.request('PUT', `workspaces/${workspace.id}/fields`, {}, field);
  }

  moveField({ workspace, data }) {
    return this.request('POST', `workspaces/${workspace.id}/fields`, {}, data);
  }

  getField({ workspace, field }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}`);
  }

  setField({ workspace, field }) {
    return this.request('POST', `workspaces/${workspace.id}/fields/${field.id}`, {}, field);
  }

  deleteField({ workspace, field }) {
    return this.request('DELETE', `workspaces/${workspace.id}/fields/${field.id}`);
  }

  getFieldGraph({ workspace, field }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/graph`);
  }

  // Cues...
  // Retrieves multiple cues, optionally using a conditions for
  // selecting specific cues.
  //
  // Examplaric usage with conditions:
  //
  //   Client.cues.get({
  //     workspace,
  //     field,
  //     query: {
  //       text: "foo",
  //       relevance: [">", 2],
  //     }
  //   });
  //
  // When the value is scalar (i.e. a string or number) than the equality
  // operator ("=") is assumed. When the value of a condition is an array,
  // the first array element must be an operator string, and the second array
  // element the actual value used for comparison.
  getCues({ workspace, field, query = {} }) {
    const params = {};

    // Formats given query conditions into a string, that can be
    // passed to the API for matching and selecting cues.
    const q = Object.entries(query)
      .map(([k, v]) => `${k}:${Array.isArray(v) ? v.join(':') : v}`)
      .join(',');

    if (q !== '') {
      params.q = q;
    }

    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/cues`, params);
  }

  addCue({ workspace, field, cue }) {
    return this.request('PUT', `workspaces/${workspace.id}/fields/${field.id}/cues`, {}, cue);
  }

  getCue({ workspace, field, cue }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/cues/${cue.id}`);
  }

  setCue({ workspace, field, cue }) {
    return this.request('POST', `workspaces/${workspace.id}/fields/${field.id}/cues/${cue.id}`, {}, cue);
  }

  deleteCue({ workspace, field, cue }) {
    return this.request('DELETE', `workspaces/${workspace.id}/fields/${field.id}/cues/${cue.id}`);
  }

  // Returns possible candidates for a certain cue property.
  //
  // The given cue object may point to an existing cue, by providing
  // an ID. In thath case recommendations will be returned based upon its
  // current data in the backend.
  //
  //   Client.core.getCuePropCandidates({
  //     workspace: { id: 1 },
  //     field: { id: 2 },
  //     cue: { id: 2 },
  //     // ...
  //   });
  //
  // Alternatively a cue object without an ID may be provided, in which case the
  // candidate recommendations will be based upon the submitted data.
  //
  //   Client.core.getCuePropCandidates({
  //     workspace: { id: 1 },
  //     field: { id: 2 },
  //     cue: { text: 'Example', x: 1.0, y: 0.75 },
  //     // ...
  //   });
  //
  // The response type will always be an array of items, which type varies
  // dependent on for which property the candidates have been requested for.
  //
  //   Client.core.getCuePropCandidates({ // returns an array of strings
  //     /// ...
  //     prop: CUE_PROP_ASPECT // i.e. CUE_PROP_ASPECT, CUE_PROP_LINKED_FIELDS, ...
  //   });
  //
  getCuePropCandidates({ workspace, field, cue, prop }) {
    return this.request(
      'POST',
      `workspaces/${workspace.id}/fields/${field.id}/cue-${prop.replace('_', '-')}-candidates`,
      {},
      cue
    );
  }

  requestReviewForCue({ workspace, field, cue }) {
    return this.request('PUT', `workspaces/${workspace.id}/fields/${field.id}/cues/${cue.id}/reviews/request`);
  }

  approveReviewForCue({ workspace, field, cue }) {
    return this.request('POST', `workspaces/${workspace.id}/fields/${field.id}/cues/${cue.id}/reviews/approve`);
  }

  // Merges multiple cues into a single cue.
  //
  // Usage:
  //   Client.core.mergeCues({
  //     workspace,
  //     field,
  //     cues, // i.e. [ { id: 1 }, { id: 2 }, ...]
  //     merge: {
  //       text: // text for the merge cue
  //     }
  //   });
  mergeCues({ workspace, field, cues, merge }) {
    return this.request('POST', `workspaces/${workspace.id}/fields/${field.id}/cues/merge`, {}, { ...merge, cues });
  }

  // Groups cues into a new or existing group.
  //
  // Create a new group with cues:
  //   Client.core.groupCues({
  //     workspace,
  //     field,
  //     group: { title: 'Strategical problems' },
  //     cues, // i.e. [ { id: 1 }, { id: 2 }, ...]
  //   });
  //
  // Add cues to an existing group, will merge with already grouped cues:
  //   Client.core.groupCues({
  //     workspace,
  //     field,
  //     group: { id: 1 },
  //     cues, // i.e. [ { id: 3 }, { id: 4 }, ...]
  //   });
  //
  // Create a new empty group:
  //   Client.core.groupCues({
  //     workspace,
  //     field,
  //     group: { title: 'Best customers' }
  //   });
  groupCues({ workspace, field, group, cues }) {
    if (group.id && cues) {
      return this.request(
        'POST',
        `workspaces/${workspace.id}/fields/${field.id}/groups/${group.id}/cues`,
        {},
        { ...group, cues }
      );
    }

    return this.request(
      group.id ? 'POST' : 'PUT',
      `workspaces/${workspace.id}/fields/${field.id}/groups${group.id ? `/${group.id}` : ''}`,
      {},
      { ...group, cues }
    );
  }

  // Remove certain cues from a group or dissolve a group.
  //
  // Remove certain cues from a group, keeping the group intact:
  //   Client.core.ungroupCues({
  //     workspace,
  //     field,
  //     group: { id: 1 },
  //     cues, // i.e. [ { id: 1 }, { id: 2 }, ...]
  //   });
  //
  // Remove all cues from a group, then delete the group, dissolving the group:
  //   Client.core.ungroupCues({
  //     workspace,
  //     field,
  //     group: { id: 1 }
  //   });
  ungroupCues({ workspace, field, group, cues }) {
    if (cues) {
      return this.request(
        'DELETE',
        `workspaces/${workspace.id}/fields/${field.id}/groups/${group.id}/cues`,
        {},
        { cues }
      );
    }
    return this.request('DELETE', `workspaces/${workspace.id}/fields/${field.id}/groups/${group.id}`, {}, {});
  }

  getGroups({ workspace, field }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/groups`);
  }

  // Adds an empty new group.
  addGroup({ workspace, field, group }) {
    return this.request('PUT', `workspaces/${workspace.id}/fields/${field.id}/groups`, {}, group);
  }

  getGroup({ workspace, field, group }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/groups/${group.id}`);
  }

  // Updates the properties of a group, does not modify grouped cues.
  //
  // Usage:
  //   Client.core.setGroup({
  //     workspace,
  //     field,
  //     group: { id: 1, title: '...' }
  //   });
  setGroup({ workspace, field, group }) {
    return this.request('POST', `workspaces/${workspace.id}/fields/${field.id}/groups/${group.id}`, {}, group);
  }

  // Deletes the group and all grouped cues.
  deleteGroup({ workspace, field, group }) {
    return this.request('DELETE', `workspaces/${workspace.id}/fields/${field.id}/groups/${group.id}`);
  }

  getStatement({ workspace, field, statement }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/statements/${statement.id}`);
  }

  getStatements({ workspace, field }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/statements`);
  }

  // Adds an statement
  addStatement({ workspace, field, statement }) {
    return this.request('PUT', `workspaces/${workspace.id}/fields/${field.id}/statements`, {}, statement);
  }

  // Sets the statement
  setStatement({ workspace, field, statement }) {
    return this.request(
      'POST',
      `workspaces/${workspace.id}/fields/${field.id}/statements/${statement.id}`,
      {},
      statement
    );
  }

  // Deletes the briefing
  deleteStatement({ workspace, field, statement }) {
    return this.request('DELETE', `workspaces/${workspace.id}/fields/${field.id}/statements/${statement.id}`);
  }

  getBriefing({ workspace, field, briefing }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/briefings/${briefing.id}`);
  }

  getBriefings({ workspace, field }) {
    return this.request('GET', `workspaces/${workspace.id}/fields/${field.id}/briefings`);
  }

  // Adds an briefing
  addBriefing({ workspace, field, briefing }) {
    return this.request('PUT', `workspaces/${workspace.id}/fields/${field.id}/briefings`, {}, briefing);
  }

  // Sets the briefing
  setBriefing({ workspace, field, briefing }) {
    return this.request('POST', `workspaces/${workspace.id}/fields/${field.id}/briefings/${briefing.id}`, {}, briefing);
  }

  // Deletes the briefing
  deleteBriefing({ workspace, field, briefing }) {
    return this.request('DELETE', `workspaces/${workspace.id}/fields/${field.id}/briefings/${briefing.id}`);
  }
}
