/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import Tag from './Tag.jsx';

import './TagList.scss';

const TagList = ({ tags, onAddItem, onDeleteItem, onChangeItem, readOnly }) => {
  return (
    <div className="tag-list">
      {tags.map((i) => {
        return <Tag {...i} key={i.id} onChange={onChangeItem} onDelete={onDeleteItem} readOnly={readOnly} />;
      })}

      {!readOnly && (
        <Tag
          key={0}
          isAddButton
          onChange={(i) => {
            if (i.text && i.text !== '') {
              onAddItem(i.text);
            }
          }}
          onDelete={() => {}}
        />
      )}
    </div>
  );
};

export default TagList;
