/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useContext } from 'react';

import classNames from 'classnames';

import { SET_CORE, SET_CONTEXT, aspectFromName } from '@fieldbyfield/product-field';

import { ParagraphSizeContext, ParagraphWeightContext } from '../Paragraph/Paragraph.jsx';

import './Term.scss';

const Term = ({ children, size, weight }) => {
  let transform = 'normal';

  const aspect = aspectFromName(children ?? '');

  if (SET_CORE.includes(aspect)) {
    transform = 'lowercase';
  } else if (SET_CONTEXT.includes(aspect) || ['VALUE', 'MARKET', 'RESOURCES', 'IDEA'].includes(children)) {
    transform = 'uppercase';
  }

  const contextSize = useContext(ParagraphSizeContext);
  const renderSize = size || contextSize || 'beta';

  const contextWeight = useContext(ParagraphWeightContext);
  const renderWeight = weight || contextWeight || 'regular';

  return (
    <em className={classNames('term', `term--${renderSize}`, `term--${renderWeight}`, `term--${transform}`)}>
      {children}
    </em>
  );
};

export default Term;
