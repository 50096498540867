/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { createContext } from 'react';

import classNames from 'classnames';

import './Paragraph.scss';

export const ParagraphSizeContext = createContext(null);
export const ParagraphWeightContext = createContext(null);

/**
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @param {('alpha' | 'beta' | 'gamma' | 'delta' | 'epsilon' | 'zeta' | 'eta')} [props.size = "beta"]
 * @param {('regular' | 'medium')} [props.weight = "regular"]
 */
const Paragraph = ({ children, size = 'beta', weight = 'regular' }) => {
  return (
    <ParagraphSizeContext.Provider value={size}>
      <ParagraphWeightContext.Provider value={weight}>
        <p className={classNames('paragraph', `paragraph--${size}`, `paragraph--${weight}`)}>{children}</p>
      </ParagraphWeightContext.Provider>
    </ParagraphSizeContext.Provider>
  );
};
export default Paragraph;
