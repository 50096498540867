/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useLayoutEffect, useRef } from 'react';

import classNames from 'classnames';

import Icon from '../Icon';
import selectFocusableNodes from '../utils/selectFocusableNodes';

import './Modal.scss';

let previouslyFocusedDomNode;

const Modal = ({
  children,
  onDismiss,
  title,
  subtitle,
  footer,
  noPadding = false,
  wide = false,
  high = false,
  fullWidth = false,
  fullHeight = false,
  theme,
}) => {
  const modalRef = useRef();

  const handleKey = (ev) => {
    if (ev.key === 'Escape') {
      ev.stopPropagation();
      onDismiss();
    }

    // Captures the focus and keeps it within the modal.

    // Backwards
    if (ev.key === 'Tab' && ev.getModifierState('Shift')) {
      if (modalRef.current) {
        const focusableNodes = selectFocusableNodes(modalRef.current);

        // If the focus is outside or on the last element focus the first one
        if (!modalRef.current.contains(document.activeElement) || document.activeElement === focusableNodes[0]) {
          ev.preventDefault();
          if (focusableNodes.length > 0) {
            focusableNodes[focusableNodes.length - 1].focus();
          }
          return;
        }
      }
      return;
    }

    // Forwards
    if (ev.key === 'Tab') {
      if (modalRef.current) {
        const focusableNodes = selectFocusableNodes(modalRef.current);

        // If the focus is outside or on the last element focus the first one
        if (
          !modalRef.current.contains(document.activeElement) ||
          document.activeElement === focusableNodes[focusableNodes.length - 1]
        ) {
          ev.preventDefault();
          if (focusableNodes.length > 0) {
            focusableNodes[0].focus();
          }
        }
      }
    }
  };

  const eventTrap = (ev) => {
    // This is to prevent a click on the body of an alert from
    // reaching its parent, typically an Overlay (clicks on
    // which typically dismiss the alert.)
    ev.stopPropagation();
  };

  useLayoutEffect(() => {
    const modalDom = modalRef.current;

    if (!modalRef.current) {
      return;
    }

    modalDom.addEventListener('keydown', handleKey);
    previouslyFocusedDomNode = document.activeElement;

    if (!modalDom.contains(document.activeElement)) {
      const focusableNodes = selectFocusableNodes(modalDom);
      if (focusableNodes.length > 0) {
        focusableNodes[0].focus();
      }
    }

    return () => {
      modalDom.removeEventListener('keydown', handleKey);

      if (previouslyFocusedDomNode) {
        previouslyFocusedDomNode.focus();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // it's okay to not comply to jsx-a11x for eventTrap
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <div
      className={classNames('modal', {
        'modal--no-padding': noPadding,
        'modal--wide': wide,
        'modal--high': high,
        'modal--full-width': fullWidth,
        'modal--full-height': fullHeight,
        [`modal--theme-${theme}`]: theme,
      })}
      role="dialog"
      aria-modal
      aria-labelledby="modal-title"
      ref={modalRef}
      onClick={eventTrap}
    >
      <form className="modal__form">
        <div className="modal__header">
          <div id="modal-title">
            {title} {subtitle && <span className="modal__subtitle">{subtitle}</span>}
          </div>
          <button onClick={onDismiss} type="button" className="modal__close-button" aria-label="Modal schließen">
            <Icon icon="close-thick" size="tiny" color="gray-600" />
          </button>
        </div>
        <div className="modal__body">{children}</div>
        {footer && <div className="modal__footer">{footer}</div>}
      </form>
    </div>
  );
};

export default Modal;
