/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React, { useState, useEffect } from 'react';

import { ContractForm } from '@fieldbyfield/components';

import PrivacyContent from './PrivacyContent.jsx';
import TermsContent from './TermsContent.jsx';

// First lets the user accept the TOS and then the PP.
const AcceptTerms = ({ onAcceptTerms }) => {
  const [areTOSAccepted, setAreTOSAccepted] = useState(false);
  const [isPPAccepted, setIsPPAccepted] = useState(false);

  useEffect(() => {
    if (areTOSAccepted && isPPAccepted) {
      onAcceptTerms();
    }
  }, [areTOSAccepted, isPPAccepted, onAcceptTerms]);

  if (!areTOSAccepted) {
    return <ContractForm onConfirm={() => setAreTOSAccepted(true)} title="Terms of Service" content={TermsContent} />;
  }
  return <ContractForm onConfirm={() => setIsPPAccepted(true)} title="Privacy Policy" content={PrivacyContent} />;
};

export default AcceptTerms;
