/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

import React from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

import './Button.scss';

const HtmlButton = React.forwardRef((props, ref) => {
  return <button type="button" ref={ref} {...props} />;
});

const HtmlLink = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <a ref={ref} {...props}>
      {children}
    </a>
  );
});

/**
 * A Button triggers an action.
 *
 * @param {Object} props
 * @param {JSX.Element} props.children
 * @param {function} props.onClick
 * @param {"default" | "primary" | "destructive" | "ghost"} [props.type]
 * @param {boolean} [props.disabled]
 * @param {"default" | "light" | "dark-gray" | "marketing" | "admin" | {backgroundColor: string, backgroundColorActive: string, color: string, iconColor: string, borderColor: string}} [props.theme]
 * @param {"alpha" | "beta"} [props.size]
 * @param {string} [props.icon]
 * @param {boolean} [props.iconRight] Whether the icon should be displayed on the right side of the icon, rather than the left.
 * @param {string} [props.type] The semantic type of the HTML button element.
 * @param {boolean} props.submit
 */
const Button = React.forwardRef((props, ref) => {
  return asButton(HtmlButton)({ ...props, innerRef: ref });
});

export const ButtonLink = React.forwardRef((props, ref) => {
  return asButton(HtmlLink)({ className: 'button--link', ...props, innerRef: ref });
});

export const asButton =
  (BaseComponent) =>
  ({
    children,
    className,
    type = 'default',
    theme = 'default',
    size = 'beta',
    disabled,
    icon,
    iconRight,
    submit,
    innerRef,
    ...props
  }) => {
    const style = {};

    if (theme && typeof theme === 'object') {
      if (theme.backgroundColor) {
        style[`--background-color`] = theme.backgroundColor;
      }
      if (theme.backgroundColorActive) {
        style[`--background-color-active`] = theme.backgroundColorActive;
      }
      if (theme.color) {
        style[`--color`] = theme.color;
      }
      if (theme.iconColor) {
        style[`--icon-color`] = theme.iconColor;
      }
      if (theme.borderColor) {
        style[`--border-color`] = theme.borderColor;
      }
    }

    return (
      <BaseComponent
        className={classNames('button', className, `button--size-${size}`, `button--type-${type}`, {
          [`button--theme-${theme}`]: typeof theme === 'string',
          'button--disabled': disabled,
        })}
        style={style}
        disabled={disabled}
        type={submit ? 'submit' : 'button'}
        ref={innerRef}
        {...props}
      >
        <div>
          {icon && (
            <span className="button__icon">
              <Icon icon={icon} size={size === 'alpha' ? 'tiny' : 'alpha'} />
            </span>
          )}
          {children}
          {iconRight && (
            <span className="button__icon-right">
              <Icon icon={iconRight} size={size === 'alpha' ? 'tiny' : 'alpha'} />
            </span>
          )}
        </div>
      </BaseComponent>
    );
  };

/**
 * A Button Group aligns multiple Buttons.
 *
 * @param {Object} props
 * @param {JSX.Element} props.children
 */
export const ButtonGroup = ({ children }) => {
  return <div className="button-group">{children}</div>;
};

export default Button;
