/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

export default {
  ui: {
    loading: false,
    authError: false,
  },
  auth: {
    token: null,
  },
  user: {
    timeboxTotal: 900,
    timeboxRemaining: 900,
    timeboxIsRunning: false,
    timeboxIsActive: false,
    email: 'christoph@productfieldworks.com',
    location: 'Hamburg, Germany',
  },
  survey: {
    isLoaded: false,
    isSubmitted: false,
  },
  product: {
    name: null,
    info: null,
  },
  introduction: {
    subject: null,
    description: null,
    moderator: null,
  },
  questions: [],
};
