/**
 * Copyright 2021 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */

// This will be injected by esbuild with an implementation suitable
// for the environment the build is created for (browser or Deno).
// const hash = async (v) => {};
/* global hash */

// Handles deduplication of requests.
export const maybeDeduplicateRequest = async (running, base, token, method, path, params, data) => {
  let h;

  // With multiple GET requests for the same resource and one request already in
  // flight, reject new ones and let the earliest win.
  if (method === 'GET') {
    h = await hash([base, token, method, path, params, data]); // include all

    if (running.has(h)) {
      console.debug('Request Deduplication: Cancelling request (%s %s) as one is in-flight already...', method, path);
      return [h, Promise.reject(new Error('request already in-flight'))];
    }
  }

  // With multiple POST reuests wanting to mutate the resource, allow only the
  // last one win, so we don't get stale responses in turn.
  if (method === 'POST') {
    h = await hash([base, token, method, path, params]); // exclude data

    if (running.has(h)) {
      console.debug('Request Deduplication: Cancelling in-flight earlier request (%s %s)...', method, path);

      running.cancel(h);
      return [h, null];
    }
  }

  // With multiple DELETE requests only the first one has to make it, all
  // subsequent ones would have the same result, or even error out at the server.
  if (method === 'DELETE') {
    h = await hash([base, token, method, path, params]); // exclude data

    if (running.has(h)) {
      console.debug('Request Deduplication: Cancelling request (%s %s) as one is in-flight already...', method, path);
      return [h, Promise.reject(new Error('request already in-flight'))];
    }
  }

  // Do not cancel PUT requests, we might want to rapidly create a new ressource.

  return [h, null];
};
